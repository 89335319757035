import React, { useState, useEffect } from "react";
import { MyorderFetchAPi } from "../../APIS/OrderCreateAPI";
import "../../Style/profile/Myorederdetails.css";
import { OrdercancelAPI } from "../../APIS/OrderCreateAPI";
const MyOrderdeatils = ({
  orderId,
  orderNumber,
  getUserAuthenticationRedux,
}) => {
  const [orderdata, setOrderdata] = useState();
  const [orderAddress, setOrderAddress] = useState();

  useEffect(() => {
    orderIteamdeatails();
  }, []);

  const orderIteamdeatails = () => {
    let fromdata = {
      language: "en",
      orderId: orderId,
      orderNumber: orderNumber,
    };
    MyorderFetchAPi(getUserAuthenticationRedux, fromdata, (callback) => {
      setOrderAddress(callback?.orders?.addressShipping);
      setOrderdata(callback?.orders);
    });
  };
  const handleCancel = () => {
    let fromdata = {
      orderId: orderdata ? orderdata?.order_id : "",
      orderItemId: [orderdata ? orderdata?.product_id : ""],
    };

    OrdercancelAPI(getUserAuthenticationRedux, fromdata, orderIteamdeatails);
  };

  return (
    <div className="">
      <div className="shiping-Address-box">
        <div className="shiping-Address-heading">
          <h5>Shipping Address</h5>
        </div>
        <div className="">
          <div className="row">
            <div className="col-md-2 col-4">
              <div className="shiping-Address-block">Address</div>
            </div>
            <div className="col-md-10 col-8">
              <div className="shiping-Address-size">
                <span>{orderAddress?.name}</span>
                <span>({orderAddress?.address_type})</span>
              </div>
              <div className="shiping-Address-size">
                <span>({orderAddress?.phone_code})</span>
                <span>{orderAddress?.mobile}</span>
              </div>
              <div className="shiping-Address-size">
                <span>{orderAddress?.street}</span>
                <span className="ms-1">{orderAddress?.landmark}</span>
              </div>
              <div className="shiping-Address-size">
                <span>{orderAddress?.city}</span>
                <span className="mx-1">{orderAddress?.state}</span>
                <span>{orderAddress?.code}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="shipping-iteam-details-box">
        <div className="row">
          <div className="col-md-4 col-12">
            <div className="order-iteam-img-box">
              <img src={orderdata?.productImage} alt="kamlesh" />
            </div>
          </div>
          <div className="col-md-8 col-12">
            <div className="d-flex align-iteams-center">
              <div className="col-md-3 col-5 porduct-heading-iteam">
                Product Name :
              </div>
              <div className="col-md-9 col-7">{orderdata?.productName}</div>
            </div>
            <div className="d-flex align-iteams-center">
              <div className="col-md-3 col-5 porduct-heading-iteam">
                Quantity :
              </div>
              <div className="col-md-9 col-7">{orderdata?.quantity}</div>
            </div>
            <div className="d-flex align-iteams-center">
              <div className="col-md-3 col-5 porduct-heading-iteam">
                Order-Amount :
              </div>
              <div className="col-md-9 col-7">${orderdata?.order_amount}</div>
            </div>
            <div className="d-flex align-iteams-center">
              <div className="col-md-3 col-5 porduct-heading-iteam">
                Payment-Type :
              </div>
              <div className="col-md-9 col-7">{orderdata?.payment_type}</div>
            </div>
            <div className="d-flex align-iteams-center">
              <div className="col-md-3 col-5 porduct-heading-iteam">
                Base-Price :
              </div>
              <div className="col-md-9 col-7">${orderdata?.base_price}</div>
            </div>
            <div className="d-flex align-iteams-center">
              <div className="col-md-3 col-5 porduct-heading-iteam">
                Sale-Price :
              </div>
              <div className="col-md-9 col-7">${orderdata?.sale_price}</div>
            </div>
            {orderdata
              ? orderdata?.variation?.variations?.map((cur, i) => {
                  {
                    /* console.log(cur,"variations") */
                  }
                  return (
                    <div className="d-flex align-iteams-center" key={i}>
                      <div className="col-3 porduct-heading-iteam">
                        {cur?.attributeName} :
                      </div>
                      <div className="col-9">{cur?.variationName}</div>
                    </div>
                  );
                })
              : ""}

            <div className="d-flex align-iteams-center">
              <div className="col-3 porduct-heading-iteam">Vendor-Name :</div>
              <div className="col-9">{orderdata?.vendorName}</div>
            </div>
            <div className="d-flex align-iteams-center">
              <div className="col-3 porduct-heading-iteam">Vendor-Email:</div>
              <div className="col-9">{orderdata?.vendorEmail}</div>
            </div>
          </div>
        </div>
        <div className="my-3">
          {orderdata?.status === 3 ? (
            <div className="d-flex justify-content-center align-iteams-center">
              <div className="order-cancel-button"> Order Cancel</div>
            </div>
          ) : (
            <div className="d-flex justify-content-center align-iteams-center">
              <div className="order-single-button" onClick={handleCancel}>
                Order Cancel
              </div>
              <div className="order-single-button ms-2">Track Order</div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default MyOrderdeatils;
