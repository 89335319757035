import Footer from "Sections/HomeSection/Footer";
import NavBar from "Sections/HomeSection/NavBar";
import React, { useEffect, useState } from "react";
import "../../Style/Home/Whishlist.css";
import { MdDelete } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import { FetchWishAPI } from "../../APIS/WhislistAPI";
import { setWishlength } from "../../Redux/actions";
import { RemoveWishApi } from "../../APIS/WhislistAPI";
import CategoriesDropdeon from "Sections/HomeSection/CategoriesDropdeon";

const Whislist = ({ getUserAuthenticationRedux, setWishlengthRedux }) => {
  const navigation = useNavigate();
  const [wishData, setWishData] = useState([]);
  useEffect(() => {
    FetchwishData();
  }, [getUserAuthenticationRedux]);
  const FetchwishData = () => {
    FetchWishAPI(getUserAuthenticationRedux, (callback) => {
      setWishData(callback);
      setWishlengthRedux(callback.length);
    });
  };

  const RemoveWishData = (product_id) => {
    const formdata = {
      language: "en",
      productId: product_id,
    };

    RemoveWishApi(
      getUserAuthenticationRedux,
      formdata,
      FetchwishData,
      wishData,
      setWishData,
      setWishlengthRedux
    );
  };
  return (
    <>
      <NavBar />
      <CategoriesDropdeon />
      <div className="" style={{ minHeight: "550px" }}>
        <div className="row d-flex justify-content-center align-items-center py-md-4">
          {wishData.length !== 0 ? (
            <div className="col-lg-8 col-md-10 col-12 ">
              <div className="d-flex justify-content-center align-items-center home-wishlist-heading gap-4">
                My Wishlist
              </div>
              <div className="">
                <div className="d-md-flex d-none align-iteam-center wishlist-producat-heading">
                  <div className="col-2 produact-iteam"></div>
                  <div className="col-2 produact-iteam">Product Name</div>

                  <div className="col-2 produact-iteam">Price Sale</div>

                  <div className="col-2 produact-iteam">Price Base</div>

                  <div className="col-2 produact-iteam">View Item</div>

                  <div className="col-2 produact-iteam">Remove</div>
                </div>
                {wishData !== 0
                  ? wishData?.map((iteam, i) => {
                      const {
                        base_price,
                        featured_image,
                        name,
                        product_id,
                        sale_price,
                        slug,
                        category_name,
                      } = iteam;
                      return (
                        <div
                          className="d-flex justify-content-between align-iteam-center wish-list-producat"
                          key={i}
                        >
                          <div className="col-md-2 col-12">
                            <div className="Wish-list-iteam">
                              <img src={featured_image} alt />
                            </div>
                          </div>
                          <div className="col-md-2 col-12 Wish-list-iteam">
                            <span className="d-md-none d-flex  wish-list-small-size">
                              Product Name:
                            </span>{" "}
                            {name}
                          </div>

                          <div className="col-md-2 col-12 Wish-list-iteam">
                            <span className="d-md-none d-flex wish-list-small-size">
                              Sale Price:
                            </span>
                            ${sale_price}
                          </div>

                          <div className="col-md-2 col-12 Wish-list-iteam">
                            <span className="d-md-none d-flex wish-list-small-size">
                              Base Price:
                            </span>
                            ${base_price}
                          </div>

                          <div
                            className="col-md-2 col-12 Wish-list-iteam-view"
                            onClick={() =>
                              navigation(`/ceiling/${product_id}/${slug}`, {
                                state: category_name,
                              })
                            }
                          >
                            View Item
                          </div>

                          <div className="col-md-2 col-12 Wish-list-iteam">
                            <span className="d-md-none d-flex wish-list-small-size">
                              Remove:
                            </span>
                            <MdDelete
                              style={{ fontSize: "22px", color: "red" }}
                              onClick={() => RemoveWishData(product_id)}
                            />
                          </div>
                        </div>
                      );
                    })
                  : ""}
              </div>
            </div>
          ) : (
            <div className="container px-sm-5 px-2">
              <div
                className="mx-4 d-flex align-items-center justify-content-center"
                style={{
                  minHeight: "480px",
                  border: "1px solid #b9ccca",
                  fontSize: "30px",
                }}
              >
                {" "}
                WishList Empty
              </div>
            </div>
          )}
        </div>
      </div>
      <Footer />
    </>
  );
};
const mapStateToProps = (state) => {
  return {
    getUserAuthenticationRedux: state.userAuthentication.data,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setWishlengthRedux: (parameter) => {
      dispatch(setWishlength(parameter));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Whislist);
