import axios from "axios";
import { baseUrl } from "../baseUrl";
import { SuccessToaster, ErrorToaster } from "../Toaster";

export const Fetchuserdetails = (token, callback) => {
  let config = {
    method: "get",
    maxBodyLength: Infinity,
    url: `${baseUrl}fetch-user`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };

  axios
    .request(config)
    .then((response) => {
      callback(response?.data?.data);
    })
    .catch((error) => {
      console.log(error);

      const Unauthenticated = error?.response?.data?.message;

      if (Unauthenticated === "Unauthenticated.") {
        localStorage.removeItem("transArabiatoken");
        window.location.reload();
      }
    });
};
export const UpdateInfo = (token, formdata, Fetchuserdetails, callback) => {
  let data = JSON.stringify(formdata);
  let config = {
    method: "post",
    maxBodyLength: Infinity,
    url: `${baseUrl}info-update`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    data: data,
  };

  axios
    .request(config)
    .then((response) => {
      SuccessToaster(response?.data?.message);
      Fetchuserdetails(token, callback);
    })
    .catch((error) => {
      console.log(error);
    });
};

export const ProfileChange = (token, formdata, callback) => {
  let data = JSON.stringify(formdata);
  let config = {
    method: "post",
    maxBodyLength: Infinity,
    url: `${baseUrl}profile-update`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    data: data,
  };

  axios
    .request(config)
    .then((response) => {
      callback(response?.data?.data?.filePath);

      SuccessToaster(response?.data?.message);
    })
    .catch((error) => {
      console.log(error);
    });
};
