import React, { useState } from "react";
import "../Style/Forget.css";
import { CiMail } from "react-icons/ci";
import Otpmodel from "./Otpmodel";
import { forgetPassword } from "../APIS/AuthApi";
import logo3 from "../PublicImages/AboutSection/logo3.png";
import { ErrorToaster } from "../Toaster";
import { useNavigate } from "react-router";

const Forget = () => {
  const [email, setEmail] = useState("");
  const [open, setOpen] = React.useState(false);
  const navigation = useNavigate();

  const handlesumbmit = (e) => {
    e.preventDefault();
    if (email === "") {
      ErrorToaster("Enter your valid Email");
    } else {
      setOpen(true);
      setEmail("");
      let fromData = {
        username: email,
      };
      forgetPassword(fromData, setOpen);
    }
  };

  return (
    <div className="container-fluied">
      <div className="Row d-md-flex">
        <div className="col-md-6 col-12 loginbox">
          <div className="login-left-side">
            <div className="login-left-side-1">
              <h1>
                For<span style={{ color: "#0D8AAF" }}>get</span> to Your Account
              </h1>
              <form onSubmit={handlesumbmit}>
                <div className="mb-3 mt-4">
                  <label className="d-flex justify-content-start fw-6">
                    Email Address
                  </label>
                  <div className="left-side-email mt-2">
                    <CiMail />
                    <input
                      className="form-control"
                      type="email"
                      placeholder="Enter Your Email"
                      name="email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </div>
                </div>

                <div className="mt-5">
                  <button
                    className="btn btn-success w-100"
                    type="submit"
                    style={{
                      borderRadius: "20px",
                      backgroundColor: "#0D8AAF",
                      borderColor: "#0D8AAF",
                    }}
                  >
                    Generate Otp
                  </button>
                </div>
              </form>
              <Otpmodel open={open} setOpen={setOpen} />
            </div>
          </div>
        </div>
        <div className="col-md-6 col-12  d-md-flex d-none ">
          <div className="login-rigth-side">
            <div className="login-right-logo">
              <img src={logo3} alt="logo" onClick={() => navigation("/")} />
            </div>
            <div className="login-right-box">
              <h1> Welcome back !</h1>
              <p>
                We are glad to see you again! Get access to your Orders,
                Wishlist and Recommendations.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Forget;
