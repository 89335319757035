import React, { useEffect, useState, useRef } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { IoCloseSharp } from "react-icons/io5";
import { FetchEditReviewAPI } from "../APIS/ReviewAPI";
import ReactStars from "react-stars";
import { CiCirclePlus } from "react-icons/ci";
import { ErrorToaster } from "Toaster";
import { UpdateEditReviewAPi } from "../APIS/ReviewAPI";
import galleryicons1 from ".././PublicImages/galleryicons1.png";
import "../Style/EditReview.css";

import { UploadImageReviewApi } from "../APIS/ReviewAPI";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",
  border: "2px solid #0aa8d8c6",
  boxShadow: 24,
  p: 4,
  borderRadius: "5px",
};
const EditReviewmodel = ({
  open,
  setOpen,
  reviewId,
  getUserAuthenticationRedux,
}) => {
  const handleClose = () => setOpen(false);
  const [editReview, setEditReview] = useState([]);
  const [editFeedback, setEditFeedback] = useState("");
  const [rating, setRating] = useState("");
  const [message, setMessage] = useState("");
  const [image, setImage] = useState([]);
  const [image1, setImage1] = useState([]);
  const [image2, setImage2] = useState([]);
  const [sendimage, setSendimage] = useState([]);
  const inputRef = useRef();
  const inputRef1 = useRef();
  const inputRef2 = useRef();

  useEffect(() => {
    EditReview();
  }, [open]);
  const EditReview = () => {
    let formdata = {
      reviewId: reviewId,
    };
    if (reviewId) {
      FetchEditReviewAPI(getUserAuthenticationRedux, formdata, (callback) => {
        setEditReview(callback?.data);

        setEditFeedback(callback?.data?.title);
        setRating(callback?.data?.rating);
        setMessage(callback?.data?.message);
        setImage(callback?.data?.images[0]);
        setImage1(callback?.data?.images[1]);
        setImage2(callback?.data?.images[2]);
      });
    }
  };
  const handleUpdateImage = () => {
    inputRef.current.click();
  };
  const handleImage = (e) => {
    let file = e.target.files[0];
    let formData = new FormData();

    formData.append("image", file);

    formData.append("type", "review");

    if (!getUserAuthenticationRedux) {
      ErrorToaster("You are not login please first one is login");
    } else {
      UploadImageReviewApi(getUserAuthenticationRedux, formData, (callback) => {
        setSendimage([...sendimage, callback?.data?.imgSend]);
        setImage(callback?.data?.img);
      });
    }
  };
  const handleUpdateImage1 = () => {
    inputRef1.current.click();
  };
  const handleImage1 = (e) => {
    let file = e.target.files[0];

    let formData = new FormData();

    formData.append("image", file);
    formData.append("type", "review");
    if (!getUserAuthenticationRedux) {
      ErrorToaster("You are not login please first one is login");
    } else {
      UploadImageReviewApi(getUserAuthenticationRedux, formData, (callback) => {
        setSendimage([...sendimage, callback?.data?.imgSend]);
        setImage1(callback?.data?.img);
      });
    }
  };
  const handleUpdateImage2 = () => {
    inputRef2.current.click();
  };
  const handleImage2 = (e) => {
    let file = e.target.files[0];

    let formData = new FormData();

    formData.append("image", file);
    formData.append("type", "review");
    if (!getUserAuthenticationRedux) {
      ErrorToaster("You are not login please first one is login");
    } else {
      UploadImageReviewApi(getUserAuthenticationRedux, formData, (callback) => {
        setSendimage([...sendimage, callback?.data?.imgSend]);
        setImage2(callback?.data?.img);
      });
    }
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    let fromdata = {
      reviewId: reviewId,
      productId: editReview?.productId,
      title: editFeedback,
      description: message,
      rating: rating,
      images: sendimage,
    };
    UpdateEditReviewAPi(getUserAuthenticationRedux, fromdata, setOpen);
  };

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} className="responsive-model-box">
          <div className=" d-flex justify-content-between">
            <div className="Edit-Review-heading">Edit Review</div>
            <div className="Edit-Review-heading">
              <IoCloseSharp onClick={handleClose} />
            </div>
          </div>
          <form onSubmit={handleSubmit}>
            <div className="mb-3">
              <ReactStars
                count={5}
                type="text"
                onChange={(newRating) => setRating(newRating)}
                name="rating"
                value={rating}
                size={16}
                color1={"gray"}
                color2={"#ffd700"}
                half={true}
              />
            </div>
            <div className="mb-3">
              <label className="form-label">Enter Your Feedback</label>
              <input
                className="form-control"
                type="text"
                name="feedback"
                value={editFeedback}
                onChange={(e) => setEditFeedback(e.target.value)}
              />
            </div>
            <div className="mb-3">
              <label className="form-label">Message</label>
              <textarea
                className="form-control"
                rows="3"
                name="message"
                type="text"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
              ></textarea>
            </div>
            <div className="mb-3">
              <label className="form-label">Choose File</label>
              <div className="d-flex">
                <div className="">
                  <div
                    className="edit-reviews-imgae"
                    onClick={handleUpdateImage}
                  >
                    <img
                      src={image?.length > 0 ? image : galleryicons1}
                      alt=""
                    />
                    <CiCirclePlus />
                  </div>
                  <input
                    type="file"
                    ref={inputRef}
                    onChange={handleImage}
                    style={{ display: "none" }}
                  />
                </div>

                <div className="">
                  <div
                    className="edit-reviews-imgae"
                    onClick={handleUpdateImage1}
                  >
                    <img
                      src={image1?.length > 0 ? image1 : galleryicons1}
                      alt=""
                    />
                    <CiCirclePlus />
                  </div>
                  <input
                    type="file"
                    ref={inputRef1}
                    onChange={handleImage1}
                    style={{ display: "none" }}
                  />
                </div>
                <div className="">
                  <div
                    className="edit-reviews-imgae"
                    onClick={handleUpdateImage2}
                  >
                    <img
                      src={image2?.length > 0 ? image2 : galleryicons1}
                      alt=""
                    />
                    <CiCirclePlus />
                  </div>
                  <input
                    type="file"
                    ref={inputRef2}
                    onChange={handleImage2}
                    style={{ display: "none" }}
                  />
                </div>
              </div>
            </div>
            <div className="mb-3">
              <button className="Add-reviews-button" type="submit">
                Upadte Review
              </button>
            </div>
          </form>
        </Box>
      </Modal>
    </>
  );
};

export default EditReviewmodel;
