import React, { useEffect, useState } from "react";
import "Style/Home/RatingSection.css";
import Slider from "react-slick";
import { FaStar } from "react-icons/fa";
import testimonial_img_one from "PublicImages/Testimonial/testimonial_img.png";
import { TestiMonialsAPI } from "../../APIS/api";

const RatingSection = () => {
  const testimonialData = [1, 2, 3, 4, 5];
  const [testimonial, setTestimonial] = useState([]);
  useEffect(() => {
    testmonialdata();
  }, []);
  const testmonialdata = () => {
    let fromdata = {
      language: "en",
    };
    TestiMonialsAPI(fromdata, (callback) => {
      setTestimonial(callback?.testimonial_list);
    });
  };
  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 2,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 1025,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
    ],
  };

  return (
    <>
      <div className="RatingSection">
        <div className="text-center">
          <h3>RATING AND REVIEW</h3>
          <p className="pb-3">
            Lorem Ipsum is simply dummy text of the printing and type there
            setting industry.
          </p>
        </div>
        <div className="Testinomial_slider  row ">
          <Slider {...settings}>
            {testimonial.map((data, i) => {
              const { name_en, designation, comment, image } = data;
              return (
                <div className="col-md-6 p-3" key={i}>
                  <div className="card_sec">
                    <div className="row d-flex justify-content-center align-items-start ">
                      <div className="col-md-3 col-12">
                        <div className="testimonial_profile d-flex justify-content-center align-items-center">
                          <img
                            src={image ? image : testimonial_img_one}
                            alt="test"
                          />
                        </div>
                      </div>
                      <div className="col-md-9 col-12">
                        <div className="d-flex align-items-center justify-content-between ">
                          <h5>
                            {name_en} -
                            <span className="opacity-50"> {designation}</span>
                          </h5>
                        </div>
                        <p className="text-start slider_content">{comment}</p>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </Slider>
        </div>
      </div>
    </>
  );
};

export default RatingSection;
