import axios from "axios";
import { baseUrl } from "../baseUrl";
import { SuccessToaster, ErrorToaster } from "../Toaster";

export const registerApi = (fromData, navigation) => {
  let data = JSON.stringify(fromData);
  // console.log(fromData)
  let config = {
    method: "post",
    maxBodyLength: Infinity,
    url: `${baseUrl}register`,
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };
  axios
    .request(config)
    .then((response) => {
      console.log(response);
      console.log(response.data.data.user);
      SuccessToaster(response?.data?.message);

      navigation("/login");
    })
    .catch((error) => {
      // console.log(error)
      let err = error?.response?.data?.message;
      if (err.length > 0) {
        console.log(error?.response?.data?.message);
        ErrorToaster(error?.response?.data?.message);
      } else {
        ErrorToaster(error?.response?.data?.message);
      }
      console.log(error, "error");
    });
};

export const loginModelApi = (fromData, setUserAuthenticationRedux) => {
  let data = JSON.stringify(fromData);
  let config = {
    method: "post",
    maxBodyLength: Infinity,
    url: `${baseUrl}login`,
    headers: {
      Content_Type: "application/json",
    },
    data: data,
  };
  axios
    .request(config)
    .then((response) => {
      setUserAuthenticationRedux(response.data.data.token?.replaceAll('"', ""));

      localStorage.setItem("transArabiatoken", response?.data?.data?.token);

      SuccessToaster(response?.data?.message);
    })
    .catch((error) => {
      let err = error?.response?.data?.message;
      if (err.length > 0) {
        console.log(error?.response?.data?.message);
        ErrorToaster(error?.response?.data?.message);
      } else {
        ErrorToaster(error?.response?.data?.message);
      }
      console.log(error, "error");
    });
};

export const loginApi = (fromData, navigation, setUserAuthenticationRedux) => {
  let data = JSON.stringify(fromData);
  let config = {
    method: "post",
    maxBodyLength: Infinity,
    url: `${baseUrl}login`,
    headers: {
      Content_Type: "application/json",
    },
    data: data,
  };
  axios
    .request(config)
    .then((response) => {
      console.log(response);

      setUserAuthenticationRedux(response.data.data.token?.replaceAll('"', ""));

      localStorage.setItem("transArabiatoken", response?.data?.data?.token);
      navigation("/");
      SuccessToaster(response?.data?.message);
    })
    .catch((error) => {
      let err = error?.response?.data?.message;
      if (err.length > 0) {
        console.log(error?.response?.data?.message);
        ErrorToaster(error?.response?.data?.message);
      } else {
        ErrorToaster(error?.response?.data?.message);
      }
      console.log(error, "error");
    });
};

export const forgetPassword = (fromData, setOpen) => {
  let data = JSON.stringify(fromData);

  let config = {
    method: "post",
    maxBodyLength: Infinity,
    url: `${baseUrl}forgot-password`,
    headers: {
      Content_Type: "application/json",
    },
    data: data,
  };
  axios
    .request(config)
    .then((response) => {
      console.log(response);
      SuccessToaster(response?.data?.message);
      setOpen(true);
    })
    .catch((error) => {
      let err = error?.response?.data?.message;
      if (err.length > 0) {
        console.log(error?.response?.data?.message);
        ErrorToaster(error?.response?.data?.message[0]);
      } else {
        ErrorToaster(error?.response?.data?.message);
      }
      console.log(error, "error");
    });
};

export const Otpverify = (OTP, navigation) => {
  let data = JSON.stringify({
    otp: OTP,
  });
  let config = {
    method: "post",
    maxBodyLength: Infinity,
    url: `${baseUrl}otp-verification`,
    headers: {
      Content_Type: "application/json",
    },
    data: data,
  };
  axios
    .request(config)
    .then((response) => {
      console.log(response);

      navigation("/resetpassword", {
        state: { token: response?.data?.data?.password_reset_token },
      });
      SuccessToaster(response?.data?.message);
    })
    .catch((error) => {
      let err = error?.response?.data?.message;
      if (err.length > 0) {
        console.log(error?.response?.data?.message);
        ErrorToaster(error?.response?.data?.message);
      } else {
        ErrorToaster(error?.response?.data?.message);
      }
      console.log(error, "error");
    });
};
export const resetpassword = (fromData, navigation) => {
  let data = JSON.stringify(fromData);

  let config = {
    method: "post",
    maxBodyLength: Infinity,
    url: `${baseUrl}reset-password`,
    headers: {
      Content_Type: "application/json",
    },
    data: data,
  };
  axios
    .request(config)
    .then((response) => {
      navigation("/login");
      SuccessToaster(response?.data?.message);
    })
    .catch((error) => {
      let err = error?.response?.data?.message;
      if (err.length > 0) {
        console.log(error?.response?.data?.message);
        ErrorToaster(error?.response?.data?.message[0]);
      } else {
        ErrorToaster(error?.response?.data?.message);
      }
      console.log(error, "error");
    });
};

export const logOutAPI = (token, navigation) => {
  let config = {
    method: "get",
    maxBodyLength: Infinity,
    url: `${baseUrl}logout`,
    headers: {
      Content_Type: "application/json",
      Authorization: `Bearer ${token}`,
    },
  };
  axios
    .request(config)
    .then((response) => {
      SuccessToaster(response?.data?.message);
      if (response?.data?.status === "success") {
        localStorage.removeItem("transArabiatoken");
        navigation("/");
        window.location.reload();
      }
    })
    .catch((error) => {
      let err = error?.response?.data?.message;
      if (err.length > 0) {
        ErrorToaster(error?.response?.data?.message);
      } else {
        ErrorToaster(error?.response?.data?.message);
      }
      console.log(error, "error");
    });
};
export const passwordpasswordAPI = (token, fromData) => {
  let data = JSON.stringify(fromData);

  let config = {
    method: "post",
    maxBodyLength: Infinity,
    url: `${baseUrl}password-change`,
    headers: {
      Content_Type: "application/json",
      Authorization: `Bearer ${token}`,
    },
    data: data,
  };
  axios
    .request(config)
    .then((response) => {
      SuccessToaster(response?.data?.message);
    })
    .catch((error) => {
      let err = error?.response?.data?.message;
      if (err.length > 0) {
        console.log(error?.response?.data?.message);
        ErrorToaster(error?.response?.data?.message);
      } else {
        ErrorToaster(error?.response?.data?.message);
      }
      console.log(error, "error");
    });
};
export const DeleteAccountAPI = (token, fromData) => {
  let data = JSON.stringify(fromData);

  let config = {
    method: "post",
    maxBodyLength: Infinity,
    url: `${baseUrl}delete-account`,
    headers: {
      Content_Type: "application/json",
      Authorization: `Bearer ${token}`,
    },
    data: data,
  };
  axios
    .request(config)
    .then((response) => {
      SuccessToaster(response?.data?.message);
      if (response?.data?.status === "success") {
        localStorage.removeItem("transArabiatoken");
        window.location.reload();
      }
    })
    .catch((error) => {
      let err = error?.response?.data?.message;
      if (err.length > 0) {
        console.log(error?.response?.data?.message);
        ErrorToaster(error?.response?.data?.message);
      } else {
        ErrorToaster(error?.response?.data?.message);
      }
      console.log(error, "error");
    });
};
