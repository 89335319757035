import React, { useEffect, useState } from "react";
import { RiArrowDropDownLine } from "react-icons/ri";
import { Link, useNavigate } from "react-router-dom";
import "Style/Home/CategoriesDropDown.css";
import { MegaMenuAPI } from "../../APIS/MegamenuAPI";

const CategoriesDropdeon = () => {
  const [megaMenudata, SetMegaMenudata] = useState("");
  const navigation = useNavigate();
  useEffect(() => {
    MegaMenuAPI((callback) => {
      SetMegaMenudata(callback);
    });
  }, []);

  return (
    <>
      <hr className="m-0"></hr>
      <div className="CategoriesDropdown">
        <div className="row">
          <div className="col-md-8">
            <nav>
              <div className="wrapper">
                <ul className="nav-links p-0 d-flex justify-content-between align-items-center m-0">
                  <li>
                    <Link to="#" className="desktop-item">
                      Mega Menu
                      <RiArrowDropDownLine className="CategoriesDropdown_icon" />
                    </Link>
                    <div className="mega-box">
                      <div className="row content ">
                        {megaMenudata?.categories?.length > 0
                          ? megaMenudata?.categories.map((iteam, i) => {
                              const {
                                parent_cat_id,
                                parent_cat_name,
                                parent_cat_slug,
                              } = iteam;

                              return (
                                <div className="col-md-2" key={i}>
                                  <header
                                    onClick={() =>
                                      navigation(
                                        `/solar/${parent_cat_id}/${parent_cat_slug}`
                                      )
                                    }
                                    style={{ cursor: "pointer" }}
                                  >
                                    {parent_cat_name}
                                  </header>
                                  <ul className="mega-links p-0">
                                    {iteam?.child_categories !== "null"
                                      ? iteam?.child_categories?.map(
                                          (elem, i) => {
                                            const {
                                              id,
                                              name_en,
                                              slug_en,
                                            } = elem;
                                            return (
                                              <li key={i}>
                                                <Link
                                                  to=""
                                                  onClick={() =>
                                                    navigation(
                                                      `/solar/${id}/${slug_en}`
                                                    )
                                                  }
                                                >
                                                  {name_en}
                                                </Link>
                                              </li>
                                            );
                                          }
                                        )
                                      : ""}
                                  </ul>
                                </div>
                              );
                            })
                          : ""}
                      </div>
                    </div>
                  </li>

                  <li>
                    <Link to="/about-us" className="desktop-item">
                      About Us
                    </Link>
                  </li>
                  <li>
                    <Link to="/policies" className="desktop-item">
                      Policies
                    </Link>
                  </li>
                  <li>
                    <Link to="/contact-us" className="desktop-item">
                      Contact Us
                    </Link>
                  </li>
                  <li>
                    <Link
                      to=""
                      className="desktop-item"
                      onClick={() => navigation("/blog")}
                    >
                      Blog
                    </Link>
                  </li>
                  <li></li>
                </ul>
              </div>
            </nav>
          </div>
        </div>
      </div>
    </>
  );
};

export default CategoriesDropdeon;
