import axios from "axios";
import { baseUrl } from "../baseUrl";
import { SuccessToaster, ErrorToaster } from "../Toaster";

export const FetchCartAPI = (token, callback) => {
  let data = JSON.stringify({
    language: "en",
    page: 1,
  });

  let config = {
    method: "post",
    maxBodyLength: Infinity,
    url: `${baseUrl}fetch-carts`,
    headers: {
      Content_Type: "application/json",
      Authorization: `Bearer ${token}`,
    },
    data: data,
  };
  axios
    .request(config)
    .then((response) => {
      callback(response?.data?.data);
    })
    .catch((error) => {
      console.log("error", error);
      console.log(error?.response?.data?.message, "message");
    });
};
export const UpdateCartApi = (token, formdata, FetchCartdata) => {
  let data = JSON.stringify(formdata);
  let config = {
    method: "post",
    maxBodyLength: Infinity,
    url: `${baseUrl}update-to-cart`,
    headers: {
      Content_Type: "application/json",
      Authorization: `Bearer ${token}`,
    },
    data: data,
  };
  axios
    .request(config)
    .then((response) => {
      FetchCartdata();
      SuccessToaster(response?.data?.message);
    })
    .catch((error) => {
      let err = error?.response?.data?.message;
      if (err.length > 0) {
        console.log(error?.response?.data?.message);
      } else {
      }
    });
};
export const RemoveCartApi = (
  token,
  formdata,
  FetchCartdata,
  cartData,
  setCartData,
  setCartlengthRedux
) => {
  let data = JSON.stringify(formdata);
  let config = {
    method: "post",
    maxBodyLength: Infinity,
    url: `${baseUrl}remove-to-cart`,
    headers: {
      Content_Type: "application/json",
      Authorization: `Bearer ${token}`,
    },
    data: data,
  };
  axios
    .request(config)
    .then((response) => {
      console.log(response);
      SuccessToaster(response?.data?.message);
      if (cartData.length === 1) {
        setCartData([]);
        setCartlengthRedux(0);
        return;
      }
      FetchCartdata();
    })
    .catch((error) => {
      let err = error?.response?.data?.message;
      if (err.length > 0) {
        console.log(error?.response?.data?.message);
      } else {
      }
    });
};
export const ADDCartApi = (
  token,
  formdata,
  FetchCartAPI,
  setCartlengthRedux
) => {
  let data = JSON.stringify(formdata);
  let config = {
    method: "post",
    maxBodyLength: Infinity,
    url: `${baseUrl}add-to-cart`,
    headers: {
      Content_Type: "application/json",
      Authorization: `Bearer ${token}`,
    },
    data: data,
  };
  axios
    .request(config)
    .then((response) => {
      SuccessToaster(response?.data?.message);
      FetchCartAPI(token, (callback) => {
        setCartlengthRedux(callback?.cart_items?.length);
      });
    })
    .catch((error) => {
      let err = error?.response?.data?.message;

      if (err.length > 0) {
        console.log(error?.response?.data?.message);
        ErrorToaster(error?.response?.data?.message);
      } else {
      }
    });
};
export const SinglevariationChild = (token, formdata, callback) => {
  let data = JSON.stringify(formdata);
  let config = {
    method: "post",
    maxBodyLength: Infinity,
    url: `${baseUrl}single-product-variation-child`,
    headers: {
      Content_Type: "application/json",
      Authorization: `Bearer ${token}`,
    },
    data: data,
  };
  axios
    .request(config)
    .then((response) => {
      callback(response?.data);
    })
    .catch((error) => {
      let err = error?.response?.data?.message;
      if (err.length > 0) {
        console.log(error?.response?.data?.message);
      } 
    });
};
export const secondvariationAPI = (token, fromdata) => {
  let data = JSON.stringify(fromdata);
  let config = {
    method: "post",
    maxBodyLength: Infinity,
    url: `${baseUrl}single-product-variation`,
    headers: {
      Content_Type: "application/json",
      Authorization: `Bearer ${token}`,
    },
    data: data,
  };
  axios
    .request(config)
    .then((response) => {
      console.log(response);
      console.log(response?.data);

      // SuccessToaster(response?.data?.message)
      // callback(response?.data)
    })
    .catch((error) => {
      let err = error?.response?.data?.message;
      if (err.length > 0) {
        console.log(error?.response?.data?.message);
      } 
    });
};
