import { createStore, combineReducers, applyMiddleware } from "redux";
import { thunk } from "redux-thunk";
import userAuthenticationReducer from "./Reducer/userAuthenticationReducer";
import cartLengthReducer from "./Reducer/CartLength";
import wishLengthReducer from "./Reducer/WishLength";
import userInformationReducer from "./Reducer/UserInformation";
import CategroydataReducer from "./Reducer/Categroydrop";
export const rootReducer = combineReducers({
  userAuthentication: userAuthenticationReducer,
  cartLength: cartLengthReducer,
  wishLength: wishLengthReducer,
  userInfo: userInformationReducer,
  Categroy: CategroydataReducer,
});

const store = createStore(rootReducer, applyMiddleware(thunk));

export default store;
