import axios from "axios";
import { baseUrl } from "../baseUrl";
import { SuccessToaster, ErrorToaster } from "../Toaster";
export const OrdercreateAPI = (
  token,
  formdata,
  navigation,
  setCartlengthRedux,
  callback
) => {
  let data = JSON.stringify(formdata);
  let config = {
    method: "post",
    maxBodyLength: Infinity,
    url: `${baseUrl}order-create`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    data: data,
  };

  axios
    .request(config)
    .then((response) => {
      callback(response?.data);

      // navigation("/")
      SuccessToaster(response?.data?.message);
      setCartlengthRedux(0);
    })
    .catch((error) => {
      console.log(error);
    });
};
export const OrderFetchAPI = (token, formdata, callback) => {
  let data = JSON.stringify(formdata);
  let config = {
    method: "post",
    maxBodyLength: Infinity,
    url: `${baseUrl}order-list`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    data: data,
  };

  axios
    .request(config)
    .then((response) => {
      callback(response?.data);
    })
    .catch((error) => {
      console.log(error);
    });
};
export const MyorderFetchAPi = (token, formdata, callback) => {
  let data = JSON.stringify(formdata);
  let config = {
    method: "post",
    maxBodyLength: Infinity,
    url: `${baseUrl}order-items-fetch`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    data: data,
  };

  axios
    .request(config)
    .then((response) => {
      callback(response?.data?.data);
    })
    .catch((error) => {
      console.log(error);
    });
};

export const OrdercancelAPI = (token, fromdata, orderIteamdeatails) => {
  let data = JSON.stringify(fromdata);
  let config = {
    method: "post",
    maxBodyLength: Infinity,
    url: `${baseUrl}order-cancel`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    data: data,
  };

  axios
    .request(config)
    .then((response) => {
      SuccessToaster(response?.data?.message);
      orderIteamdeatails();
    })
    .catch((error) => {
      console.log(error);
    });
};
