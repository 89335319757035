import React, { useEffect, useRef, useState } from "react";
import "../../Style/Reviewsection.css";
import ReactStars from "react-stars";
import { CiCirclePlus } from "react-icons/ci";
import { ErrorToaster } from "Toaster";
import { UploadImageReviewApi } from "../../APIS/ReviewAPI";
import { AddReviewAPI } from "../../APIS/ReviewAPI";
import { FetchReviewAPI } from "../../APIS/ReviewAPI";
import { connect } from "react-redux";
import { CiEdit } from "react-icons/ci";
import { NavLink } from "react-router-dom";
import EditReviewmodel from "Model/EditReviewmodel";
import { MdDelete } from "react-icons/md";
import { DeleteReviewAPi } from "../../APIS/ReviewAPI";
import galleryicons1 from "../../PublicImages/galleryicons1.png";
import InfiniteScroll from "react-infinite-scroll-component";

const ReviewSection = ({
  productId,
  getUserInfoRedux,
  getUserAuthenticationRedux,
}) => {
  const inputRef = useRef();
  const inputRef1 = useRef();
  const inputRef2 = useRef();
  const userId = getUserInfoRedux?.user?.id;

  const [rating, setRating] = useState();
  const [feedback, setFeedback] = useState();
  const [message, setMessage] = useState();
  const [image, setImage] = useState([]);
  const [image1, setImage1] = useState([]);
  const [image2, setImage2] = useState([]);
  const [sendimage, setSendimage] = useState([]);
  const [reviewuser, setReviewuser] = useState([]);
  const [allreview, setAllreview] = useState([]);
  const [reviewslength, setReviewslength] = useState("");
  const [currentPage, setCurrentPage] = useState(2);
  const [open, setOpen] = useState(false);
  const [reviewId, setReviewId] = useState();

  let transAribai_token = localStorage.getItem("transArabiatoken");

  const handleSubmit = (e) => {
    e.preventDefault();

    if (rating === "" || feedback === "" || message === "") {
      ErrorToaster("All Field Is Requierd");
    } else {
      let fromdata = {
        productId: productId,
        title: feedback,
        description: message,
        images: sendimage,
        rating: rating,
      };

      AddReviewAPI(transAribai_token, fromdata);
      setImage("");
      setImage1("");
      setImage2("");

      setFeedback("");
      setMessage("");
      setRating("");
    }
  };
  const handleUpdateImage = () => {
    inputRef.current.click();
  };
  const handleImage = (e) => {
    let file = e.target.files[0];
    let formData = new FormData();

    formData.append("image", file);

    formData.append("type", "review");

    if (!transAribai_token) {
      ErrorToaster("You are not login please first one is login");
    } else {
      UploadImageReviewApi(transAribai_token, formData, (callback) => {
        setSendimage([...sendimage, callback?.data?.imgSend]);
        setImage(callback?.data?.img);
      });
    }
  };
  const handleUpdateImage1 = () => {
    inputRef1.current.click();
  };
  const handleImage1 = (e) => {
    let file = e.target.files[0];

    let formData = new FormData();

    formData.append("image", file);
    formData.append("type", "review");
    if (!transAribai_token) {
      ErrorToaster("You are not login please first one is login");
    } else {
      UploadImageReviewApi(transAribai_token, formData, (callback) => {
        setSendimage([...sendimage, callback?.data?.imgSend]);
        setImage1(callback?.data?.img);
      });
    }
  };
  const handleUpdateImage2 = () => {
    inputRef2.current.click();
  };
  const handleImage2 = (e) => {
    let file = e.target.files[0];

    let formData = new FormData();

    formData.append("image", file);
    formData.append("type", "review");
    if (!transAribai_token) {
      ErrorToaster("You are not login please first one is login");
    } else {
      UploadImageReviewApi(transAribai_token, formData, (callback) => {
        setSendimage([...sendimage, callback?.data?.imgSend]);
        setImage2(callback?.data?.img);
      });
    }
  };

  useEffect(() => {
    FetchReviewrating();
  }, [userId]);

  const FetchReviewrating = () => {
    let fromdata = {
      language: "en",
      page: 1,
      productId: productId,
      uid: userId,
      filter: "highRating",
    };

    FetchReviewAPI(fromdata, (callback) => {
      setReviewslength(callback?.total_reviews);
      setReviewuser(callback?.data?.reviewsUser);
      setAllreview(callback?.data?.reviews);
    });
  };

  const reviewoutputscroll = () => {
    let fromdata = {
      language: "en",
      page: currentPage,
      productId: productId,
      uid: userId,
      filter: "highRating",
    };

    FetchReviewAPI(fromdata, (callback) => {
      setReviewslength(callback?.total_reviews);

      setAllreview([...allreview, ...callback?.data?.reviews]);
      setCurrentPage(currentPage + 1);
    });
  };

  const handleEditReview = (editId) => {
    setOpen(true);

    setReviewId(editId);
  };

  const handledelete = (id) => {
    let fromdata = {
      reviewId: id,
    };
    if (!transAribai_token) {
      ErrorToaster("You are not login please first one is login");
    } else {
      DeleteReviewAPi(transAribai_token, fromdata);
    }
  };

  return (
    <div className="Review-section">
      <div className="row">
        <div className="col-lg-9 col-md-12 col-12">
          <div className="row">
            <div className="col-md-6 col-12">
              <div className="Add-review">
                <h3>Add Your Review</h3>
                <form onSubmit={handleSubmit}>
                  <div className="mb-3">
                    <ReactStars
                      count={5}
                      type="text"
                      onChange={(newRating) => setRating(newRating)}
                      name="rating"
                      value={rating}
                      size={16}
                      color1={"gray"}
                      color2={"#ffd700"}
                      half={true}
                    />
                  </div>
                  <div className="mb-3">
                    <label className="form-label">Enter Your Feedback</label>
                    <input
                      className="form-control"
                      type="text"
                      name="feedback"
                      value={feedback}
                      onChange={(e) => setFeedback(e.target.value)}
                    />
                  </div>
                  <div className="mb-3">
                    <label className="form-label">Message</label>
                    <textarea
                      className="form-control"
                      rows="3"
                      name="message"
                      type="text"
                      value={message}
                      onChange={(e) => setMessage(e.target.value)}
                    ></textarea>
                  </div>
                  <div className="mb-3">
                    <label className="form-label">Choose File</label>
                    <div className="d-flex">
                      <div className="">
                        <div
                          className="Add-reviews-imgae"
                          onClick={handleUpdateImage}
                        >
                          <img
                            src={image?.length > 0 ? image : galleryicons1}
                            alt=""
                          />
                          <CiCirclePlus />
                        </div>
                        <input
                          type="file"
                          ref={inputRef}
                          onChange={handleImage}
                          style={{ display: "none" }}
                        />
                      </div>

                      <div className="">
                        <div
                          className="Add-reviews-imgae"
                          onClick={handleUpdateImage1}
                        >
                          <img
                            src={image1?.length > 0 ? image1 : galleryicons1}
                            alt=""
                          />
                          <CiCirclePlus />
                        </div>
                        <input
                          type="file"
                          ref={inputRef1}
                          onChange={handleImage1}
                          style={{ display: "none" }}
                        />
                      </div>
                      <div className="">
                        <div
                          className="Add-reviews-imgae"
                          onClick={handleUpdateImage2}
                        >
                          <img
                            src={image2?.length > 0 ? image2 : galleryicons1}
                            alt=""
                          />
                          <CiCirclePlus />
                        </div>
                        <input
                          type="file"
                          ref={inputRef2}
                          onChange={handleImage2}
                          style={{ display: "none" }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="mb-3">
                    <button className="Add-reviews-button" type="submit">
                      Submit
                    </button>
                  </div>
                </form>
              </div>
            </div>
            <div className="col-md-6 col-12">
              <div className="Review-rating" id="scrollableDiv">
                <InfiniteScroll
                  dataLength={allreview?.length}
                  hasMore={allreview?.length < reviewslength}
                  next={() => reviewoutputscroll()}
                  loader={
                    <p style={{ textAlign: "center" }}>
                      <b>Loading...</b>
                    </p>
                  }
                  scrollableTarget="scrollableDiv"
                  endMessage={
                    <p style={{ textAlign: "center" }}>
                      <b>Yay! You have seen it all</b>
                    </p>
                  }
                >
                  <h3>Review & Rating</h3>
                  {reviewuser?.length > 0
                    ? reviewuser?.map((iteam, i) => {
                        const {
                          title,
                          message,
                          rating,
                          images,
                          createdAt,
                          createdBy,
                          id,
                        } = iteam;

                        return (
                          <div className="user-review" key={i}>
                            <h6>
                              {createdBy?.name_en}{" "}
                              <span onClick={() => handleEditReview(id)}>
                                <CiEdit
                                  style={{
                                    color: "#0d8aaf",
                                    cursor: "pointer",
                                    fontSize: "22px",
                                  }}
                                />
                              </span>
                              <span
                                className="ms-2"
                                onClick={() => handledelete(id)}
                              >
                                <MdDelete
                                  style={{
                                    color: "red",
                                    cursor: "pointer",
                                    fontSize: "22px",
                                  }}
                                />
                              </span>
                            </h6>
                            <div className="d-flex align-items-center">
                              <ReactStars
                                count={5}
                                value={rating}
                                size={16}
                                color1={"gray"}
                                half={true}
                              />
                              <span className="mx-2">{createdAt}</span>
                            </div>
                            <div className="d-flex">
                              {images?.length > 0
                                ? images?.map((iteam, i) => {
                                    return (
                                      <div
                                        className="Review-rating-section"
                                        key={i}
                                      >
                                        <img
                                          src={iteam?.length > 0 ? iteam : ""}
                                          alt=""
                                        />
                                      </div>
                                    );
                                  })
                                : ""}
                            </div>
                            <div className="user-review-descraption">
                              <p className="mb-1">{title}</p>
                              <p className="mb-1">{message}</p>
                            </div>
                          </div>
                        );
                      })
                    : ""}

                  {allreview?.length > 0
                    ? allreview?.map((review, i) => {
                        const {
                          title,
                          message,
                          rating,
                          images,
                          createdAt,
                          createdBy,
                        } = review;
                        return (
                          <div className="All-fecth-user my-3" key={i}>
                            <h6>{createdBy?.name_en}</h6>
                            <div className="d-flex align-items-center">
                              <ReactStars
                                count={5}
                                value={rating}
                                size={16}
                                color1={"gray"}
                                half={true}
                              />
                              <span className="mx-2">{createdAt}</span>
                              {/* <span>time</span> */}
                            </div>
                            <div className="d-flex">
                              {images
                                ? images?.map((iteam, i) => {
                                    return (
                                      <div
                                        className="Review-rating-section"
                                        key={i}
                                      >
                                        <img
                                          src={iteam?.length > 0 ? iteam : ""}
                                          alt=""
                                        />
                                      </div>
                                    );
                                  })
                                : ""}
                            </div>
                            <div className="All-fetch-user-discraption">
                              <p className="mb-1">{title}</p>
                              <p className="mb-1">{message}</p>
                            </div>
                          </div>
                        );
                      })
                    : ""}
                </InfiniteScroll>
              </div>
            </div>
            <EditReviewmodel
              open={open}
              setOpen={setOpen}
              reviewId={reviewId}
              getUserAuthenticationRedux={getUserAuthenticationRedux}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    getUserAuthenticationRedux: state.userAuthentication.data,
    getUserInfoRedux: state.userInfo.data,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(ReviewSection);
