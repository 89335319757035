import React, { useEffect, useState } from "react";
import Footer from "Sections/HomeSection/Footer";
import NavBar from "Sections/HomeSection/NavBar";
import "../../Style/Checkout.css";
import { FetchAddressAPI } from "../../APIS/api";
import { FetchCartAPI } from "../../APIS/CartAPI";
import { connect } from "react-redux";
import { RemoveAddressAPI } from "../../APIS/api";
import Addadressmodel from "Model/Addadressmodel";
import Editadressmodel from "Model/Editadressmodel";
import { FaRegHandPointRight } from "react-icons/fa";
import { ErrorToaster } from "Toaster";
import { OrdercreateAPI } from "../../APIS/OrderCreateAPI";
import { useNavigate } from "react-router-dom";
import { setCartlength } from "../../Redux/actions";
import Ordercheckoutmodel from "Model/Ordercheckoutmodel";
const CheckOutpage = ({ getUserAuthenticationRedux, setCartlengthRedux }) => {
  const [cartInfo, setCartInfo] = useState([]);
  const [adderss, setAddress] = useState([]);
  const [AddressID, setAddressID] = useState();
  const [openAdd, setOpenAdd] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [EditAddress, setEditAddress] = useState([]);
  const [cartIteam, setCartIteam] = useState([]);
  const [orderId, setOrderId] = useState([]);
  const [open, setOpen] = useState(false);
  const navigation = useNavigate();
  const [paymentMethod, setPaymentMethod] = useState({
    type: "",
  });

  useEffect(() => {
    FetchAddress();
    Cartdata();
  }, []);
  const FetchAddress = () => {
    FetchAddressAPI(getUserAuthenticationRedux, (callback) => {
      setAddress(callback?.addressList);
    });
  };
  const Cartdata = () => {
    FetchCartAPI(getUserAuthenticationRedux, (callback) => {
      setCartIteam(callback?.cart_items);
      setCartInfo(callback?.couponInfo);
    });
  };

  const handleRemoveAddress = (id) => {
    let fromdata = {
      addressId: id,
    };

    RemoveAddressAPI(getUserAuthenticationRedux, fromdata, FetchAddress);
  };
  const handleEdit = (data) => {
    setEditAddress(data);
    setOpenEdit(true);
  };
  const iteam = cartIteam?.map((cur, i) => {
    return {
      quantity: cur.quantity,
      variationId: cur.variationId,
      productId: cur.productDetails[0].id,
      base_price: cur.variationData.base_price,
      sale_price: cur.variationData.sale_price,
      sku: cur.variationData.sku,
    };
  });

  const OrderCreateHandle = () => {
    if (!AddressID) {
      ErrorToaster("Please Selected Address");
    } else {
      let fromdata = {
        addressId: AddressID,
        couponId: cartInfo[0]?.couponId,
        discountAmount: cartInfo[0]?.discountAmount,
        totalAmount: cartInfo[0]?.subtotalAmount,
        payAmount: cartInfo[0]?.grandTotal,
        payment_method: paymentMethod?.type,
        txn_id: "tok_bypassPending",
        payment_status: 1,
        billing_shipping_address: 1,
        item: iteam,
      };

      OrdercreateAPI(
        getUserAuthenticationRedux,
        fromdata,
        navigation,
        setCartlengthRedux,
        (callback) => {
          setOrderId(callback);
          setOpen(true);
        }
      );
    }
  };

  return (
    <>
      <NavBar />
      <div className="container">
        <div className="row my-3">
          <div className="col-md-4 col-12">
            <div className="checkout-balance">
              <h5>Payment Details</h5>
              <div className="table-responsive">
                <table className="table mb-0">
                  <tbody>
                    <tr>
                      <td>Sub Total:</td>
                      <td className="text-end">
                        ${cartInfo[0]?.subtotalAmount}
                      </td>
                    </tr>
                    <tr>
                      <td>Discount:</td>
                      <td className="text-end">
                        ${cartInfo[0]?.discountAmount}
                      </td>
                    </tr>
                    <tr>
                      <td>Shipping Charge :</td>
                      <td className="text-end">Free</td>
                    </tr>

                    <tr>
                      <td className="bg-light">Total:</td>
                      <td className="text-end">
                        <span className="fw-bold">
                          ${cartInfo[0]?.grandTotal}
                        </span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div className="col-md-8 col-12 mt-md-0 mt-3">
            <div className="check-out-Address-box">
              <div className="d-flex justify-content-between align-items-center mb-3">
                <h5>Address Information</h5>
                <div className="Add-box" onClick={(e) => setOpenAdd(true)}>
                  ADD
                </div>
              </div>
              {adderss
                ? adderss?.map((item, i) => {
                    {
                    }
                    return (
                      <div className="mb-3" key={i}>
                        <div className="d-flex ">
                          <div class="form-check">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="flexRadioDefault"
                              id="flexRadioDefault2"
                              onClick={() => setAddressID(item?.id)}
                              style={{ cursor: "pointer" }}
                            />
                          </div>
                          <div className="Addres-check-type">
                            <div className="" style={{ fontWeight: "900" }}>
                              {item?.name}({item?.address_type})
                            </div>
                            <div>{item?.mobile}</div>
                            <div>
                              <span>{item?.street}</span>{" "}
                              <span>{item?.landmark}</span>
                            </div>
                            <div>
                              <span>{item?.city}</span>
                              <span className="mx-2">{item?.state}</span>
                              <span>{item?.code}</span>
                            </div>
                          </div>
                        </div>
                        <div className="d-flex my-3">
                          <div
                            className="Edit-button ms-4"
                            onClick={() => handleEdit(item)}
                          >
                            Edit
                          </div>
                          <div
                            className="Remove-button ms-3"
                            onClick={() => handleRemoveAddress(item?.id)}
                          >
                            Remove
                          </div>
                        </div>
                      </div>
                    );
                  })
                : ""}
              <Addadressmodel
                openAdd={openAdd}
                setOpenAdd={setOpenAdd}
                getUserAuthenticationRedux={getUserAuthenticationRedux}
                FetchAddress={FetchAddress}
              />
              <Editadressmodel
                openEdit={openEdit}
                setOpenEdit={setOpenEdit}
                EditAddress={EditAddress}
                getUserAuthenticationRedux={getUserAuthenticationRedux}
                FetchAddress={FetchAddress}
              />
            </div>

            <div className="payment-box">
              <div className="payment-box-heading">
                <h5 className="mb-0">Payment Method</h5>
                <span className="ms-3 mb-1">
                  <FaRegHandPointRight />
                </span>
              </div>
              <div className="d-flex align-items-center justify-content-center">
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="cod"
                    id="flexRadioDefault3"
                    onChange={(e) =>
                      setPaymentMethod({
                        ...paymentMethod,
                        type: e.target.value,
                      })
                    }
                    checked={paymentMethod.type === "cod" ? true : false}
                    value={"cod"}
                    style={{ cursor: "pointer" }}
                  />
                  <label className="form-check-label" for="flexRadioDefault3">
                    Cash On Delivery
                  </label>
                </div>
                <div className="form-check ms-3">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="stripe"
                    id="flexRadioDefault4"
                    onChange={(e) =>
                      setPaymentMethod({
                        ...paymentMethod,
                        type: e.target.value,
                      })
                    }
                    checked={paymentMethod.type === "stripe" ? true : false}
                    value={"stripe"}
                    style={{ cursor: "pointer" }}
                  />
                  <label className="form-check-label" for="flexRadioDefault4">
                    Stripe
                  </label>
                </div>
              </div>
              <div className="d-flex justify-content-center my-3">
                {paymentMethod.type === "cod" ? (
                  <div
                    className="Check-out-buy-button"
                    onClick={OrderCreateHandle}
                  >
                    Buy
                  </div>
                ) : (
                  ""
                )}

                {paymentMethod.type === "stripe" ? (
                  <div className="Check-out-buy-button">Pay</div>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
          <Ordercheckoutmodel open={open} setOpen={setOpen} orderId={orderId} />
        </div>
      </div>
      <Footer />
    </>
  );
};
const mapStateToProps = (state) => {
  return {
    getUserAuthenticationRedux: state.userAuthentication.data,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setCartlengthRedux: (parameter) => {
      dispatch(setCartlength(parameter));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CheckOutpage);
