import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import {
  AiOutlineMail,
  AiFillEye,
  AiFillEyeInvisible,
  AiOutlineLock,
} from "react-icons/ai";
import { ErrorToaster } from "../Toaster";
import { NavLink, useNavigate } from "react-router-dom";
import "../Style/LoginModel.css";
import { loginModelApi } from "../APIS/AuthApi";
import { connect } from "react-redux";

import { setUserAuthentication } from "../Redux/actions";

const LoginModel = ({
  setShowLoginModal,
  showLoginModal,
  setUserAuthenticationRedux,
}) => {
  const navigation = useNavigate();
  const handleClose = () => setShowLoginModal(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const [emailError, setEmailrror] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const handleLogin = (e) => {
    e.preventDefault();
    if (email === "") {
      setEmailrror("Please Enter The Email");
      ErrorToaster("Please Enter The Email");
    } else if (password === "") {
      setPasswordError("Please Enter The Password");
      ErrorToaster("Please Enter The Password");
    } else {
      let formData = {
        email: email,
        password: password,
        device_token: "d",
        device_id: "d",
      };
      loginModelApi(formData, setUserAuthenticationRedux);
      setShowLoginModal(false);
    }
  };
  return (
    <>
      <Modal
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={showLoginModal}
        onHide={handleClose}
      >
        <Modal.Header closeButton>
          <Modal.Title className="login_title">
            Welcome To TransArabia
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h5 className="login_heading">Please fill your details to login</h5>

          <div className="login_form my-4">
            <InputGroup className="mb-3">
              <InputGroup.Text id="basic-addon1">
                <AiOutlineMail />
              </InputGroup.Text>
              <Form.Control
                style={{
                  borderColor: emailError ? "red" : "",
                  color: emailError ? "red" : "",
                }}
                placeholder="Email"
                //   aria-label="email"
                aria-describedby="basic-addon1"
                name="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </InputGroup>
          </div>

          <div className="login_form my-4">
            <InputGroup className="mb-3">
              <InputGroup.Text
                id="basic-addon1"
                style={{ height: "47px", marginTop: "5px" }}
              >
                <AiOutlineLock />
              </InputGroup.Text>
              <Form.Control
                style={{
                  borderColor: passwordError ? "red" : "",
                  color: passwordError ? "red" : "",
                }}
                placeholder="Password"
                //   aria-label="phone"
                aria-describedby="basic-addon1"
                type={showPassword ? "text" : "password"}
                value={password}
                name="password"
                onChange={(e) => setPassword(e.target.value)}
              />
              <InputGroup.Text
                id="basic-addon1"
                style={{ height: "47px", marginTop: "5px" }}
              >
                {showPassword ? (
                  <AiFillEyeInvisible
                    onClick={() => setShowPassword(!showPassword)}
                  />
                ) : (
                  <AiFillEye onClick={() => setShowPassword(!showPassword)} />
                )}
              </InputGroup.Text>
            </InputGroup>
          </div>
          <NavLink
            to="/signup"
            className="text-decoration-none d-flex justify-content-end"
            style={{ color: "#0d8aaf", pointer: "cursor" }}
          >
            SignUp
          </NavLink>
        </Modal.Body>
        <Modal.Footer>
          <button className="login_button" onClick={handleLogin}>
            Login
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    setUserAuthenticationRedux: (parameter) => {
      dispatch(setUserAuthentication(parameter));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginModel);
