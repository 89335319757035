import React, { useRef, useState, useEffect } from "react";
import "../../Style/profile/profileLeftBar.css";
import { FaClockRotateLeft } from "react-icons/fa6";
import { BiSolidUser } from "react-icons/bi";
import { TbArrowsExchange } from "react-icons/tb";
import { AiFillDelete } from "react-icons/ai";
import { FiLogOut } from "react-icons/fi";
import { CgProfile } from "react-icons/cg";
import { logOutAPI } from "../../APIS/AuthApi";
import { ProfileChange } from "../../APIS/UserdetailsAPI";
import { useNavigate } from "react-router-dom";

const ProfileLeftBar = ({
  setProfile,
  getUserInfoRedux,
  getUserAuthenticationRedux,
}) => {
  const navigation = useNavigate();
  const [profileImage, setProfileImage] = useState("");
  useEffect(() => {
    setProfileImage(getUserInfoRedux?.user?.image);
  }, []);
  const inputRef = useRef(null);
  const handleImage = (e) => {
    const file = e.target.files[0];

    var render = new FileReader();
    render.readAsDataURL(file);
    render.onload = () => {
      let profile = {
        profile: render.result,
      };

      ProfileChange(getUserAuthenticationRedux, profile, (callback) => {
        setProfileImage(callback);
      });
    };
    render.onerror = (error) => {
      console.log("wwwwwww", error);
    };
  };
  const handleUpdateImage = () => {
    inputRef.current.click();
  };

  return (
    <>
      <div className="profile_left_bar">
        <div className="container">
          <div className="main-body">
            <div className="row gutters-sm">
              <div className="card">
                <div className="card-body">
                  <div className="d-flex flex-column align-items-center text-center">
                    <div className="profile_img">
                      <img
                        src={
                          profileImage
                            ? profileImage
                            : "https://bootdey.com/img/Content/avatar/avatar7.png"
                        }
                        alt="Admin"
                        className="rounded-circle"
                      />
                    </div>
                    <div className="mt-3">
                      <h4>
                        {getUserInfoRedux
                          ? getUserInfoRedux?.user?.name_en
                          : ""}
                      </h4>
                      <p className="text-secondary mb-1">
                        {getUserInfoRedux ? getUserInfoRedux?.user_type : ""}
                      </p>
                      <p className="text-muted font-size-sm">
                        {getUserInfoRedux ? getUserInfoRedux?.user?.email : ""}
                      </p>
                      <button
                        className="update_btn"
                        onClick={handleUpdateImage}
                      >
                        Edit
                      </button>
                      <input
                        type="file"
                        accept="image/png,image/jpg,image/jpeg,"
                        ref={inputRef}
                        onChange={handleImage}
                        style={{ display: "none" }}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="card mt-3">
                <ul className="list-group list-group-flush">
                  <li className="list-group-item d-flex justify-content-between align-items-center flex-wrap">
                    <div
                      className="mb-0 d-flex align-items-center gap-3 other_sec"
                      onClick={() => setProfile("profile")}
                    >
                      <CgProfile className="profileicons" />
                      <span> My Profile</span>
                    </div>
                  </li>
                  <li className="list-group-item d-flex justify-content-between align-items-center flex-wrap">
                    <div
                      className="mb-0 d-flex align-items-center gap-3 other_sec"
                      onClick={() => setProfile("myorders")}
                    >
                      <FaClockRotateLeft className="profileicons" />
                      <span> My Order</span>
                    </div>
                  </li>
                  <li className="list-group-item d-flex justify-content-between align-items-center flex-wrap">
                    <div
                      className="mb-0 d-flex align-items-center gap-3 other_sec"
                      onClick={() => setProfile("changePass")}
                    >
                      <TbArrowsExchange className="profileicons" />
                      <span> Change Password</span>
                    </div>
                  </li>
                  <li className="list-group-item d-flex justify-content-between align-items-center flex-wrap">
                    <div
                      className="mb-0 d-flex align-items-center gap-3 other_sec"
                      onClick={() => setProfile("deleteAccount")}
                    >
                      <AiFillDelete className="profileicons" />
                      <span>Delete Account</span>
                    </div>
                  </li>
                  <li className="list-group-item d-flex justify-content-between align-items-center flex-wrap">
                    <div className="mb-0 d-flex align-items-center gap-3 other_sec">
                      <FiLogOut className="profileicons" />
                      <span
                        onClick={() =>
                          logOutAPI(getUserAuthenticationRedux, navigation)
                        }
                      >
                        {" "}
                        Logout
                      </span>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProfileLeftBar;
