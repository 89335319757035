import React, { useState } from "react";
import "../Style/Login.css";
import { connect } from "react-redux";

import { CiMail } from "react-icons/ci";
import { CiLock } from "react-icons/ci";
import { IoEye } from "react-icons/io5";
import { FaEyeSlash } from "react-icons/fa";
import { NavLink } from "react-router-dom";
import logo3 from "../PublicImages/AboutSection/logo3.png";
import { ErrorToaster } from "../Toaster";
import { loginApi } from "../APIS/AuthApi";
import { useNavigate } from "react-router-dom";
import { setUserAuthentication } from "../Redux/actions";

const Login = ({ setUserAuthenticationRedux }) => {
  const [showpassword, setShowpassword] = useState(false);
  const navigation = useNavigate();

  const [input, setInput] = useState({
    email: "",
    password: "",
  });
  const [emailError, setEmailError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);

  let passReg = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
  let emailreg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w\w+)+$/;
  const handleInput = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setInput({ ...input, [name]: value });
  };
  const handlesumbmit = (e) => {
    e.preventDefault();

    if (input.email === "" || emailreg.test(input.email) === "false") {
      ErrorToaster("Please Fill The Email && Valid Email");
      setEmailError("Please Fill The Email");
    } else if (
      input.password === "" ||
      passReg.test(input.password) === "false"
    ) {
      ErrorToaster(
        "Password with min 8 Characters Include atleast 1 Uppercase, 1 Special Character, and Alphanumeric Characters"
      );

      setPasswordError(
        "Password with min 8 Characters Include atleast 1 Uppercase, 1 Special Character, and Alphanumeric Characters"
      );
    } else {
      let formData = {
        email: input.email,
        password: input.password,
        device_token: "d",
        device_id: "454322118432132",
      };
      loginApi(formData, navigation, setUserAuthenticationRedux);
      setEmailError("");
      setPasswordError("");
      setInput({
        email: "",
        password: "",
      });
    }
  };

  return (
    <div className="container-fluied">
      <div className="Row d-md-flex">
        <div className="col-md-6 col-12 loginbox">
          <div className="login-left-side">
            <div className="login-left-side-1">
              <h1>
                Log<span style={{ color: "#0D8AAF" }}>In</span> to Your Account
              </h1>
              <form onSubmit={handlesumbmit}>
                <div className="mb-3 mt-4">
                  <label className="d-flex justify-content-start fw-6">
                    Email Address
                  </label>
                  <div
                    className="left-side-email mt-2"
                    style={{
                      borderColor: emailError ? "red" : "",
                      Color: emailError ? "red" : "",
                    }}
                  >
                    <CiMail />
                    <input
                      className="form-control"
                      type="email"
                      placeholder="Enter Your Email"
                      name="email"
                      value={input.email}
                      onChange={handleInput}
                    />
                  </div>
                  {emailError ? (
                    <p className="text-start mt-2 text-danger">{emailError}</p>
                  ) : (
                    ""
                  )}
                </div>
                <div className="mb-3">
                  <label className="d-flex justify-content-start fw-6">
                    Password
                  </label>
                  <div
                    className="left-side-password mt-2"
                    style={{
                      borderColor: passwordError ? "red" : "",
                      Color: passwordError ? "red" : "",
                    }}
                  >
                    <CiLock />
                    <div
                      className="leftpassword d-flex"
                      style={{ position: "relative" }}
                    >
                      <input
                        className="form-control"
                        type={showpassword ? "text" : "password"}
                        placeholder="Enter Your password"
                        name="password"
                        value={input.password}
                        onChange={handleInput}
                      />
                      {showpassword ? (
                        <IoEye onClick={() => setShowpassword(!showpassword)} />
                      ) : (
                        <FaEyeSlash
                          onClick={() => setShowpassword(!showpassword)}
                        />
                      )}
                    </div>
                  </div>
                  {passwordError ? (
                    <p className="text-start mt-2 text-danger">
                      {passwordError}
                    </p>
                  ) : (
                    ""
                  )}
                </div>
                <div className="d-sm-flex justify-content-between">
                  <div className="form-check text-start">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value=""
                      id="flexCheckChecked"
                      //   style={{backgroundColor:"#0D8AAF"}}
                    />
                    <label class="form-check-label" for="flexCheckChecked">
                      Remember me
                    </label>
                  </div>
                  <div className="text-start me-md-0 ms-4">
                    <NavLink
                      to="/forget"
                      style={{ color: "#0D8AAF", textDecoration: "none" }}
                    >
                      Forget Password ?
                    </NavLink>
                  </div>
                </div>
                <div className="mt-5">
                  <button
                    className="btn btn-success w-100"
                    type="submit"
                    style={{
                      borderRadius: "20px",
                      backgroundColor: "#0D8AAF",
                      borderColor: "#0D8AAF",
                    }}
                  >
                    Login
                  </button>
                </div>
              </form>
              <div className="d-flex justify-content-center mt-3">
                <p>Don't have an account?</p>
                <NavLink
                  to="/signup"
                  style={{ color: "#0D8AAF", textDecoration: "none" }}
                >
                  &nbsp;&nbsp;Signup
                </NavLink>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-6 col-12  d-md-flex d-none ">
          <div className="login-rigth-side">
            <div className="login-right-logo">
              <img src={logo3} alt="logo" onClick={() => navigation("/")} />
            </div>
            <div className="login-right-box">
              <h1> Welcome back !</h1>
              <p>
                We are glad to see you again! Get access to your Orders,
                Wishlist and Recommendations.
              </p>
            </div>
          </div>
        </div>
        {/* </div> */}
      </div>
    </div>
  );
};
const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    setUserAuthenticationRedux: (parameter) => {
      dispatch(setUserAuthentication(parameter));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
