import Footer from "Sections/HomeSection/Footer";
import NavBar from "Sections/HomeSection/NavBar";
import React, { useEffect, useState } from "react";
import "../Style/Verfication.css";
import { NavLink, useParams } from "react-router-dom";
import { HiCheckBadge } from "react-icons/hi2";
import { LuShieldClose } from "react-icons/lu";
import { verficationAPI } from "../APIS/api";

const Verfication = () => {
  const params = useParams();

  const token = params.slug;

  const [verify, setVerify] = useState("");
  useEffect(() => {
    verficationAPI(token, (callback) => {
      setVerify(callback?.data);
    });
  }, []);

  return (
    <>
      <NavBar />
      <div className="container">
        {verify.status === "success" ? (
          <div className="verfication">
            <div className="row">
              <div className="Verfication-box">
                <div className="">
                  <div className="verfication-icons">
                    {" "}
                    <HiCheckBadge />
                  </div>
                  <div className="verification-successfuly">
                    Verfication Successfully
                  </div>
                  <div className="verfication-link">
                    <NavLink to="/login">Login</NavLink>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="verfication">
            <div className="row">
              <div className="Verfication-box">
                <div className="">
                  <div className="verfication-icons-Unsuccefully">
                    {" "}
                    <LuShieldClose />
                  </div>
                  <div className="verification-successfuly">
                    Verfication Unsuccessfully
                  </div>
                  <div className="verfication-link">
                    <NavLink to="/">Go To Home</NavLink>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      <Footer />
    </>
  );
};

export default Verfication;
