import React, { useState } from "react";
import "../../Style/profile/delete.css";
import { DeleteAccountAPI } from "../../APIS/AuthApi";

const DeleteAccount = ({ getUserAuthenticationRedux }) => {
  const [password, setPassword] = useState("");
  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(password);
    let fromdata = {
      password: password,
    };
    DeleteAccountAPI(getUserAuthenticationRedux, fromdata);
  };

  return (
    <>
      <div className="DeleteAccount ">
        <h3>Delete Account</h3>
        <div className="row">
          <div className="col-md-10">
            <hr />
            <p>
              Deleting your account will remove all of your information form our
              dtabase. This cannot br undone.
            </p>
            <span className="opacity-75">To confirm this type "password"</span>
            <form onSubmit={handleSubmit}>
              <div className="row form d-flex align-items-center mt-3">
                <div className="col-md-8">
                  <input
                    type="password"
                    placeholder="Enter your Password"
                    name="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </div>
                <div className="col-md-4 mt-md-none mt-2">
                  <button type="submit" className="bg-danger delete_btn">
                    Delete Account
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default DeleteAccount;
