import React from "react";
import "Style/Home/SolarproductCard.css";
import { Button } from "react-bootstrap";
import { IoCartOutline } from "react-icons/io5";
import { FaRegHeart } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { IoMdHeart } from "react-icons/io";
import ReactStars from "react-stars";

const SolarproductCard = ({
  categoriesData,
  handlewishlist,
  Removewishlist,
  categroyname,
}) => {
  const navigation = useNavigate();

  return (
    <>
      <div className="SolarproductCard" style={{ minHeight: "450px" }}>
        <div className="row">
          {categoriesData
            ? categoriesData.map((item, i) => {
                const {
                  averageRating,
                  categoryId,
                  categoryName,
                  categorySlug,
                  image,
                  id,
                  name,
                  slug,
                  wishlist,
                  description,
                  base_price,
                  sale_price,
                  offerDiscount,
                } = item;

                return (
                  <div className="col-lg-3 col-md-4 mt-4" key={i}>
                    <div className="card">
                      <img
                        src={image}
                        alt="img"
                        onClick={() =>
                          navigation(`/ceiling/${id}/${categorySlug}`, {
                            state: categroyname,
                          })
                        }
                        style={{ cursor: "pointer" }}
                      />
                      <div className="content ">
                        <div className="d-flex justify-content-between align-items-center">
                          <h6
                            onClick={() =>
                              navigation(`/ceiling/${id}/${categorySlug}`, {
                                state: categroyname,
                              })
                            }
                            style={{ cursor: "pointer" }}
                          >
                            {name?.slice(0, 40)}
                          </h6>
                          {wishlist === "no" ? (
                            <FaRegHeart
                              onClick={() => handlewishlist(id)}
                              style={{ cursor: "pointer" }}
                            />
                          ) : (
                            <IoMdHeart
                              onClick={() => Removewishlist(id)}
                              style={{ cursor: "pointer", color: "red" }}
                            />
                          )}
                        </div>
                        <p className="m-0 text-start">
                          {description?.slice(0, 40)}...
                        </p>
                        <div className="">
                          <p className="d-flex align-items-center gap-2">
                            ${sale_price}
                            <span className=" text-decoration-line-through opacity-50">
                              {" "}
                              ${base_price}
                            </span>{" "}
                          </p>
                        </div>
                        <div className="review mt-2 d-flex align-items-center justify-content-between">
                          <div className="d-flex align-items-center gap-1 icons">
                            <ReactStars
                              count={5}
                              value={averageRating}
                              size={16}
                              color1={"gray"}
                              half={true}
                            />
                            <span className="text-dark opacity-50">
                              (reviews)
                            </span>
                          </div>
                          <Button
                            className="AddToCart_btn d-flex align-items-center gap-1"
                            onClick={() =>
                              navigation(`/ceiling/${id}/${categorySlug}`, {
                                state: categroyname,
                              })
                            }
                          >
                            <IoCartOutline /> Add to Cart
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })
            : ""}
        </div>
      </div>
    </>
  );
};

export default SolarproductCard;
