import axios from "axios";
import { baseUrl } from "../baseUrl";
import { SuccessToaster, ErrorToaster } from "../Toaster";

export const UploadImageReviewApi = (token, formData, callback) => {
  let config = {
    method: "post",
    maxBodyLength: Infinity,
    url: `${baseUrl}image-uploads`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
      "Content-Type ": "multipart/form-data",
    },
    data: formData,
  };
  axios
    .request(config)
    .then((response) => {
      callback(response);
    })
    .catch((error) => {
      console.log(error);
      let err = error?.response?.data?.message;
      if (err.length > 0) {
        console.log(error?.response?.data?.message);
        ErrorToaster(error?.response?.data?.message);
      } else {
      }
      console.log(error, "error");
    });
};
export const AddReviewAPI = (token, fromdata) => {
  let data = JSON.stringify(fromdata);
  console.log(data, "234567890");
  let config = {
    method: "post",
    maxBodyLength: Infinity,
    url: `${baseUrl}add-review`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    data: data,
  };
  axios
    .request(config)
    .then((response) => {
      SuccessToaster(response?.data?.message);
    })
    .catch((error) => {
      console.log(error, "error");
      ErrorToaster(error?.response?.data?.message);
    });
};

export const FetchReviewAPI = (fromdata, callback) => {
  let data = JSON.stringify(fromdata);

  const config = {
    method: "post",
    maxBodyLength: Infinity,
    url: `${baseUrl}product-reviews`,
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };
  axios
    .request(config)
    .then((response) => {
      callback(response?.data);
    })
    .catch((error) => {
      console.log(error);
      let err = error?.response?.data?.message;

      if (err.length > 0) {
        console.log(error?.response?.data?.message);
      } else {
      }
      console.log(error, "error");
    });
};
export const FetchEditReviewAPI = (token, fromdata, callback) => {
  let data = JSON.stringify(fromdata);

  const config = {
    method: "post",
    maxBodyLength: Infinity,
    url: `${baseUrl}edit-review`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    data: data,
  };
  axios
    .request(config)
    .then((response) => {
      callback(response?.data);
    })
    .catch((error) => {
      console.log(error);
      let err = error?.response?.data?.message;

      if (err.length > 0) {
        console.log(error?.response?.data?.message);
      } else {
      }
      console.log(error, "error");
    });
};
export const UpdateEditReviewAPi = (token, fromdata, setOpen) => {
  let data = JSON.stringify(fromdata);

  const config = {
    method: "post",
    maxBodyLength: Infinity,
    url: `${baseUrl}update-review`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    data: data,
  };
  axios
    .request(config)
    .then((response) => {
      SuccessToaster(response?.data?.message);
      setOpen(false);
    })
    .catch((error) => {
      console.log(error);
      let err = error?.response?.data?.message;

      if (err.length > 0) {
        console.log(error?.response?.data?.message);
      } else {
      }
      console.log(error, "error");
    });
};
export const DeleteReviewAPi = (token, fromdata) => {
  let data = JSON.stringify(fromdata);

  const config = {
    method: "post",
    maxBodyLength: Infinity,
    url: `${baseUrl}delete-review`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    data: data,
  };
  axios
    .request(config)
    .then((response) => {
      SuccessToaster(response?.data?.message);
    })
    .catch((error) => {
      console.log(error);
      let err = error?.response?.data?.message;

      if (err.length > 0) {
        console.log(error?.response?.data?.message);
      } else {
      }
      console.log(error, "error");
    });
};
