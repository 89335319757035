import React, { useState } from "react";
import { Link } from "react-router-dom";
import "Style/Home/ContactUs.css";
import { MdOutlineLocationOn } from "react-icons/md";
import { IoMdCall } from "react-icons/io";
import { HiMail } from "react-icons/hi";
import { BiLogoFacebook } from "react-icons/bi";
import { AiOutlineInstagram } from "react-icons/ai";
import { AiOutlineTwitter } from "react-icons/ai";
import NavBar from "Sections/HomeSection/NavBar";
import Footer from "Sections/HomeSection/Footer";
import { connect } from "react-redux";
import { ContactUsAPI } from "../../APIS/api";
import { ErrorToaster } from "../../Toaster";
import CategoriesDropdeon from "Sections/HomeSection/CategoriesDropdeon";
const ContactUs = ({ getUserAuthenticationRedux }) => {
  const [input, setInput] = useState({
    name: "",
    email: "",
    phone: "",
    subject: "",
    textArea: "",
  });
  const handleInput = (e) => {
    const { name, value } = e.target;
    setInput({ ...input, [name]: value });
  };
  const handlesubmit = (e) => {
    e.preventDefault();

    if (input.name === "") {
      ErrorToaster("Name field is Requerid");
    } else if (input.email === "") {
      ErrorToaster("Email field is Requerid");
    } else if (
      input.phone === "" ||
      input?.phone?.length >= "11" ||
      input?.phone?.length <= "9"
    ) {
      ErrorToaster("Phone  field is Requerid & min 9 & max 11 number");
    } else if (input.subject === "") {
      ErrorToaster("Subject field is Requerid");
    } else if (input.textArea === "") {
      ErrorToaster("TextArea field is Requerid ");
    } else {
      let fromdata = {
        name: input.name,
        email: input.email,
        message: input.textArea,
        subject: input.subject,
        phone: input.phone,
      };

      ContactUsAPI(fromdata);

      setInput({
        name: "",
        email: "",
        phone: "",
        subject: "",
        textArea: "",
      });
    }
  };

  return (
    <>
      <NavBar />
      <CategoriesDropdeon />
      <div className="ContactUs">
        <div className="Header_banner d-flex justify-content-center align-items-center">
          <div className="header_content d-flex justify-content-center align-items-center flex-column">
            <h1> Contact Us </h1>
            <p className="text-center ">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam
              gravida odio id lacus tincidunt, at bibendum ipsum porta.
              Phasellus at posuere erat, vitae ullamcorper odio.
            </p>
            <p></p>
          </div>
        </div>
        {/* Contact Form ------------------------------------------- */}
        <section className="contact-sec sec-pad">
          <div className="row">
            <div className="col-md-6">
              <div className="contact-detail">
                <h1 className="section-title">Contact us</h1>
                <ul className="contact-ul">
                  <li className="d-flex align-items-center flex-wrap">
                    <MdOutlineLocationOn className="location" />
                    <p className="m-0">CyberXinfosystem Noida Sec 16</p>
                  </li>

                  <li className="d-flex align-items-center flex-wrap">
                    <IoMdCall className="called" />
                    <Link to="tel:08510004495">
                      <p className="m-0">0255000XXXX</p>
                    </Link>
                  </li>

                  <li className="d-flex align-items-center flex-wrap">
                    <HiMail className="mail" />
                    <Link to="mailto:pardeepkumar4bjp@gmail.com">
                      <p className="m-0"> demounknown@gmail.com</p>
                    </Link>
                  </li>
                </ul>
                <span>
                  <Link
                    to="https://www.facebook.com/"
                    target="_blank"
                    className="fb"
                  >
                    <BiLogoFacebook />
                  </Link>
                  <Link
                    to="https://www.instagram.com/"
                    target="_blank"
                    className="insta"
                  >
                    <AiOutlineInstagram />
                  </Link>
                  <Link
                    to="https://twitter.com/"
                    target="_blank"
                    className="twitter"
                  >
                    <AiOutlineTwitter />
                  </Link>
                </span>
              </div>
            </div>

            <div className="col-md-6">
              <form className="contFrm" onSubmit={handlesubmit}>
                <div className="row">
                  <div className="col-sm-6">
                    <input
                      type="text"
                      name="name"
                      placeholder="Your Name"
                      className="inptFld"
                      value={input.name}
                      onChange={handleInput}
                    />
                  </div>

                  <div className="col-sm-6">
                    <input
                      type="email"
                      name="email"
                      placeholder="Email Address"
                      className="inptFld"
                      onChange={handleInput}
                      value={input.email}
                    />
                  </div>

                  <div className="col-sm-6">
                    <input
                      type="number"
                      name="phone"
                      placeholder="Phone Number"
                      className="inptFld"
                      onChange={handleInput}
                      value={input.phone}
                    />
                  </div>

                  <div className="col-sm-6">
                    <input
                      type="text"
                      name="subject"
                      placeholder="Subject"
                      className="inptFld"
                      onChange={handleInput}
                      value={input.subject}
                    />
                  </div>

                  <div className="col-12">
                    <textarea
                      className="inptFld"
                      rows=""
                      cols=""
                      placeholder="Your Message..."
                      name="textArea"
                      onChange={handleInput}
                      value={input.textArea}
                    ></textarea>
                  </div>

                  <div className="col-12">
                    <input
                      type="submit"
                      name="submit"
                      value="SUBMIT"
                      className="inptBtn"
                    />
                  </div>
                </div>
              </form>
            </div>
          </div>
        </section>
      </div>
      <Footer />
    </>
  );
};
const mapStateToProps = (state) => {
  return {
    getUserAuthenticationRedux: state.userAuthentication.data,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(ContactUs);
