import React, { useEffect, useState } from "react";
import { IoMdHome } from "react-icons/io";
import { FaAngleRight } from "react-icons/fa";
import NavBar from "Sections/HomeSection/NavBar";
import Footer from "Sections/HomeSection/Footer";
import CeilingFansProduct from "Sections/CeilingFans/CeilingFansProduct";
import ImageSlider from "Sections/CategoriesSection/ImageSlider";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { FetchSingleproduat } from "../APIS/Singleproduceat";
import CategoriesDropdeon from "Sections/HomeSection/CategoriesDropdeon";
import ReviewSection from "Sections/CategoriesSection/ReviewSection";

const CeilingFans = () => {
  const [producetdata, setProducetdata] = useState("");
  const [mainImage, setMainImage] = useState("");
  const [sliderImage, setSliderImage] = useState([]);
  const [suggestedProduct, setSuggestedProduct] = useState([]);
  const navigation = useNavigate();
  const params = useParams();
  const productId = params.id;

  const CategoriesNamelocation = useLocation();

  useEffect(() => {
    Fetchsingleproducet();
  }, [productId]);
  const Fetchsingleproducet = () => {
    let formData = {
      productId: productId,
      language: "en",
    };
    FetchSingleproduat(formData, (callback) => {
      setProducetdata(callback.product);
      setMainImage(callback?.product[0]?.featured_image);
      setSliderImage(callback?.product[0]?.imagesGet);
      setSuggestedProduct(callback?.product[0]?.suggestedProduct);
    });
  };

  return (
    <>
      <NavBar />
      <CategoriesDropdeon />
      <hr className="m-0" />
      <div className="d-flex align-items-center globle_categories_routing">
        <IoMdHome
          className="globle_Home_icon"
          onClick={() => navigation("/")}
          style={{ cursor: "pointer" }}
        />
        <FaAngleRight className="globle_right_arrow" />
        <span>Categories</span>
        {CategoriesNamelocation?.state !== "All Categories" ? (
          <>
            <FaAngleRight className="globle_right_arrow" />
            <span>{CategoriesNamelocation?.state}</span>
          </>
        ) : (
          ""
        )}

        <FaAngleRight className="globle_right_arrow" />
        <span className="opacity-75">
          {producetdata ? producetdata[0]?.name.slice(0, 30) : ""}...
        </span>
      </div>
      <ImageSlider
        producetdata={producetdata}
        mainImage={mainImage}
        sliderImage={sliderImage}
        setMainImage={setMainImage}
        setSliderImage={setSliderImage}
      />
      <ReviewSection productId={productId} />
      <CeilingFansProduct
        suggestedProduct={suggestedProduct}
        CategoriesNamelocation={CategoriesNamelocation}
      />
      <Footer />
    </>
  );
};

export default CeilingFans;
