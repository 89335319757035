import React, { useEffect, useState } from "react";
import CategoriesDropdeon from "Sections/HomeSection/CategoriesDropdeon";
import Footer from "Sections/HomeSection/Footer";
import NavBar from "Sections/HomeSection/NavBar";
import "../../Style/Blogsection.css";
import { FetchBloglistAPI } from "../../APIS/BlogAPi";
import { useNavigate } from "react-router-dom";
import { NavLink } from "react-router-dom";
import Loader from "Loader";

const Blogsection = () => {
  const [bloglist, setbloglist] = useState([]);
  const [currentPage, setCurrentPage] = useState("1");
  const [bloglistlength, setBloglistlength] = useState("");
  const [loading, setLoading] = useState(false);

  const navigation = useNavigate();
  useEffect(() => {
    bloglistItem();
  }, [currentPage]);
  const bloglistItem = () => {
    let fromdata = {
      page: currentPage,
      language: "en",
    };
    FetchBloglistAPI(fromdata, setLoading, (callback) => {
      setBloglistlength(callback?.total_records);
      setbloglist(callback?.data?.blog_list);
    });
  };
  const recordsPerPage = 10;
  const lastIndex = currentPage * recordsPerPage;
  const firstIndex = lastIndex - recordsPerPage;

  const npage = Math.ceil(bloglistlength / recordsPerPage);
  const numbers = [...Array(npage + 1).keys()].slice(1);
  const perPage = () => {
    if (currentPage !== 1) {
      setCurrentPage(currentPage - 1);
    }
  };
  const changeCpage = (n) => {
    setCurrentPage(n);
  };
  const nextPage = () => {
    if (currentPage !== npage) {
      setCurrentPage(currentPage + 1);
    }
  };

  return (
    <>
      <NavBar />
      <CategoriesDropdeon />
      <div className="">
        <div className="blogUS_header d-flex align-items-center justify-content-center text-white">
          <div className="blogUs_text d-flex align-items-center justify-content-center flex-column">
            <h1>Blog</h1>
            <p className="text-center">
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the industry's standard dummy text
              ever since the 1500s,
            </p>
          </div>
        </div>
      </div>
      {loading ? (
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ minHeight: "500px" }}
        >
          <Loader />
        </div>
      ) : (
        <div className="container">
          <div className="row mt-4">
            {bloglist
              ? bloglist?.map((iteam, i) => {
                  const { id, title, slug, description, image, date } = iteam;
                  return (
                    <div className="col-lg-4 col-md-6 col-12 my-2" key={i}>
                      <div className="blog-section">
                        <div className="blog-img">
                          <img
                            src={image ? image : ""}
                            alt="blog"
                            onClick={() => {
                              navigation(`/blog/${slug}`, { state: id });
                              window.scrollTo(0, 0);
                            }}
                          />
                        </div>
                        <div className="bolg-content">
                          <p className="blog-title">{title.slice(0, 40)}...</p>
                          <p className="mb-0">{description.slice(0, 50)}...</p>
                          <p className=" d-flex justify-content-between align-iteams-center mt-1">
                            <span>Date:{date}</span>
                            <span
                              className="read-more-blog"
                              onClick={() => {
                                navigation(`/blog/${slug}`, { state: id });
                                window.scrollTo(0, 0);
                              }}
                            >
                              Read More
                            </span>
                          </p>
                        </div>
                      </div>
                    </div>
                  );
                })
              : ""}
            <nav>
              <ul className="pagination d-flex justify-content-end me-4 my-3 pagination-iteam">
                <li className="page-item">
                  <NavLink
                    to=""
                    className={`page-link${currentPage === 1 ? "back" : ""}`}
                    onClick={perPage}
                  >
                    {currentPage === 1 ? "" : <>PrevPage</>}{" "}
                  </NavLink>
                </li>
                {numbers.map((n, i) => (
                  <li
                    className={`page-item ${currentPage === n ? "active" : ""}`}
                    key={i}
                  >
                    <NavLink
                      to=""
                      className="page-link"
                      onClick={() => changeCpage(n)}
                    >
                      {n}
                    </NavLink>
                  </li>
                ))}
                <li className="page-item">
                  <NavLink
                    to=""
                    className={`page-link${
                      currentPage === numbers.length ? "next" : ""
                    }`}
                    onClick={nextPage}
                  >
                    {currentPage === numbers.length ? "" : <>NextPage</>}
                  </NavLink>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      )}

      <Footer />
    </>
  );
};

export default Blogsection;
