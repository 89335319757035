import React from "react";
import { Button } from "react-bootstrap";
import { FaAngleRight } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";
import "Style/Home/BussinessCardSection.css";

const BussinessCardSection = () => {
  const navigation = useNavigate();
  return (
    <>
      <div className="BussinessCardSection">
        <div className="row">
          <div className="col-md-6">
            <div className="BusinessCardWrapper" id="B2B">
              <h4>B2B </h4>
              <p>
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text ever since the 1500s, when an unknown printer took a galley
                of type and scrambled it to make a type specimen book.
              </p>
              <Button
                className=" Explore_btn d-flex align-items-center"
                onClick={() => {
                  navigation("/b-2-b");
                  window.scrollTo(0, 0);
                }}
              >
                Explore Now <FaAngleRight />
              </Button>
            </div>
          </div>
          <div className="col-md-6 mt-md-0 mt-3">
            <div className="BusinessCardWrapper">
              <h4>B2C </h4>
              <p>
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text ever since the 1500s, when an unknown printer took a galley
                of type and scrambled it to make a type specimen book.
              </p>
              <Button
                className="Explore_btn d-flex align-items-center"
                onClick={() => {
                  navigation("/b-2-c");
                  window.scrollTo(0, 0);
                }}
              >
                Explore Now <FaAngleRight />
              </Button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BussinessCardSection;
