import axios from "axios";
import { baseUrl } from "../baseUrl";

export const FetchSingleproduat = (fromData, callback) => {
  let data = JSON.stringify(fromData);

  let config = {
    method: "post",
    maxBodyLength: Infinity,
    url: `${baseUrl}single-product`,
    headers: {
      Content_Type: "application/json",
    },
    data: data,
  };
  axios
    .request(config)
    .then((response) => {
      callback(response?.data?.data);
    })
    .catch((error) => {
      let err = error?.response?.data?.message;
      if (err.length > 0) {
        console.log(error?.response?.data?.message);
      } else {
      }
    });
};
