import React, { useState, useEffect } from "react";
import { GoogleMap, useJsApiLoader, MarkerF } from "@react-google-maps/api";
const center = {
  lat: -3.745,
  lng: -38.523,
};
const containerStyle = {
  width: "100%",
  height: "100%",
};

const Location = () => {
  const [position, setPosition] = useState({
    lat: 28.5786164,
    lng: 77.3154057,
  });
  useEffect(() => {
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(function (position) {
        const { latitude, longitude } = position.coords;
        setPosition({
          lat: latitude,
          lng: longitude,
        });
      });
    } else {
      console.log("Geolocation is not available in your browser.");
    }
  }, []);

  useEffect(() => {
    const reverseGeocodingUrl = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${position.lat},${position.lng}&key=AIzaSyA5Uz0XCnjjnou0uhhJBEeoZte-18ATSKU`;

    fetch(reverseGeocodingUrl)
      .then((response) => response.json())
      .then((result) => {
        if (result) {
          // console.log(result,'[][][]');
          // setMyAddress(result?.results[0]?.formatted_address)
        } else {
          console.log("No address found");
        }
      });
  }, []);

  const { isLoaded, loadError } = useJsApiLoader({
    googleMapsApiKey: "AIzaSyA5Uz0XCnjjnou0uhhJBEeoZte-18ATSKU",
  });
  const mapRef = React.useRef();

  const onMapLoad = React.useCallback((map) => {
    // console.log(map, "6666666666");
    mapRef.current = map;
  }, []);
  if (loadError) return "Error";
  if (!isLoaded) return "Maps";
  // console.log(position, "99999999");

  // console.log(mapRef.current,"00000000")
  return (
    <>
      {/* <div>
    {position.latitude && position.longitude ? (
        <p>
          Latitude: {position.latitude}, Longitude: {position.longitude}
        </p>
      ) : (
        <p>Loading...</p>
      )}
      </div>  */}
      <GoogleMap center={position} zoom={10} onLoad={onMapLoad}>
        <MarkerF position={position} />
      </GoogleMap>
    </>
  );
};

export default Location;
