import React from "react";
import NavBar from "Sections/HomeSection/NavBar";
import Footer from "Sections/HomeSection/Footer";
import { IoMdHome } from "react-icons/io";
import { FaAngleRight } from "react-icons/fa";
import CategoriesProduct from "Sections/CategoriesSection/CategoriesProduct";
import { useParams } from "react-router-dom";
import CategoriesDropdeon from "Sections/HomeSection/CategoriesDropdeon";

const Categories = () => {
  return (
    <>
      <div>
        <NavBar />
        <CategoriesDropdeon />
        <hr className="m-0" />
        <div className="d-flex align-items-center globle_categories_routing">
          <IoMdHome className="globle_Home_icon" />
          <FaAngleRight className="globle_right_arrow" />
          <span>Categories</span>
        </div>
        <CategoriesProduct />
        <Footer />
      </div>
    </>
  );
};

export default Categories;
