import React, { useEffect, useState } from "react";
import NavBar from "Sections/HomeSection/NavBar";
import Footer from "Sections/HomeSection/Footer";
import "Style/Home/Faq.css";
import CategoriesDropdeon from "Sections/HomeSection/CategoriesDropdeon";
import { FAQAPI } from "../../APIS/FAQAPI";

const Faq = () => {
  const [Faqdata, setFaqdata] = useState([]);
  useEffect(() => {
    Faqvaraitondata();
  }, []);
  const Faqvaraitondata = () => {
    let fromdata = {
      page: 1,
      language: "en",
    };
    FAQAPI(fromdata, (callback) => {
      setFaqdata(callback?.data?.faq_list);
    });
  };

  return (
    <>
      <NavBar />
      <CategoriesDropdeon />

      <div className="transarabia_faq">
        <div className="transarabia_faq_banner  d-flex align-items-center">
          <div className="faq_banner_text">
            <h1>FAQs</h1>
            <p>How can we help you ?</p>
          </div>
        </div>

        <div className="Faq_dropDowns">
          <div className="row">
            <div className="text-center py-3">
              <h2>Frequently Asking Question’s </h2>
            </div>
          </div>
          <div class="row">
            {Faqdata
              ? Faqdata?.map((iteam, i) => {
                  const { question, id, answer } = iteam;
                  const panelId = `collapse-${id}`;
                  return (
                    <div className="col-md-6">
                      <div className="pxlr-club-faq">
                        <div className="content">
                          <div
                            className="panel-group"
                            id="accordion"
                            role="tablist"
                            aria-multiselectable="true"
                          >
                            <div className="panel panel-default">
                              <div className="panel-heading" id={id} role="tab">
                                <h4 className="panel-title">
                                  <a
                                    className="collapsed"
                                    role="button"
                                    data-toggle="collapse"
                                    data-parent="#accordion"
                                    href={`#${panelId}`}
                                    aria-expanded="false"
                                    aria-controls={panelId}
                                  >
                                    {question}{" "}
                                    <i className="pull-right fa fa-plus"></i>
                                  </a>
                                </h4>
                              </div>
                              <div
                                className="panel-collapse collapse"
                                id={panelId}
                                role="tabpanel"
                                aria-labelledby={panelId}
                              >
                                <div className="panel-body pxlr-faq-body">
                                  <p>{answer}</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })
              : ""}
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Faq;
