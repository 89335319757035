import React from "react";
import "Style/Home/Warraintee_Cards_Section.css";
import Warraintee_Cards_Section_one from "PublicImages/Warraintee_Cards_Section/Warraintee_Cards_Section_one.png";
import Warraintee_Cards_Section_two from "PublicImages/Warraintee_Cards_Section/Warraintee_Cards_Section_two.png";
import Warraintee_Cards_Section_three from "PublicImages/Warraintee_Cards_Section/Warraintee_Cards_Section_three.png";
const WarrainteeCardsSection = () => {
  return (
    <>
      <div className="Warraintee_Cards_Section">
        <div className="row">
          <div className="col-md-4">
            <div className="Warraintee_Cards d-flex  align-items-center">
              <div className="d-flex justify-content-between align-items-start gap-3">
                <img
                  src={Warraintee_Cards_Section_one}
                  alt="img"
                  className="images"
                />
                <div className="Warraintee_Cards_content">
                  <h6 className="m-0 fw-bold">Warranty</h6>
                  <p className="m-0 opacity-75">
                    Lorem Ipsum is simply dummy text of the printing & type
                    there setting industry. Lorem Ipsum has been typesetting
                    many industry.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="Warraintee_Cards d-flex  align-items-center">
              <div className="d-flex justify-content-between align-items-start gap-3">
                <img
                  src={Warraintee_Cards_Section_two}
                  alt="img"
                  className="images"
                />
                <div className="Warraintee_Cards_content">
                  <h6 className="m-0 fw-bold">
                    After Sales Service & Maintenance Support
                  </h6>
                  <p className="m-0 opacity-75">
                    Lorem Ipsum is simply dummy text of the printing & type
                    there setting industry. Lorem Ipsum has been typesetting
                    many industry.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="Warraintee_Cards d-flex  align-items-center">
              <div className="d-flex justify-content-between align-items-start gap-3">
                <img
                  src={Warraintee_Cards_Section_three}
                  alt="img"
                  className="images"
                />
                <div className="Warraintee_Cards_content">
                  <h6 className="m-0 fw-bold">Easy Return Policy</h6>
                  <p className="m-0 opacity-75">
                    Lorem Ipsum is simply dummy text of the printing & type
                    there setting industry. Lorem Ipsum has been typesetting
                    many industry.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default WarrainteeCardsSection;
