import React, { useRef } from "react";
import "Style/Home/Header.css";
import Slider from "react-slick";
import banner_img_one from "PublicImages/HeaderImg/banner_img_one.jpg";
import banner_img_two from "PublicImages/HeaderImg/banner_img_two.jpg";
import banner_img_three from "PublicImages/HeaderImg/banner_img_three.jpg";


const Header = () => {
  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,

  };
  return (
    <>
      <div className="header_banner">
        <div className="slider-container">
          <Slider {...settings}>
            <div className="slider_banner">
              <img src={banner_img_one} alt="img" />
              <div className="banner_content">
                <h1>
                  Bring Home Appliances <span>at unbelivable Prices !</span>
                </h1>
                <p>
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry's
                  standard dummy text ever since the 1500s, when an unknown
                  printer took a galley of type and scrambled.
                </p>
              </div>
            </div>
            <div className="slider_banner">
              <img src={banner_img_two} alt="img" />
              <div className="banner_content">
                <h1>
                  Bring Home Appliances <span>at unbelivable Prices !</span>
                </h1>
                <p>
                Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry's
                  standard dummy text ever since the 1500s, when an unknown
                  printer took a galley of type and scrambled.
                </p>
              </div>
            </div>
            <div className="slider_banner">
              <img src={banner_img_three} alt="img" />
              <div className="banner_content">
                <h1>
                  Bring Home Appliances <span>at unbelivable Prices !</span>
                </h1>
                <p>
                Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry's
                  standard dummy text ever since the 1500s, when an unknown
                  printer took a galley of type and scrambled.
                </p>
              </div>
            </div>
          </Slider>
        </div>
      </div>
    </>
  );
};

export default Header;
