import React, { useEffect, useState } from "react";
import "../../Style/profile/profileRightBar.css";
import { UpdateInfo } from "../../APIS/UserdetailsAPI";
import { Fetchuserdetails } from "../../APIS/UserdetailsAPI";
import { connect } from "react-redux";
import { setUserDetails } from "../../Redux/actions";
import { ErrorToaster } from "Toaster";

const ProfileRightBar = ({
  getUserInfoRedux,
  getUserAuthenticationRedux,
  setUserInfoRedux,
}) => {
  const [Edit, setEdit] = useState("");
  const [dummydata, setDummaydata] = useState("");
  useEffect(() => {
    setEdit(getUserInfoRedux?.user);
    setDummaydata(getUserInfoRedux?.user);
  }, [getUserInfoRedux]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (
      dummydata.phone === "" ||
      dummydata.name_en === "" ||
      dummydata.phone.length <= "9" ||
      dummydata.phone.length >= "12"
    ) {
      ErrorToaster("Mobile Number 9 to 11 && name filed is requierd");
    } else {
      let formdata = {
        language: "en",
        name: dummydata.name_en,
        phone: dummydata.phone,
      };
      // console.log(formdata)
      UpdateInfo(
        getUserAuthenticationRedux,
        formdata,
        Fetchuserdetails,
        (callback) => {
          setUserInfoRedux(callback);
        }
      );
    }
  };
  return (
    <>
      <div className="profile_right_bar ">
        <div className="col-lg-10 col-lg-offset-2 col-md-8 col-md-offset-2 col-sm-12 col-xs-12 edit_information">
          <form onSubmit={handleSubmit}>
            <h3 className="">Edit Personal Information</h3>
            <div className="row">
              <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <div className="form-group">
                  <label className="profile_details_text py-2">
                    First Name:
                  </label>
                  <input
                    type="text"
                    name="first_name"
                    className="form-control"
                    value={dummydata?.name_en}
                    onChange={(e) =>
                      setDummaydata({
                        ...dummydata,
                        name_en: e.target.value,
                      })
                    }
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <div className="form-group">
                  <label className="profile_details_text py-2">
                    Email Address:
                  </label>
                  <input
                    type="email"
                    name="email"
                    className="form-control"
                    value={Edit?.email}
                    required
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <div className="form-group">
                  <label className="profile_details_text py-2">
                    Mobile Number:
                  </label>
                  <input
                    type="number"
                    name="phone"
                    className="form-control"
                    value={dummydata?.phone}
                    onChange={(e) =>
                      setDummaydata({
                        ...dummydata,
                        phone: e.target.value,
                      })
                    }
                  />
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 submit">
                <div className="form-group py-3">
                  <input
                    type="submit"
                    className="Submit_btn bg-success"
                    value="Update"
                  />
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};
const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    setUserInfoRedux: (parameter) => {
      dispatch(setUserDetails(parameter));
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(ProfileRightBar);
