import React, { useState } from "react";
import Box from "@mui/material/Box";

import Modal from "@mui/material/Modal";
import { IoIosClose } from "react-icons/io";
import OTPInput from "otp-input-react";
import "../Style/Forget.css";
import { useNavigate } from "react-router-dom";
import { ErrorToaster } from "../Toaster";
import { Otpverify } from "../APIS/AuthApi";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #0D8AAF",
  boxShadow: 24,
  p: 4,
};

const Otpmodel = ({ open, setOpen }) => {
  const [OTP, setOTP] = useState("");
  const navigation = useNavigate();

  const handleClose = () => setOpen(false);
  const handlesubmit = () => {
    if (OTP === "" || OTP.length <= 3) {
      ErrorToaster("Enter otp");
    } else {
      Otpverify(OTP, navigation);
    }
  };
  return (
    <div>
      <Modal open={open} onClose={handleClose}>
        <Box sx={style} className="Otpmodel">
          <>
            <div className="d-flex justify-content-between">
              <h5>Enter Otp</h5>
              <IoIosClose style={{ fontSize: "35px" }} onClick={handleClose} />
            </div>
            <div className="mt-3">
              <OTPInput
                value={OTP}
                onChange={setOTP}
                autoFocus
                OTPLength={4}
                otpType="number"
                disabled={false}
              />
            </div>
            <div className="mt-5 d-flex justify-content-end">
              <button
                type="button"
                className="btn btn-success "
                style={{ background: "#0D8AAF", border: "#0D8AAF" }}
                onClick={handlesubmit}
              >
                Otp Verify
              </button>
            </div>
          </>
        </Box>
      </Modal>
    </div>
  );
};

export default Otpmodel;
