import ProfileLeftBar from "Sections/Profile/ProfileLeftBar";
import ProfileRightBar from "Sections/Profile/ProfileRightBar";
import NavBar from "Sections/HomeSection/NavBar";
import Footer from "Sections/HomeSection/Footer";
import "../Style/profile/Profile.css";
import MyOrder from "Sections/Profile/MyOrder";
import { useState } from "react";
import ChangePassword from "Sections/Profile/ChangePassword";
import DeleteAccount from "Sections/Profile/DeleteAccount";
import { connect } from "react-redux";

import { setUserDetails } from "../Redux/actions";
import CategoriesDropdeon from "Sections/HomeSection/CategoriesDropdeon";
import MyOrderdeatils from "Sections/Profile/MyOrderdeatils";

const Profile = ({ getUserInfoRedux, getUserAuthenticationRedux }) => {
  // console.log(getUserInfoRedux,"userInformation")
  const [profile, setProfile] = useState("profile");
  const [orderId, setOrderId] = useState("");
  const [orderNumber, setOrderNumber] = useState("");
  return (
    <>
      <NavBar />
      <CategoriesDropdeon />
      <hr className="d-md-none d-flex mt-0" />
      <div className="profile">
        <div className="row">
          <div className="col-md-4">
            <ProfileLeftBar
              setProfile={setProfile}
              getUserInfoRedux={getUserInfoRedux}
              getUserAuthenticationRedux={getUserAuthenticationRedux}
            />
          </div>
          <div className="col-md-8">
            {profile === "profile" ? (
              <>
                <ProfileRightBar
                  getUserInfoRedux={getUserInfoRedux}
                  getUserAuthenticationRedux={getUserAuthenticationRedux}
                />
              </>
            ) : (
              ""
            )}
            {profile === "myorders" ? (
              <>
                <MyOrder
                  getUserAuthenticationRedux={getUserAuthenticationRedux}
                  setProfile={setProfile}
                  setOrderId={setOrderId}
                  setOrderNumber={setOrderNumber}
                />
              </>
            ) : (
              ""
            )}
            {profile === "changePass" ? (
              <>
                {" "}
                <ChangePassword
                  getUserAuthenticationRedux={getUserAuthenticationRedux}
                />
              </>
            ) : (
              ""
            )}
            {profile === "deleteAccount" ? (
              <>
                {" "}
                <DeleteAccount
                  getUserAuthenticationRedux={getUserAuthenticationRedux}
                />
              </>
            ) : (
              ""
            )}
            {profile === "orderdeatils" ? (
              <>
                <MyOrderdeatils
                  orderId={orderId}
                  orderNumber={orderNumber}
                  getUserAuthenticationRedux={getUserAuthenticationRedux}
                />
              </>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};
const mapStateToProps = (state) => {
  return {
    getUserAuthenticationRedux: state.userAuthentication.data,
    getUserInfoRedux: state.userInfo.data,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(Profile);
