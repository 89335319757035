import React from "react";
import CategoriesimageOne from "PublicImages/CategoriesProduct/categroisImage_1.png";
import Categoriesimagetwo from "PublicImages/CategoriesProduct/Categoriesimagetwo.png";
import Categoriesimagethree from "PublicImages/CategoriesProduct/Categoriesimagethree.png";
import Categoriesimagefour from "PublicImages/CategoriesProduct/Categoriesimagefour.png";
import Categoriesimagefive from "PublicImages/CategoriesProduct/Categoriesimagefive.png";
import Categoriesimagesix from "PublicImages/CategoriesProduct/Categoriesimagesix.png";
import Categoriesimageseven from "PublicImages/CategoriesProduct/Categoriesimageseven.png";
import CategoriesimageEight from "PublicImages/CategoriesProduct/CategoriesimageEight.png";
import CategoriesimageNine from "PublicImages/CategoriesProduct/CategoriesimageNine.png";
import CategoriesimageTen from "PublicImages/CategoriesProduct/CategoriesimageTen.png";
import CategoriesimageEleven from "PublicImages/CategoriesProduct/CategoriesimageEleven.png";
import CategoriesimageTwelve from "PublicImages/CategoriesProduct/CategoriesimageTwelve.png";
import CategoriesimageThirty from "PublicImages/CategoriesProduct/Categoriesimagethirty.png";

import "Style/Home/CategoriesProduct.css";
import { FaAngleRight } from "react-icons/fa";
const CategoriesProduct = () => {
  const CategoriesProductData = [
    {
      id: 1,
      img: Categoriesimagetwo,
      tittle: "Solar Power Equipment",
    },
    {
      id: 2,
      img: CategoriesimageOne,
      tittle: "Home Appliances",
    },
    {
      id: 3,
      img: CategoriesimageThirty,
      tittle: "Battery Power Packs",
    },
    {
      id: 4,
      img: Categoriesimageseven,
      tittle: "Kitchen & Cooking",
    },
    {
      id: 5,
      img: Categoriesimagefive,
      tittle: "Personal Care",
    },
    {
      id: 6,
      img: Categoriesimagesix,
      tittle: "Watches",
    },
    {
      id: 7,
      img: Categoriesimageseven,
      tittle: "Garden Furniture Accessories",
    },
    {
      id: 8,
      img: CategoriesimageEight,
      tittle: "Garden Equipment",
    },
    {
      id: 9,
      img: CategoriesimageNine,
      tittle: "Electrical Vehicles",
    },
    {
      id: 10,
      img: CategoriesimageThirty,
      tittle: "Health Products",
    },
    {
      id: 11,
      img: CategoriesimageEleven,
      tittle: "Asian Decorative Artifacts",
    },
    {
      id: 12,
      img: CategoriesimageTwelve,
      tittle: "Seasonal Decorative Items",
    },
  ];

  return (
    <>
      <div className="CategoriesProduct">
        <div className="row">
          {CategoriesProductData.map((data, i) => {
            return (
              <div className="col-md-3 mt-4" key={i}>
                <div className="card">
                  <img src={data.img} alt="img" className="w-100" />
                  <div className="content d-flex align-items-center justify-content-between">
                    <h6 className="m-0">See Whole Collection</h6>
                    <FaAngleRight className="arrow" />
                  </div>
                  <h6 className="image_content">{data.tittle}</h6>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default CategoriesProduct;
