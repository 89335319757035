import React from "react";
import { Button } from "react-bootstrap";
import "Style/Home/QuickSection.css";
import { FaAngleRight } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";

const QuickSection = () => {
  const navigation = useNavigate();
  return (
    <>
      <div className="QuickSection">
        <div className="row">
          <div className="col-md-9 col-12">
            <div className="QuickSection_banner_content">
              <h2>Connecting Businesses with Quality Products, Effortlessly</h2>
              <Button
                className="d-flex align-items-center gap-2 QuickSection_Explore"
                onClick={() => {
                  window.scrollTo(0, 0);
                  navigation("/contact-us");
                }}
              >
                Explore Now <FaAngleRight />
              </Button>
            </div>
          </div>
          <div className="col-md-3 col-12">
            <div className="QuickSection_rightImg d-flex flex-column align-items-center">
              <h4 className="m-0">B2B </h4>
              <h5>Quick Quotes</h5>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default QuickSection;
