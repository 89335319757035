import React, { useState } from "react";
import "../Style/Signup.css";

import { IoPhonePortraitSharp } from "react-icons/io5";
import { BiIdCard } from "react-icons/bi";
import { useNavigate } from "react-router-dom";

import { CiMail } from "react-icons/ci";
import { CiLock } from "react-icons/ci";
import { IoEye } from "react-icons/io5";
import { FaEyeSlash } from "react-icons/fa";
import { NavLink } from "react-router-dom";
import logo3 from "../PublicImages/AboutSection/logo3.png";
import { ErrorToaster } from "../Toaster";
import { registerApi } from "../APIS/AuthApi";

const Signup = () => {
  const [showpassword, setShowpassword] = useState(false);
  const [showconfirmpassword, setShowconfirmpassword] = useState(false);
  const [token, setToken] = useState("");
  const navigation = useNavigate();
  let user = "user-a576-4210-94d7-d70d7a6a124b";
  let b2b = "b2buser-f147-488b-b43a-e70562d7ce06";
  const [active, setActive] = useState(true);
  const [active1, setActive2] = useState(false);

  const [input, setInput] = useState({
    email: "",
    password: "",
    confirmpassword: "",
    phone: "",
    name: "",
    lastname: "",
  });
  const [emailError, setEmailError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [lastnameError, setlastnameError] = useState(false);
  const [nameError, setnameError] = useState(false);

  let passReg = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
  let emailreg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w\w+)+$/;
  const handleInput = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setInput({ ...input, [name]: value });
  };
  const handleToken = (type) => {
    if (type === "user") {
      setToken(user);
      setActive(true);
      setActive2(false);
    } else {
      setToken(b2b);
      setActive2(true);
      setActive(false);
    }
  };
  const handlesumbmit = (e) => {
    e.preventDefault();

    if (input.name === "") {
      ErrorToaster("Please Fill The User Name");
      setnameError("Please Fill The User Name");
    } else if (input.lastname === "") {
      setlastnameError("Please Fill The Last Name");
      ErrorToaster("Please Fill The Last Name");
    } else if (emailreg.test(input.email) === false) {
      setEmailError("Please Fill The Email");
      ErrorToaster("Please Fill The Email");
    } else if (passReg.test(input.password) === false) {
      setPasswordError(
        "Password with min 8 Characters Include atleast 1 Uppercase, 1 Special Character, and Alphanumeric Characters"
      );

      ErrorToaster(
        "Password with min 8 Characters Include atleast 1 Uppercase, 1 Special Character, and Alphanumeric Characters"
      );
    } else if (input.password !== input.confirmpassword) {
      setPasswordError(
        "Password with min 8 Characters Include atleast 1 Uppercase, 1 Special Character, and Alphanumeric Characters"
      );
      ErrorToaster(
        "Password with min 8 Characters Include atleast 1 Uppercase, 1 Special Character, and Alphanumeric Characters"
      );
    } else if (!token) {
      ErrorToaster("Please the slected the user or B2B");
    } else if (
      input.phone === "" ||
      input.phone?.length <= "9" ||
      input.phone?.length >= "12"
    ) {
      ErrorToaster("Phone Number 9 to 12 digit");
    } else {
      let fromData = {
        name: input.name,
        last_name: input.lastname,
        email: input.email,
        password: input.password,
        confirm_password: input.confirmpassword,
        contact_number: input.phone,
        token: token,
      };

      registerApi(fromData, navigation);
      setInput({
        email: "",
        password: "",
        confirmpassword: "",
        phone: "",
        name: "",
        lastname: "",
      });
    }
  };
  return (
    <div className="container-fluid">
      <div className="row d-md-flex m-0">
        <div className="col-md-6 col-12">
          <div className="signup-left-side">
            <div className="signup-left-side-1">
              <h1>
                Sign<span style={{ color: "#0D8AAF" }}>Up</span> to Create
                Account
              </h1>
              <div>
                <div className="mt-5">
                  <button
                    className="btn btn-outline-success signuptopbutton"
                    style={{
                      backgroundColor: active === true ? "#0d8aaf" : "",
                      borderColor: active === true ? "#0d8aaf" : "",
                      color: active === true ? "white" : "",
                    }}
                    onClick={() => handleToken("user")}
                  >
                    User
                  </button>
                  <button
                    className="btn btn-outline-success ms-3 signuptopbutton"
                    style={{
                      backgroundColor: active1 === true ? "#0d8aaf" : "",
                      borderColor: active1 === true ? "#0d8aaf" : "",
                      color: active1 === true ? "white" : "",
                    }}
                    onClick={() => handleToken("b2b")}
                  >
                    B2B
                  </button>
                </div>
              </div>
              <form onSubmit={handlesumbmit}>
                <div className="row mt-3 d-flex">
                  <div className="col-lg-6 col-12">
                    <div className="mb-3">
                      <label className="d-flex justify-content-start fw-6">
                        Name
                      </label>
                      <div
                        className="left-side-email mt-2"
                        style={{
                          borderColor: emailError ? "red" : "",
                          Color: emailError ? "red" : "",
                        }}
                      >
                        <BiIdCard />
                        <input
                          className="form-control"
                          type="text"
                          placeholder="Enter Your Name"
                          name="name"
                          value={input.name}
                          onChange={handleInput}
                        />
                      </div>
                      {nameError ? (
                        <p className="text-start mt-2 text-danger">
                          {nameError}
                        </p>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                  <div className=" col-12 col-lg-6 ">
                    <div className="mb-3">
                      <label className="d-flex justify-content-start fw-6">
                        Last Name
                      </label>
                      <div
                        className="left-side-email mt-2"
                        style={{
                          borderColor: lastnameError ? "red" : "",
                          Color: lastnameError ? "red" : "",
                        }}
                      >
                        <BiIdCard />
                        <input
                          className="form-control"
                          type="text"
                          placeholder="Enter Your Last Name"
                          name="lastname"
                          value={input.lastname}
                          onChange={handleInput}
                        />
                      </div>
                      {lastnameError ? (
                        <p className="text-start mt-2 text-danger">
                          {lastnameError}
                        </p>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
                <div className="mb-3">
                  <label className="d-flex justify-content-start fw-6">
                    Email Address
                  </label>
                  <div
                    className="left-side-email mt-2"
                    style={{
                      borderColor: emailError ? "red" : "",
                      Color: emailError ? "red" : "",
                    }}
                  >
                    <CiMail />
                    <input
                      className="form-control"
                      type="email"
                      placeholder="Enter Your Email"
                      name="email"
                      value={input.email}
                      onChange={handleInput}
                    />
                  </div>
                  {emailError ? (
                    <p className="text-start mt-2 text-danger">{emailError}</p>
                  ) : (
                    ""
                  )}
                </div>
                <div className="mb-3">
                  <label className="d-flex justify-content-start fw-6">
                    Phone No.
                  </label>
                  <div className="left-side-email mt-2">
                    <IoPhonePortraitSharp />
                    <input
                      className="form-control"
                      type="number"
                      placeholder="Enter Your Phone No."
                      name="phone"
                      value={input.phone}
                      onChange={handleInput}
                    />
                  </div>
                </div>
                <div className="mb-3">
                  <label className="d-flex justify-content-start fw-6">
                    Password
                  </label>
                  <div
                    className="left-side-password mt-2"
                    style={{
                      borderColor: passwordError ? "red" : "",
                      Color: passwordError ? "red" : "",
                    }}
                  >
                    <CiLock />
                    <div
                      className="leftpassword d-flex"
                      style={{ position: "relative" }}
                    >
                      <input
                        className="form-control"
                        type={showpassword ? "text" : "password"}
                        placeholder="Enter Your password"
                        name="password"
                        value={input.password}
                        onChange={handleInput}
                      />
                      {showpassword ? (
                        <IoEye onClick={() => setShowpassword(!showpassword)} />
                      ) : (
                        <FaEyeSlash
                          onClick={() => setShowpassword(!showpassword)}
                        />
                      )}
                    </div>
                  </div>
                  {passwordError ? (
                    <p className="text-start mt-2 text-danger">
                      {passwordError}
                    </p>
                  ) : (
                    ""
                  )}
                </div>
                <div className="mb-3">
                  <label className="d-flex justify-content-start fw-6">
                    Confirm Password
                  </label>
                  <div className="left-side-password mt-2">
                    <CiLock />
                    <div
                      className="leftpassword d-flex"
                      style={{ position: "relative" }}
                    >
                      <input
                        className="form-control"
                        type={showconfirmpassword ? "text" : "password"}
                        placeholder="Enter Your confirm password"
                        name="confirmpassword"
                        value={input.confirmpassword}
                        onChange={handleInput}
                      />
                      {showconfirmpassword ? (
                        <IoEye
                          onClick={() =>
                            setShowconfirmpassword(!showconfirmpassword)
                          }
                        />
                      ) : (
                        <FaEyeSlash
                          onClick={() =>
                            setShowconfirmpassword(!showconfirmpassword)
                          }
                        />
                      )}
                    </div>
                  </div>
                </div>
                <div className="d-sm-flex justify-content-between">
                  <div className="form-check text-start">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value=""
                      id="flexCheckChecked"
                    />
                    <label class="form-check-label" for="flexCheckChecked">
                      I agree to the
                      <NavLink
                        to=""
                        style={{ color: "#0D8AAF", textDecoration: "none" }}
                      >
                        {" "}
                        &nbsp;Terms &nbsp;
                      </NavLink>
                      and
                      <NavLink
                        to=""
                        style={{ color: "#0D8AAF", textDecoration: "none" }}
                      >
                        {" "}
                        &nbsp;Privacy Policy
                      </NavLink>
                    </label>
                  </div>
                </div>
                <div className="mt-5">
                  <button
                    className="btn btn-success w-100"
                    type="submit"
                    style={{
                      borderRadius: "20px",
                      backgroundColor: "#0D8AAF",
                      borderColor: "#0D8AAF",
                    }}
                  >
                    Signup
                  </button>
                </div>
              </form>
              <div className="d-flex justify-content-center mt-3">
                <p>Already have an account?</p>
                <NavLink
                  to="/login"
                  style={{ color: "#0D8AAF", textDecoration: "none" }}
                >
                  &nbsp;&nbsp;Login
                </NavLink>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-6 col-12 d-md-flex d-none">
          <div className="signup-rigth-side">
            <div className="signup-right-logo">
              <img src={logo3} alt="logo" onClick={() => navigation("/")} />
            </div>
            <div className="signup-right-box">
              <h1> Welcome back !</h1>
              <p className="">
                We are glad to see you again! Get access to your Orders,
                Wishlist and Recommendations.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Signup;
