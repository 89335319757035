import React, { useEffect, useState, useRef, useLayoutEffect } from "react";
import { connect } from "react-redux";
import NavbarLogo from "PublicImages/NavbarImg//NavbarLogo.png";
import { RiArrowDropDownLine } from "react-icons/ri";
import { CiSearch } from "react-icons/ci";
import "Style/Home/NavBar.css";
import { FaRegHeart } from "react-icons/fa6";
import { FaRegUser } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import LoginModel from "Model/LoginModel";
import { logOutAPI } from "../../APIS/AuthApi";
import { SearchProducat } from "APIS/api";
import Location from "./Location";
import { CgProfile } from "react-icons/cg";
import { CiLogout } from "react-icons/ci";
import InfiniteScroll from "react-infinite-scroll-component";
import { FiShoppingCart } from "react-icons/fi";

const NavBar = ({
  getUserAuthenticationRedux,
  getCartLengthRedux,
  getWishLengthRedux,
  getCategroyDataRedux,
}) => {
  const navigation = useNavigate();
  let userAuthentication = localStorage.getItem("transArabiatoken");
  const [showLoginModal, setShowLoginModal] = useState(false);
  const [showprofile, setShowprofile] = useState(false);
  const [categroyshow, setCategroyshow] = useState(false);
  const [search, setSearch] = useState("");
  const [serachInput, setSearchInput] = useState([]);
  const [categoriesID, setCategoriesId] = useState("");
  const [categoriesName, setCategoriesName] = useState("All Categories");
  const [searchitem, setSearchitem] = useState(false);
  const [searchtotal, setSearchtotal] = useState("");
  const [currentPage, setCurrentPage] = useState(1);

  const btnRef = useRef();
  const searchRef = useRef();
  const searchCatRef = useRef();
  const innerRef = useRef();

  const handlelogout = () => {
    if (getUserAuthenticationRedux) {
      logOutAPI(getUserAuthenticationRedux, navigation);
    }
  };

  useEffect(() => {
    if (search === "") {
      setCurrentPage(1);
    }
  }, [search]);

  const searchOutput = (value) => {
    setSearch(value);

    let fromdata = {
      language: "en",
      cat_id: categoriesID,
      search: value,
      page: 1,
    };

    SearchProducat(fromdata, (callback) => {
      setSearchtotal(callback?.total_results);
      setSearchInput(callback?.data?.products);
    });
  };

  const searchOutputScroll = (value) => {
    let fromdata = {
      language: "en",
      cat_id: categoriesID,
      search: value,
      page: currentPage,
    };

    SearchProducat(fromdata, (callback) => {
      setSearchtotal(callback?.total_results);
      setSearchInput([...serachInput, ...callback?.data?.products]);
      setCurrentPage(currentPage + 1);
    });
  };

  const handleChange = (pID, catname) => {
    setCategoriesId(pID);
    setCategoriesName(catname);
    setCategroyshow(false);
  };
  useEffect(() => {
    const closeDropdown = (e) => {
      if (!searchRef.current.contains(e.target)) {
        setSearchitem(false);
        setSearch("");
      }
    };
    document.body.addEventListener("click", closeDropdown);
    return () => document.body.removeEventListener("click", closeDropdown);
  }, []);

  return (
    <>
      <div className="Nav_bar">
        <div className="deskTopNavbar">
          <div className="row d-flex align-items-center">
            <div className="col-md-2 col-6">
              <img onClick={() => navigation("/")} src={NavbarLogo} alt="img" />
            </div>
            <div className="col-md-2 d-md-flex d-none">
              <div className="deskTopNavbar_Deliver ">
                <span className=" gap-1 d-flex justify-content-center align-items-center ">
                  {/* Deliver to Dubai
                  <RiArrowDropDownLine className="drop_icons" /> */}
                  <Location />
                </span>
              </div>
            </div>
            {/* small size only  */}
            <div className="d-md-none d-flex col-6">
              <div className="d-flex justify-content-end align-items-center desktop_right_sec1">
                {getUserAuthenticationRedux ? (
                  <div
                    className="d-flex flex-column align-items-center justify-content-center small-size-cart"
                    style={{ cursor: "pointer", position: "relative" }}
                    onMouseEnter={() => setShowprofile(true)}
                    onMouseLeave={() => setShowprofile(false)}
                    onClick={() => setShowprofile(!showprofile)}
                  >
                    <FaRegUser />
                    <span className="d-none">Profile</span>
                    {showprofile ? (
                      <div className="profile-navbar">
                        <p
                          className="profile-menu-content menu-boder"
                          onClick={() => navigation("/profile")}
                        >
                          <CgProfile className="me-2" /> Profile
                        </p>

                        <p
                          className="profile-menu-content"
                          onClick={handlelogout}
                        >
                          <CiLogout className="me-2" /> Logout
                        </p>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                ) : (
                  <div
                    className="d-flex flex-column align-items-center justify-content-center navbar-login"
                    style={{ cursor: "pointer" }}
                    onClick={() => navigation("/login")}
                  >
                    <FaRegUser />
                    <span className="d-none">Log in</span>
                  </div>
                )}
                <div
                  className="d-flex flex-column align-items-center justify-content-center wishlist_sec navbar-login"
                  onClick={() => {
                    if (userAuthentication) {
                      navigation("/whishlist");
                    } else {
                      setShowLoginModal(true);
                    }
                  }}
                  style={{ position: "relative" }}
                >
                  <FaRegHeart />
                  <span className="d-none">Wishlist</span>
                  {getWishLengthRedux !== 0 ? (
                    <div className="d-flex align-items-center justify-content-center box-wish-nav">
                      {getWishLengthRedux}
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                <div
                  className="d-flex flex-column align-items-center justify-content-center cart_sec navbar-login"
                  onClick={() => {
                    if (userAuthentication) {
                      navigation("/cart");
                    } else {
                      setShowLoginModal(true);
                    }
                  }}
                  style={{ position: "relative" }}
                >
                  <FiShoppingCart />
                  <span className="d-none">Cart</span>
                  {getCartLengthRedux !== 0 ? (
                    <div className="d-flex align-items-center justify-content-center box-cart-nav">
                      {getCartLengthRedux}
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
            {/* small size only  */}

            <div className="col-md-4 col-8 p-0">
              <div
                className="navbar-searchSec"
                style={{ position: "relative" }}
                ref={searchRef}
              >
                <input
                  type="text"
                  placeholder="Find Best Product"
                  name=""
                  value={search}
                  onChange={(e) => searchOutput(e.target.value)}
                  onClick={() => setSearchitem(true)}
                />
                <CiSearch className="navbar-search_icon" />
                {searchitem && search?.length > 0 ? (
                  <div
                    id="scrollableDiv"
                    className="search-output"
                    style={{
                      position: "absolute",
                      top: "40px",
                      left: "0px",
                      maxHeight: 300,
                      overflow: "auto",
                    }}
                  >
                    <InfiniteScroll
                      dataLength={serachInput?.length}
                      hasMore={serachInput?.length < searchtotal}
                      next={() => searchOutputScroll(search)}
                      loader={
                        <p style={{ textAlign: "center" }}>
                          <b>Loading...</b>
                        </p>
                      }
                      scrollableTarget="scrollableDiv"
                      endMessage={
                        <p style={{ textAlign: "center" }}>
                          <b>Yay! You have seen it all</b>
                        </p>
                      }
                    >
                      {serachInput?.map((value, i) => {
                        return (
                          <div
                            className="search-output-input"
                            key={i}
                            onClick={() =>
                              navigation(`/ceiling/${value.id}/${value.slug}`, {
                                state: categoriesName,
                              })
                            }
                          >
                            {value ? value?.name.slice(0, 45) : ""}
                          </div>
                        );
                      })}
                    </InfiniteScroll>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
            <div
              className="col-md-2 col-4 p-0"
              style={{ position: "relative" }}
              ref={searchCatRef}
            >
              <span
                className="gap-2 d-flex justify-content-end align-items-center deskTopNavbar_categories "
                onMouseEnter={() => setCategroyshow(true)}
              >
                {categoriesName ? categoriesName : "All Categories"}
                <RiArrowDropDownLine className="deskTopNavbar_categories_icons" />
              </span>
              {categroyshow ? (
                <div
                  className="All-categories-box"
                  onMouseLeave={() => setCategroyshow(false)}
                  onClick={() => setCategroyshow(false)}
                >
                  <p
                    className="All-categories-box-heading"
                    onClick={(e) => handleChange("", "All Categories")}
                  >
                    All Categories
                  </p>
                  {getCategroyDataRedux
                    ? getCategroyDataRedux?.map((iteam, i) => {
                        return (
                          <p
                            className="All-categories-box-heading"
                            key={i}
                            onClick={(e) =>
                              handleChange(
                                iteam?.parent_cat_id,
                                iteam?.parent_cat_name
                              )
                            }
                          >
                            {iteam?.parent_cat_name}
                          </p>
                        );
                      })
                    : ""}
                </div>
              ) : (
                ""
              )}
            </div>
            <div className="col-md-2">
              <div className="d-flex justify-content-between align-items-center desktop_right_sec">
                {getUserAuthenticationRedux ? (
                  <div
                    className="d-flex flex-column align-items-center justify-content-center"
                    style={{ cursor: "pointer", position: "relative" }}
                    onMouseEnter={() => setShowprofile(true)}
                    onMouseLeave={() => setShowprofile(false)}
                  >
                    <FaRegUser />
                    <span className="d-lg-flex d-none">Profile</span>
                    {showprofile ? (
                      <div className="profile-navbar">
                        <p
                          className="profile-menu-content menu-boder"
                          onClick={() => navigation("/profile")}
                        >
                          <CgProfile className="me-2" /> Profile
                        </p>

                        <p
                          className="profile-menu-content"
                          onClick={handlelogout}
                        >
                          <CiLogout className="me-2" /> Logout
                        </p>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                ) : (
                  <div
                    className="d-flex flex-column align-items-center justify-content-center navbar-login"
                    style={{ cursor: "pointer" }}
                    onClick={() => navigation("/login")}
                  >
                    <FaRegUser />
                    <span className="d-lg-flex d-none">Log in</span>
                  </div>
                )}
                <div
                  className="d-flex flex-column align-items-center justify-content-center wishlist_sec navbar-login"
                  onClick={() => {
                    if (userAuthentication) {
                      navigation("/whishlist");
                    } else {
                      setShowLoginModal(true);
                    }
                  }}
                  style={{ position: "relative" }}
                >
                  <FaRegHeart />
                  <span className="d-lg-flex d-none">Wishlist</span>
                  {getWishLengthRedux !== 0 ? (
                    <div className="d-flex align-items-center justify-content-center box-wish-nav">
                      {getWishLengthRedux}
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                <div
                  className="d-flex flex-column align-items-center justify-content-center cart_sec navbar-login"
                  onClick={() => {
                    if (userAuthentication) {
                      navigation("/cart");
                    } else {
                      setShowLoginModal(true);
                    }
                  }}
                  style={{ position: "relative" }}
                >
                  <FiShoppingCart />
                  <span className="d-lg-flex d-none">Cart</span>
                  {getCartLengthRedux !== 0 ? (
                    <div className="d-flex align-items-center justify-content-center box-cart-nav">
                      {getCartLengthRedux}
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              <LoginModel
                setShowLoginModal={setShowLoginModal}
                showLoginModal={showLoginModal}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
const mapStateToProps = (state) => {
  return {
    getUserAuthenticationRedux: state.userAuthentication.data,
    getCartLengthRedux: state.cartLength.length,
    getWishLengthRedux: state.wishLength.Wishlength,
    getCategroyDataRedux: state.Categroy.data,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(NavBar);
