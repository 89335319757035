import CategoriesDropdeon from "Sections/HomeSection/CategoriesDropdeon";
import Footer from "Sections/HomeSection/Footer";
import NavBar from "Sections/HomeSection/NavBar";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { SingleblogAPI } from "../../APIS/BlogAPi";
import "../../Style/Singleblogpage.css";
import Loader from "Loader";

const Singlebolgpage = () => {
  const [singleblog, setSingleblog] = useState([]);
  const [loading, setLoading] = useState(false);

  const locationId = useLocation();

  const bolgId = locationId.state;

  useEffect(() => {
    singlebolgiteam();
  }, [bolgId]);
  const singlebolgiteam = () => {
    let fromdata = {
      language: "en",
      id: bolgId,
    };
    SingleblogAPI(fromdata, setLoading, (callback) => {
      setSingleblog(callback?.data?.blog_detail);
    });
  };

  return (
    <>
      <NavBar />
      <CategoriesDropdeon />

      <div className="container my-3">
        {loading ? (
          <div
            className="d-flex justify-content-center align-items-center"
            style={{ minHeight: "500px" }}
          >
            <Loader />
          </div>
        ) : (
          <>
            <div className="single-image-box">
              <img
                src={singleblog ? singleblog?.image : ""}
                alt="simgleimage"
              />
            </div>

            <div className="">
              <span className="fs-5"> Date :</span>
              <span>{singleblog ? singleblog?.date : ""}</span>
            </div>
            <div className="blogsingle-title">
              <span>{singleblog ? singleblog?.title : ""}</span>
            </div>
            <div className="">
              <span>{singleblog ? singleblog?.description : ""}</span>
            </div>
          </>
        )}
      </div>
      <Footer />
    </>
  );
};

export default Singlebolgpage;
