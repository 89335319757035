import React, { useEffect, useState } from "react";
import Footer from "Sections/HomeSection/Footer";
import NavBar from "Sections/HomeSection/NavBar";
import { IoMdHome } from "react-icons/io";
import { FaAngleRight } from "react-icons/fa";
import SolarproductCard from "./../Sections/SolarPowered/SolarproductCard";
import { useNavigate, useParams } from "react-router-dom";
import { FetchSolerpowerAPI } from "../APIS/SolerpowerApi";
import { connect } from "react-redux";
import { ADDWishApi } from "../APIS/WhislistAPI";
import { setWishlength } from ".././Redux/actions";
import { NavLink } from "react-router-dom";
import CategoriesDropdeon from "Sections/HomeSection/CategoriesDropdeon";
import LoginModel from "Model/LoginModel";
import { RemoveHeartWishApi } from "../APIS/WhislistAPI";
import { FetchWishAPI } from "../APIS/WhislistAPI";

const SolarPowered = ({
  getUserAuthenticationRedux,
  setWishlengthRedux,
  getUserInformation,
  getwhishlength,
}) => {
  const navigation = useNavigate();
  const params = useParams();
  const [showLoginModal, setShowLoginModal] = useState(false);

  const UserId = getUserInformation ? getUserInformation.user.id : "";

  const [categoriesData, setCategoriesData] = useState("");
  const [categorieslength, setCategorieslength] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [slectedId, setSlectedId] = useState("id");
  const [categroyname, setCategroyname] = useState("");

  const recordsPerPage = 10;
  const lastIndex = currentPage * recordsPerPage;
  const firstIndex = lastIndex - recordsPerPage;

  const npage = Math.ceil(categorieslength / recordsPerPage);
  const numbers = [...Array(npage + 1).keys()].slice(1);

  useEffect(() => {
    FetchSolerpowerdata();
  }, [currentPage, slectedId, params, UserId]);

  const FetchSolerpowerdata = () => {
    let fromData = {
      page: currentPage,
      language: "en",
      orderBy: slectedId,
      categoryId: params.id,
      uid: UserId,
    };

    FetchSolerpowerAPI(fromData, (callback) => {
      setCategroyname(callback?.category_name);
      setCategorieslength(callback.length);
      setCategoriesData(callback.categoryProducts);
    });
  };
  const handlewishlist = (pid) => {
    if (!getUserAuthenticationRedux) {
      setShowLoginModal(true);
    } else {
      let fromdata = {
        language: "en",
        productId: pid,
      };

      ADDWishApi(
        getUserAuthenticationRedux,
        fromdata,
        setWishlengthRedux,
        FetchSolerpowerdata
      );
    }
  };

  const Removewishlist = (pid) => {
    let fromdata = {
      language: "en",
      productId: pid,
    };
    console.log(fromdata, "fromdata");
    RemoveHeartWishApi(
      getUserAuthenticationRedux,
      fromdata,
      setWishlengthRedux,
      FetchSolerpowerdata,
      getwhishlength
    );
  };

  const perPage = () => {
    if (currentPage !== 1) {
      setCurrentPage(currentPage - 1);
    }
  };
  const changeCpage = (n) => {
    setCurrentPage(n);
  };
  const nextPage = () => {
    if (currentPage !== npage) {
      setCurrentPage(currentPage + 1);
    }
  };

  return (
    <>
      <NavBar />
      <CategoriesDropdeon />
      <hr className="m-0" />
      <div className="d-flex align-items-center globle_categories_routing">
        <IoMdHome
          className="globle_Home_icon"
          onClick={() => navigation("/")}
          style={{ cursor: "pointer" }}
        />
        <FaAngleRight className="globle_right_arrow" />
        <span>Categories</span>
        <FaAngleRight className="globle_right_arrow" />
        <span className="opacity-50">{categroyname ? categroyname : ""}</span>
      </div>
      <div className="d-flex justify-content-end my-2 me-md-5 me-2">
        <select
          className="form-select soler-slected-id-filter"
          aria-label="Default select example"
          style={{ width: "180px", position: "relative" }}
          value={slectedId}
          onChange={(e) => setSlectedId(e.target.value)}
        >
          <option selected>Order By Name</option>
          <option value="relevant">Newest First</option>
          <option value="priceDesc">Price High To Low</option>
          <option value="priceAsc"> Price Low To High</option>
        </select>
      </div>
      <SolarproductCard
        categoriesData={categoriesData}
        handlewishlist={handlewishlist}
        Removewishlist={Removewishlist}
        categroyname={categroyname}
      />
      <LoginModel
        setShowLoginModal={setShowLoginModal}
        showLoginModal={showLoginModal}
      />
      <nav>
        <ul className="pagination d-flex justify-content-end me-4 my-3 pagination-iteam">
          <li className="page-item">
            <NavLink
              to=""
              className={`page-link${currentPage === 1 ? "back" : ""}`}
              onClick={perPage}
            >
              {currentPage === 1 ? "" : <>PrevPage</>}{" "}
            </NavLink>
          </li>
          {numbers.map((n, i) => (
            <li
              className={`page-item ${currentPage === n ? "active" : ""}`}
              key={i}
            >
              <NavLink
                to=""
                className="page-link"
                onClick={() => changeCpage(n)}
              >
                {n}
              </NavLink>
            </li>
          ))}
          <li className="page-item">
            <NavLink
              to=""
              className={`page-link${
                currentPage === numbers.length ? "next" : ""
              }`}
              onClick={nextPage}
            >
              {currentPage === numbers.length ? "" : <>NextPage</>}
            </NavLink>
          </li>
        </ul>
      </nav>
      <Footer />
    </>
  );
};
const mapStateToProps = (state) => {
  return {
    getUserAuthenticationRedux: state.userAuthentication.data,
    getUserInformation: state.userInfo.data,
    getwhishlength: state.wishLength.Wishlength,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setWishlengthRedux: (parameter) => {
      dispatch(setWishlength(parameter));
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(SolarPowered);
