import React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { IoCloseSharp } from "react-icons/io5";
import { useNavigate } from "react-router-dom";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",
  border: "2px solid #0aa8d8c6",
  boxShadow: 24,
  p: 4,
  borderRadius: "5px",
};

const Ordercheckoutmodel = ({ open, setOpen, orderId }) => {
  const handleClose = () => setOpen(false);
  const navigation = useNavigate();

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} className="responsive-model-box">
          <div className=" d-flex justify-content-end">
            <div className="Edit-Address-heading">
              <IoCloseSharp onClick={() => navigation("/")} />
            </div>
          </div>

          <div className="mt-3">
            <div className="d-flex justify-content-center align-items-center">
              <h5 style={{ fontSize: "25px", color: "green" }}>
                {orderId ? orderId?.message : ""}
              </h5>
            </div>
            <div className="d-flex justify-content-center align-items-center">
              <p style={{ fontSize: "20px", fontWeight: "600" }}>
                <span>Order-id :</span>
                <span>{orderId ? orderId?.data?.order_id : ""}</span>
              </p>
            </div>
            <div className="d-flex align-items-center justify-content-center">
              <p
                className="mb-0 px-2 py-1"
                style={{
                  border: "1px solid #0aa8d8c6",
                  backgroundColor: "#0aa8d8c6",
                  borderRadius: "5px",
                  cursor: "pointer",
                  color: "white",
                  fontSize: "18px",
                }}
                onClick={() => navigation("/")}
              >
                Home
              </p>
            </div>
          </div>
        </Box>
      </Modal>
    </>
  );
};

export default Ordercheckoutmodel;
