import { USER_AUTHENTICATION } from "../Redux/type";
import { CART_LENGTH } from "../Redux/type";
import { WISH_LENGTH } from "../Redux/type";
import { USER_INFO } from "../Redux/type";
import { CAT_INFO } from "../Redux/type";

export const setUserAuthentication = (parameter) => {
  return {
    payload: parameter,
    type: USER_AUTHENTICATION,
  };
};

export const setCartlength = (parameter) => {
  return {
    payload: parameter,
    type: CART_LENGTH,
  };
};
export const setWishlength = (parameter) => {
  return {
    payload: parameter,
    type: WISH_LENGTH,
  };
};
export const setUserDetails = (parameter) => {
  return {
    payload: parameter,
    type: USER_INFO,
  };
};

export const setCategroyDropDetails = (parameter) => {
  return {
    payload: parameter,
    type: CAT_INFO,
  };
};
