import axios from "axios";
import { baseUrl } from "../baseUrl";
import { SuccessToaster, ErrorToaster } from "../Toaster";

export const FetchWishAPI = (token, callback) => {
  let data = JSON.stringify({
    language: "en",
  });
  let config = {
    method: "post",
    maxBodyLength: Infinity,
    url: `${baseUrl}fetch-wishlist-products`,
    headers: {
      Content_Type: "application/json",
      Authorization: `Bearer ${token}`,
    },
    data: data,
  };
  axios
    .request(config)
    .then((response) => {
      callback(response?.data?.data);
    })
    .catch((error) => {
      console.log(error);
      let err = error?.response?.data?.message;
      if (err.length > 0) {
        console.log(error?.response?.data?.message);
      } else {
      }
    });
};
export const RemoveWishApi = (
  token,
  formdata,
  FetchwishData,
  wishData,
  setWishData,
  setWishlengthRedux
) => {
  let data = JSON.stringify(formdata);
  let config = {
    method: "post",
    maxBodyLength: Infinity,
    url: `${baseUrl}remove-to-wishlist`,
    headers: {
      Content_Type: "application/json",
      Authorization: `Bearer ${token}`,
    },
    data: data,
  };
  axios
    .request(config)
    .then((response) => {
      console.log(response);
      console.log(response?.data);
      SuccessToaster(response?.data?.message);

      if (wishData.length === 1) {
        setWishData([]);
        setWishlengthRedux(0);
        return;
      }
      FetchwishData();
    })
    .catch((error) => {
      let err = error?.response?.data?.message;
      if (err.length > 0) {
        console.log(error?.response?.data?.message);
      } else {
      }
    });
};

export const RemoveHeartWishApi = (
  token,
  formdata,
  setWishlengthRedux,
  FetchSolerpowerdata,
  getwhishlength
) => {
  let data = JSON.stringify(formdata);
  let config = {
    method: "post",
    maxBodyLength: Infinity,
    url: `${baseUrl}remove-to-wishlist`,
    headers: {
      Content_Type: "application/json",
      Authorization: `Bearer ${token}`,
    },
    data: data,
  };
  axios
    .request(config)
    .then((response) => {
      SuccessToaster(response?.data?.message);
      FetchSolerpowerdata();
      if (getwhishlength === 1) {
        setWishlengthRedux(0);
        return;
      }
      FetchWishAPI(token, (callback) => {
        setWishlengthRedux(callback.length);
      });
    })
    .catch((error) => {
      let err = error?.response?.data?.message;
      if (err.length > 0) {
        console.log(error?.response?.data?.message);
      } else {
      }
    });
};
export const ADDWishApi = (
  token,
  formdata,
  setWishlengthRedux,
  FetchSolerpowerdata
) => {
  let data = JSON.stringify(formdata);
  let config = {
    method: "post",
    maxBodyLength: Infinity,
    url: `${baseUrl}add-to-wishlist`,
    headers: {
      Content_Type: "application/json",
      Authorization: `Bearer ${token}`,
    },
    data: data,
  };
  axios
    .request(config)
    .then((response) => {
      SuccessToaster(response?.data?.message);
      FetchSolerpowerdata();
      FetchWishAPI(token, (callback) => {
        setWishlengthRedux(callback.length);
      });
    })
    .catch((error) => {
      let err = error?.response?.data?.message;
      if (err.length > 0) {
        console.log(error?.response?.data?.message);
      } else {
      }
    });
};
