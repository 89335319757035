import axios from "axios";
import { baseUrl } from "../baseUrl";
import { SuccessToaster, ErrorToaster } from "../Toaster";

export const SearchProducat = (formdata, callback) => {
  let data = JSON.stringify(formdata);
  let config = {
    method: "post",
    maxBodyLength: Infinity,
    url: `${baseUrl}search-product`,
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };
  axios
    .request(config)
    .then((response) => {
      callback(response?.data);
    })
    .catch((error) => {
      console.log(error);

      console.log(error, "error");
    });
};

export const ContactUsAPI = (fromdata) => {
  let data = JSON.stringify(fromdata);

  let config = {
    method: "post",
    maxBodyLength: Infinity,
    url: `${baseUrl}contact-us`,
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };
  axios
    .request(config)
    .then((response) => {
      SuccessToaster(response?.data?.message);
    })
    .catch((error) => {
      console.log(error);
      let err = error?.response?.data?.message;
      if (err.length > 0) {
        console.log(error?.response?.data?.message);
      } else {
      }
      console.log(error, "error");
    });
};
export const TestiMonialsAPI = (fromdata, callback) => {
  let data = JSON.stringify(fromdata);
  let config = {
    method: "post",
    maxBodyLength: Infinity,
    url: `${baseUrl}fetch-testimonials`,
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };
  axios
    .request(config)
    .then((response) => {
      callback(response?.data?.data);
    })
    .catch((error) => {
      console.log(error);
      let err = error?.response?.data?.message;
      if (err.length > 0) {
        console.log(error?.response?.data?.message);
      } else {
      }
      console.log(error, "error");
    });
};
export const verficationAPI = (fromtoken, callback) => {
  let data = JSON.stringify({
    token: fromtoken,
  });

  let config = {
    method: "post",
    maxBodyLength: Infinity,
    url: `${baseUrl}verify-email`,
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };

  axios
    .request(config)
    .then((response) => {
      callback(response);
    })
    .catch((error) => {
      console.log(error, "test");
    });
};

export const FetchAddressAPI = (token, callback) => {
  let config = {
    method: "get",
    maxBodyLength: Infinity,
    url: `${baseUrl}fetch-all-address`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };
  axios
    .request(config)
    .then((response) => {
      callback(response?.data?.data);
    })
    .catch((error) => {
      console.log(error);
      let err = error?.response?.data?.message;
      if (err.length > 0) {
        console.log(error?.response?.data?.message);
      } else {
      }
      console.log(error, "error");
    });
};
export const RemoveAddressAPI = (token, fromdata, FetchAddress) => {
  let data = JSON.stringify(fromdata);
  let config = {
    method: "post",
    maxBodyLength: Infinity,
    url: `${baseUrl}delete-address`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    data: data,
  };
  axios
    .request(config)
    .then((response) => {
      SuccessToaster(response?.data?.message);
      FetchAddress();
    })
    .catch((error) => {
      console.log(error);
      let err = error?.response?.data?.message;
      if (err.length > 0) {
        console.log(error?.response?.data?.message);
      } else {
      }
      console.log(error, "error");
    });
};

export const addAddressApi = (token, fromdata, FetchAddress) => {
  let data = JSON.stringify(fromdata);
  let config = {
    method: "post",
    maxBodyLength: Infinity,
    url: `${baseUrl}add-address`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    data: data,
  };
  axios
    .request(config)
    .then((response) => {
      SuccessToaster(response?.data?.message);
      FetchAddress();
    })
    .catch((error) => {
      console.log(error);
      let err = error?.response?.data?.message;
      if (err.length > 0) {
        console.log(error?.response?.data?.message);
      } else {
      }
      console.log(error, "error");
    });
};
export const UpdateAddressApi = (token, fromdata, FetchAddress) => {
  let data = JSON.stringify(fromdata);
  let config = {
    method: "post",
    maxBodyLength: Infinity,
    url: `${baseUrl}update-address`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    data: data,
  };
  axios
    .request(config)
    .then((response) => {
      SuccessToaster(response?.data?.message);
      FetchAddress();
    })
    .catch((error) => {
      console.log(error);
      let err = error?.response?.data?.message;
      if (err.length > 0) {
        console.log(error?.response?.data?.message);
      } else {
      }
      console.log(error, "error");
    });
};

export const OfferDetailsAPI = (fromdata, callback) => {
  let data = JSON.stringify(fromdata);
  let config = {
    method: "post",
    maxBodyLength: Infinity,
    url: `${baseUrl}discount-offers`,
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };
  axios
    .request(config)
    .then((response) => {
      callback(response?.data);
    })
    .catch((error) => {
      console.log(error);
      let err = error?.response?.data?.message;
      if (err.length > 0) {
        console.log(error?.response?.data?.message);
      } else {
      }
      console.log(error, "error");
    });
};

export const CoupanApply = (token, fromdata, FetchCartdata, setRemoveshow) => {
  let data = JSON.stringify(fromdata);

  let config = {
    method: "post",
    maxBodyLength: Infinity,
    url: `${baseUrl}coupon-apply`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    data: data,
  };
  axios
    .request(config)
    .then((response) => {
      FetchCartdata();
      SuccessToaster(response?.data?.message);
      setRemoveshow(true);
    })
    .catch((error) => {
      console.log(error);
      ErrorToaster(error?.response?.data?.message);
      let err = error?.response?.data?.message;
      if (err.length > 0) {
        console.log(error?.response?.data?.message);
      } else {
      }
    });
};
export const RemoveCoupanApply = (token, fromdata, FetchCartdata) => {
  let data = JSON.stringify(fromdata);

  let config = {
    method: "post",
    maxBodyLength: Infinity,
    url: `${baseUrl}coupon-remove`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    data: data,
  };
  axios
    .request(config)
    .then((response) => {
      FetchCartdata();
      SuccessToaster(response?.data?.message);
    })
    .catch((error) => {
      console.log(error);
      let err = error?.response?.data?.message;
      if (err.length > 0) {
        console.log(error?.response?.data?.message);
      } else {
      }
      console.log(error, "error");
    });
};
