import React from "react";
import "Style/Home/Partners.css";
import NavBar from "Sections/HomeSection/NavBar";
import Footer from "Sections/HomeSection/Footer";
import partnerHeaderImg from "../../PublicImages/partnersHeaderImg_2.png";
import { BiLogoFacebook } from "react-icons/bi";
import { AiOutlineInstagram } from "react-icons/ai";
import { AiOutlineTwitter } from "react-icons/ai";

const Partners = () => {
  return (
    <>
      <NavBar />
      <div className="Partners">
        <div className="Partners_header_banner d-flex justify-content-center align-items-center">
          <div className="row d-flex justify-content-center align-items-center">
            <div className="col-md-5">
              <h1>
                Grow Your Business as a <span>Transarabia</span> Partners
              </h1>
              <p>
                Let's work togther. join our community of partners to better
                server mutual customers and grow your business
              </p>
            </div>
            <div className="col-md-7">
              <img src={partnerHeaderImg} alt="img" />
            </div>
          </div>
        </div>

        {/* Partnership Conditions ---------------------------------------------- */}
        <section className="partnership_conditions ">
          <h2>What Is a Partners Page?</h2>
          <p>
            A partners page offers information about a company’s reputable
            partnerships, alliances, and integrations within their ecosystem.
          </p>
          <p>
            In most cases, SaaS companies will want to use partners pages to
            address two audiences; prospects and customers.
          </p>
          <p>
            For prospective partners, these pages give details about the kinds
            of partner programs you have, and the benefits of being a partner.
            Here, you will want to pitch why someone should join your network
            and become an evangelist for your products or services.
          </p>
          <p>
            For customers, partners pages exist to exhibit additional resources
            in your ecosystem that increase the utility of your products. This
            often includes a marketplace or directory, where users can browse
            your organization’s partnerships and find the ones that will help
            them get the most out of your products.
          </p>
          <h2>Why Have a Partners Page?</h2>
          <p>
            There are a number of reasons to invest in partner content on your
            website.
          </p>
          <h3>1. Increase partnership opportunities</h3>
          <p>
            A well-designed partners page can play a crucial role in attracting
            and building successful partnerships. Before joining a partner
            program, prospects often ask the following:
          </p>
          <ul>
            <li>What types of partnerships does this organization have?</li>
            <li>What are the benefits to partnering with this organization?</li>
            <li>Can we have trust in partnering with this organization?</li>
          </ul>
          <p>
            “Our Partners” pages address these questions by featuring elements
            like program details, perks, and social proof. High-quality,
            interactive content will entice prospects to inquire, and ultimately
            join work with you.
          </p>
          <h3>2. Establish credibility and trust</h3>
          <p>
            Trust is a must – especially when it comes to building SaaS
            partnerships.
          </p>
          <p>
            Partners pages are an opportunity to establish credibility with both
            partners and customers by displaying success stories, testimonials,
            partner logos, and accreditations. Draw attention to the meaningful
            and measurable outcomes you’ve achieved by collaborating with
            partners.
          </p>
          <p>
            Moreover, these partners can be extremely valuable to your overall
            brand equity or the utility of your products. Just like
            integrations, partnerships and strategic alliances are critical for
            onboarding and attracting interest from prospective buyers.
          </p>
          <h3>3. Help customers find product experts</h3>
          <p>
            Showcase the people and organizations in your ecosystem that can
            assist users in maximizing the value of your offerings. Whether your
            partners offer implementation, optimization, strategic guidance, or
            any other type of assistance, marketplaces make it easy for
            customers to find and evaluate these additional resources.
          </p>
          <h3>4. Differentiate from competitors</h3>
          <p>
            What better way to stand out from the competition than to flex your
            unique network?
          </p>
          <p>
            An impressive partners page sets a brand apart from competitors by
            demonstrating the strength and diversity of its ecosystem. The best
            way to do this is by creating a directory, which encompasses the
            wide range of partnerships your organization has. This further
            conveys your brand’s credibility and distinguishes your company in
            the market.
          </p>
        </section>

        {/* partners Profiles --------------------------------------- */}
        <section className="team-section d-flex justify-content-center flex-column  align-items-center">
          <div className="partner_heading_content pb-4  text-center">
            <h2>
              Jion the <span>Transarabia</span> PartnerShip Program
            </h2>
          </div>
          <div className="row justify-content-center">
            <div className="col-12 col-md-6">
              <div className="card border-0 shadow-lg pt-5 my-5 position-relative">
                <div className="card-body p-4">
                  <div className="member-profile position-absolute w-100 text-center">
                    <img
                      className="rounded-circle mx-auto d-inline-block shadow-sm"
                      src="https://bootdey.com/img/Content/avatar/avatar7.png"
                      alt="img"
                    />
                  </div>
                  <div className="card-text pt-1">
                    <h5 className="member-name mb-0 text-center  font-weight-bold">
                      John Lynch
                    </h5>
                    <div className="mb-3 text-center">Co-founder / CEO</div>
                    <div>
                      Lorem ipsum dolor sit amet, consectetuer adipiscing elit.
                      Aenean commodo ligula eget dolor. Vivamus eget eros
                      vestibulum, accumsan ante viverra, condimentum tellus.
                      Curabitur pellentesque convallis purus non ornare. Donec
                      bibendum sed purus dignissim rutrum. Maecenas bibendum
                      feugiat est, et venenatis nunc.
                    </div>
                  </div>
                </div>
                <div className="card-footer theme-bg-primary border-0 text-center">
                  <ul className="social-list list-inline mb-0 mx-auto d-flex  justify-content-center align-items-center gap-3">
                    <li>
                      <BiLogoFacebook />
                    </li>
                    <li>
                      <AiOutlineInstagram />
                    </li>
                    <li>
                      <AiOutlineTwitter />
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="col-12 col-md-6">
              <div className="card border-0 shadow-lg pt-5 my-5 position-relative">
                <div className="card-body p-4">
                  <div className="member-profile position-absolute w-100 text-center">
                    <img
                      className="rounded-circle mx-auto d-inline-block shadow-sm"
                      src="https://bootdey.com/img/Content/avatar/avatar1.png"
                      alt="img"
                    />
                  </div>
                  <div className="card-text pt-1">
                    <h5 className="member-name mb-0 text-center  font-weight-bold">
                      Joe Thomas
                    </h5>
                    <div className="mb-3 text-center">Co-founder / CTO</div>
                    <div>
                      Lorem ipsum dolor sit amet, consectetuer adipiscing elit.
                      Aenean commodo ligula eget dolor. Vivamus eget eros
                      vestibulum, accumsan ante viverra, condimentum tellus.
                      Curabitur pellentesque convallis purus non ornare. Donec
                      bibendum sed purus dignissim rutrum. Maecenas bibendum
                      feugiat est, et venenatis nunc.
                    </div>
                  </div>
                </div>
                <div className="card-footer theme-bg-primary border-0 text-center">
                  <ul className="social-list list-inline mb-0 mx-auto d-flex  justify-content-center align-items-center gap-3">
                    <li>
                      <BiLogoFacebook />
                    </li>
                    <li>
                      <AiOutlineInstagram />
                    </li>
                    <li>
                      <AiOutlineTwitter />
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="col-12 col-md-6 col-lg-4">
              <div className="card border-0 shadow-lg pt-5 my-5 position-relative">
                <div className="card-body p-4">
                  <div className="member-profile position-absolute w-100 text-center">
                    <img
                      className="rounded-circle mx-auto d-inline-block shadow-sm"
                      src="https://bootdey.com/img/Content/avatar/avatar8.png"
                      alt=""
                    />
                  </div>
                  <div className="card-text pt-1">
                    <h5 className="member-name mb-0 text-center  font-weight-bold">
                      Jean Torres
                    </h5>
                    <div className="mb-3 text-center">Marketing</div>
                    <div>
                      Lorem ipsum dolor sit amet, consectetuer adipiscing elit.
                      Aenean commodo ligula eget dolor. Vivamus eget eros
                      vestibulum, accumsan ante viverra, condimentum tellus.
                    </div>
                  </div>
                </div>
                <div className="card-footer theme-bg-primary border-0 text-center">
                  <ul className="social-list list-inline mb-0 mx-auto d-flex  justify-content-center align-items-center gap-3">
                    <li>
                      <BiLogoFacebook />
                    </li>
                    <li>
                      <AiOutlineInstagram />
                    </li>
                    <li>
                      <AiOutlineTwitter />
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-6 col-lg-4">
              <div className="card border-0 shadow-lg pt-5 my-5 position-relative">
                <div className="card-body p-4">
                  <div className="member-profile position-absolute w-100 text-center">
                    <img
                      className="rounded-circle mx-auto d-inline-block shadow-sm"
                      src="https://bootdey.com/img/Content/avatar/avatar2.png"
                      alt=""
                    />
                  </div>
                  <div className="card-text pt-1">
                    <h5 className="member-name mb-0 text-center  font-weight-bold">
                      Doris Meyer
                    </h5>
                    <div className="mb-3 text-center">Product Manager</div>
                    <div>
                      Lorem ipsum dolor sit amet, consectetuer adipiscing elit.
                      Aenean commodo ligula eget dolor. Vivamus eget eros
                      vestibulum, accumsan ante viverra, condimentum tellus.
                    </div>
                  </div>
                </div>
                <div className="card-footer theme-bg-primary border-0 text-center">
                  <ul className="social-list list-inline mb-0 mx-auto d-flex  justify-content-center align-items-center gap-3">
                    <li>
                      <BiLogoFacebook />
                    </li>
                    <li>
                      <AiOutlineInstagram />
                    </li>
                    <li>
                      <AiOutlineTwitter />
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-6 col-lg-4">
              <div className="card border-0 shadow-lg pt-5 my-5 position-relative">
                <div className="card-body p-4">
                  <div className="member-profile position-absolute w-100 text-center">
                    <img
                      className="rounded-circle mx-auto d-inline-block shadow-sm"
                      src="https://bootdey.com/img/Content/avatar/avatar3.png"
                      alt="img"
                    />
                  </div>
                  <div className="card-text pt-1">
                    <h5 className="member-name mb-0 text-center font-weight-bold">
                      Ronald Reid
                    </h5>
                    <div className="mb-3 text-center">iOS Developer</div>
                    <div>
                      Lorem ipsum dolor sit amet, consectetuer adipiscing elit.
                      Aenean commodo ligula eget dolor. Vivamus eget eros
                      vestibulum, accumsan ante viverra, condimentum tellus.
                    </div>
                  </div>
                </div>
                <div className="card-footer theme-bg-primary border-0 text-center">
                  <ul className="social-list list-inline mb-0 mx-auto d-flex  justify-content-center align-items-center gap-3">
                    <li>
                      <BiLogoFacebook />
                    </li>
                    <li>
                      <AiOutlineInstagram />
                    </li>
                    <li>
                      <AiOutlineTwitter />
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <Footer />
    </>
  );
};

export default Partners;
