import React, { useEffect, useState } from "react";
import "../../Style/profile/My_order.css";
import { OrderFetchAPI } from "../../APIS/OrderCreateAPI";
import { NavLink } from "react-router-dom";

const MyOrder = ({
  getUserAuthenticationRedux,
  setProfile,
  setOrderId,
  setOrderNumber,
}) => {
  const [orderlist, setOrderlist] = useState([]);
  const [orderlisttotal, setOrderlisttotal] = useState("");
  const [currentPage, setCurrentPage] = useState("1");

  const recordsPerPage = 10;
  const lastIndex = currentPage * recordsPerPage;
  const firstIndex = lastIndex - recordsPerPage;

  const npage = Math.ceil(orderlisttotal / recordsPerPage);
  const numbers = [...Array(npage + 1).keys()].slice(1);
  useEffect(() => {
    orderListData();
  }, [currentPage]);
  const orderListData = () => {
    let fromdata = {
      language: "en",
      page: currentPage,
    };
    OrderFetchAPI(getUserAuthenticationRedux, fromdata, (callback) => {
      setOrderlisttotal(callback?.total_orders);

      setOrderlist(callback?.data?.orders);
    });
  };
  const perPage = () => {
    if (currentPage !== 1) {
      setCurrentPage(currentPage - 1);
    }
  };
  const changeCpage = (n) => {
    setCurrentPage(n);
  };
  const nextPage = () => {
    if (currentPage !== npage) {
      setCurrentPage(currentPage + 1);
    }
  };
  const handleview = (orderdeatils, orderId, order_number) => {
    setProfile(orderdeatils);
    setOrderId(orderId);
    setOrderNumber(order_number);
  };
  return (
    <>
      <div className="My_order">
        <h3 className="m-0">My Order Details</h3>
        <div className="row">
          <hr />
          <div className="table-responsive">
            <table className="table">
              <thead>
                <tr>
                  <th scope="col">Image</th>
                  <th scope="col">Product</th>
                  <th scope="col">Order Number</th>

                  <th scope="col">Status</th>
                  <th scope="col" className="text-right">
                    Price
                  </th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {orderlist
                  ? orderlist?.map((item, i) => {
                      const {
                        base_price,
                        createdAt,
                        featured_image,
                        id,
                        name,
                        orderId,
                        order_amount,
                        order_number,
                        productId,
                        quantity,
                        sale_price,
                        slug,
                        status,
                        user_id,
                      } = item;
                      return (
                        <tr key={i}>
                          <td className="order-image">
                            <img
                              src={
                                featured_image
                                  ? featured_image
                                  : "https://dummyimage.com/50x50/55595c/fff"
                              }
                            />
                          </td>
                          <td>{name ? name.slice(0, 50) : ""}</td>
                          <td>{order_number}</td>

                          <td>
                            <p className="d-flex align-items-center mb-0 justify-content-center order-table-status">
                              {" "}
                              {status === 3
                                ? "CANCEL"
                                : status === 2
                                ? "SRRIPE"
                                : "COD"}
                            </p>
                          </td>

                          <td className="text-right">${order_amount} </td>
                          <td className="text-right">
                            <div className="d-flex align-items-center">
                              <button
                                className="myorderbutton bg-danger"
                                onClick={() =>
                                  handleview(
                                    "orderdeatils",
                                    orderId,
                                    order_number
                                  )
                                }
                              >
                                View Details
                              </button>
                            </div>
                          </td>
                        </tr>
                      );
                    })
                  : ""}
              </tbody>
            </table>
          </div>
          <nav>
            <ul className="pagination d-flex justify-content-end me-1 my-3 pagination-iteam">
              <li className="page-item">
                <NavLink
                  to=""
                  className={`page-link${currentPage === 1 ? "back" : ""}`}
                  onClick={perPage}
                >
                  {currentPage === 1 ? "" : <>PrevPage</>}{" "}
                </NavLink>
              </li>
              {numbers.map((n, i) => (
                <li
                  className={`page-item ${currentPage === n ? "active" : ""}`}
                  key={i}
                >
                  <NavLink
                    to=""
                    className="page-link"
                    onClick={() => changeCpage(n)}
                  >
                    {n}
                  </NavLink>
                </li>
              ))}
              <li className="page-item">
                <NavLink
                  to=""
                  className={`page-link${
                    currentPage === numbers.length ? "next" : ""
                  }`}
                  onClick={nextPage}
                >
                  {currentPage === numbers.length ? "" : <>NextPage</>}
                </NavLink>
              </li>
            </ul>
          </nav>
          {/* </div> */}
        </div>
      </div>
    </>
  );
};

export default MyOrder;
