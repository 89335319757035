import CategoriesDropdeon from "Sections/HomeSection/CategoriesDropdeon";
import Footer from "Sections/HomeSection/Footer";
import NavBar from "Sections/HomeSection/NavBar";
import React from "react";
import "../../Style/Home/B2Bpage.css";

const B2BPage = () => {
  return (
    <>
      <NavBar />
      <CategoriesDropdeon />
      <div className="container" style={{ minHeight: "550px" }}>
        <div className="my-3">
          <div className="">
            <h4>Business To Business </h4>
          </div>
          <div className="B2B">
            <h6>Streamline Lead Generation</h6>
            <p>
              One of the key advantages of using B2B lead management software is
              its ability to streamline your lead generation efforts. With this
              software in place, you can automate various aspects of the lead
              generation process, such as capturing leads from multiple sources
              like your website forms, social media campaigns, and email
              marketing initiatives. The software also allows you to segment and
              categorize leads based on their level of interest or engagement
              with your brand.
            </p>
            <p className="my-2">
              By automating these processes, you can save valuable time and
              resources that would otherwise be spent on manual data entry and
              organization. This enables your sales team to focus on what they
              do best – closing deals and generating revenue. Additionally, the
              software provides real-time analytics and reporting features that
              give you insights into the effectiveness of your lead generation
              strategies. You can easily track key metrics such as conversion
              rates, cost per lead, and return on investment (ROI), allowing you
              to make data-driven decisions to optimize your campaigns.
            </p>
            <div className="B2B">
              <h6>Efficient Lead Nurturing</h6>
              <p>
                Once you’ve captured leads through various channels, it’s
                essential to nurture them effectively to move them through the
                sales funnel. B2B lead management software offers advanced lead
                nurturing capabilities that help you build strong relationships
                with potential customers by delivering personalized content at
                every stage of their buyer journey.
              </p>
              <p className="my-2">
                The software allows you to create automated email workflows
                tailored to each lead’s specific needs and interests. You can
                send targeted emails based on their behavior, such as clicks,
                downloads, or website visits. By delivering relevant and timely
                content to your leads, you increase the chances of converting
                them into qualified prospects.
              </p>
            </div>
            <div className="B2B">
              <h6>Seamless Sales Integration</h6>
              <p>
                To maximize your sales potential, it’s crucial to have seamless
                integration between your lead generation efforts and your sales
                process. B2B lead management software offers integration with
                customer relationship management (CRM) systems, allowing for
                seamless transfer of data between marketing and sales teams.
              </p>
              <p className="my-2">
                By integrating your marketing and sales processes, you can
                ensure that no leads fall through the cracks. The software
                enables automatic lead handoff from marketing to sales once a
                lead reaches a certain threshold of engagement or interest. This
                eliminates manual handoffs and reduces the chances of
                miscommunication or missed opportunities.
              </p>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default B2BPage;
