import React from "react";
import NavBar from "Sections/HomeSection/NavBar";
import Footer from "Sections/HomeSection/Footer";
import "Style/Home/Policies.css";
import CategoriesDropdeon from "Sections/HomeSection/CategoriesDropdeon";

const Policies = () => {
  return (
    <>
      <NavBar />
      <CategoriesDropdeon/>
      <div className="Transarabia_polices">
        <div className="polices_banner d-flex align-items-center justify-content-center">
          <div className="polices_banner_text d-flex align-items-center justify-content-center flex-column">
            <h1>Our Policies</h1>
            <p className="text-center">
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the industry's standard dummy text
              ever since the 1500s,
            </p>
          </div>
        </div>
        <div className="ploices_content">
          <h3>Privacy Policy</h3>
          <p>
            Last updated February 2015.
            <br />
            Welcome to TransArabia.com (the "Site"), provided by TransArabia LLC, and
            its affiliates, subsidiaries, parent company and other related
            companies ("Us", "We", or "TransArabia"). This TransArabia.com Privacy
            Policy ("Policy") describes the information we gather from you when
            you use the Site, mobile applications, and related services
            (together, the "Services") and how we use, process, and disclose
            that information. We may add to this Policy with other notices. We
            may also post additional privacy statements for some portions of the
            Services. By submitting personal information through our Services,
            you expressly consent to the transfer of your personal data to our
            servers in the U.S. for our collection, use, and disclosure in
            accordance with this Policy.
          </p>
          <h3>Information We Collect</h3>
          <p>
            When you create an account and use our Service, we collect the
            following types of information from you:
            <br />
            1. Name;
            <br />
            2. Contact information such as your email address and phone number;
            <br />
            3. Demographics information such as your gender and location;
            <br />
            4. Any other information, such as reviews, content, and bio, you
            provide us.
            <br />
            If you create an account using, or otherwise connect your account
            to, a social networking services account (e.g. Twitter or Facebook),
            we may also collect information provided to us by such social
            networking service including for example your: name; email address;
            birthday; geographic location; interests; profile picture; gender;
            networks; user ID; list of friends; and any information you have
            made public on such social networking account.
            <br />
            We automatically collect information about how you use our services,
            for example, pages you have viewed. We also collect certain
            technical information about your device including your Internet
            protocol address, geo-location information, your browser type,
            language and identifying information, your operating system and
            application version, device types, device model and manufacturer,
            device identifiers, and your device operating system type and
            version.
            <br />
            We also use cookies, Web beacons, and URL information to gather
            information regarding the date and time of your visit and the
            information for which you searched and which you viewed. Cookies are
            small pieces of information that a website sends to your computer's
            hard drive while you are viewing a website. We may use both session
            cookies (which expire once you close your web browser) and
            persistent cookies (which usually stay on your computer until you
            delete them) to provide you with a more personal and interactive
            experience on our Site. Web beacons are digital images that are used
            to log information on the Services or in our emails. We use Web
            beacons to manage cookies, count visits, and to learn what marketing
            works and what does not. We also use Web beacons to tell if you open
            or act on our emails.
            <br />
            Others, including third party analytics service providers and
            advertising partners may also collect personally identifiable
            information about your online activities over time and across
            different Web sites when you use our Services, including as
            described in this Privacy Policy. This Policy does not apply to and
            we are not responsible for those other parties. Third party
            analytics services may use cookies and web beacons through our Site
            (for example Google Analytics) to provide us with information about
            how you use and interact with our Site. We encourage you to review
            the privacy policies of these third parties to learn about your
            choices about information they collect from you.
          </p>
          <h3>Sharing Of Your Information</h3>
          <p>
            The TransArabia Services may allow you to connect and share your
            actions, comments, content, profile, and information publicly or
            with other people. Please be mindful of your own privacy needs as
            you choose who to connect with and what to share and make public. We
            cannot control the privacy or security of information you choose to
            make public or share with others.
            <br />
            The Services may also provide you with the option to share certain
            information from your TransArabia account with social networking
            services like Facebook, Google, or Twitter. We are not responsible
            for the use or re-sharing by others of any of your information once
            it is made public. If you do not want your information to be made
            public, you should not use the TransArabia Services in this manner
            and/or you should adjust your privacy settings on the applicable
            social networking service accordingly. We are not responsible for
            and we do not contro these social networking services privacy
            practices. Please review the applicable privacy policy for
            information about how they use your information.
          </p>
          <h3>How we Use Your Information</h3>
          <p>
            We generally use your information to:
            <br />
            Facilitate the creation of and secure your account on the Services;
            Identify you as a user in our system; Provide, personalize, and
            improve the Services; Communicate with you about your use of the
            Services; Develop new products and services, Customize the
            advertising you view and recommend content; Fulfill your requests;
            Send newsletters, surveys, offers and promotional materials related
            to the Services and for other marketing purposes of TransArabia using
            your contact information; Protect, investigate, and prevent
            potentially fraudulent, unauthorized, or illegal activities; Protect
            our rights and the rights of other users; and As otherwise described
            in this Privacy Policy or in notices we provided to you. We may also
            use your information to verify your geographic location. We may use
            your geographic location data to personalize our Service, to
            recommend content, determine whether the information you have
            requested is available in your location.
          </p>
          <h3>How we Disclose Your Information</h3>
          <p>
            We may share your information as follows:
            <br />
            1. We may share your personal information with your consent or at
            your direction. 2. We may also share your information with others
            who perform services on our behalf. 3. We may disclose your
            information if we believe we are required to do so by law, or to
            comply with a court order, judicial or other government subpoena, or
            warrant. 4. We also may disclose your information if we believe
            doing so is appropriate or necessary to prevent any liability, or
            fraudulent, abusive, or unlawful uses or to protect TransArabia and our
            Services; or any rights, property, or personal safety of TransArabia or
            others. 5. In the event that TransArabia is or may be acquired by or
            merged with another company or involved in any other business deal
            (or negotiation of a business deal) involving sale or transfer of
            all or part of our business or assets, we may transfer or assign
            your information as part of or in connection with the transaction.
            Finally, in the event of insolvency, bankruptcy, or receivership,
            information may be transferred as a business asset. We may also
            share aggregated and anonymized data with our partners, advertisers,
            and other third parties.
          </p>
          <h3>Security</h3>
          <p>
          TransArabia takes commercially reasonable steps to help protect your
            Information against loss, misuse and unauthorized access, or
            disclosure. No company can fully prevent security risks, however.
            While we strive to protect your personal information, we cannot
            guarantee its absolute security. To help protect yourself and your
            information, choose a unique password for our Services and do not
            use a password on our Services that you would use on any other
            website or online service.
          </p>
          <h3>Dispute Resolution</h3>
          <p>
            If you believe that TransArabia has not adhered to this Statement,
            please contact TransArabia by e-mail at support@deviine.com. We will do
            our best to address your concerns. If you feel that your complaint
            has been addressed incompletely, we invite you to let us know for
            further investigation.
          </p>
          <h3>Information Choices</h3>
          <p>
            You may opt out of receiving promotional emails from TransArabia by
            following the instructions in those emails. If you opt out, we may
            still send you non-promotional emails, such as emails about your
            accounts or our ongoing business relations. You may also send
            requests about your personal information, including changes to your
            contact preferences, changes to or deletions of your information or
            content you post, and requests to opt-out of sharing your personal
            information with third parties by emailing support@TransArabia.com.
            Please note that deletion of your information or content does not
            ensure complete or comprehensive removal of the content or
            information posted on the Services.
            <br />
            When you visit the Site, we and others give you the following
            choices about use of mechanisms for tracking, including tracking of
            your online activities over time and across different websites and
            online services by third parties. Most Web browsers are set to
            accept cookies by default. If you prefer, you can usually choose to
            set your browser to remove cookies and to reject cookies from our
            Site or from third parties. If you choose to remove cookies or
            reject cookies, this could affect certain features or services of
            our Site. To opt-out of Google Analytics web tracking for certain
            browsers, you can download Google Analytics Opt-out Browser Add-on.
            You may opt out of other cookies that may be present on the Site by
            following the directions on Google's opt-out page. However, while we
            and others give you choices as described in this policy, there are
            many ways in which Web browser signals and other similar mechanisms
            can indicate your choice to disable tracking, and our Site may not
            be aware of or honor every mechanism.
            <br />
            You may have the right to know what personal information TransArabia has
            about you and to correct any inaccuracies. Please direct any such
            requests by email to support@TransArabia.com or by one of the other
            means listed below.
          </p>
          <h3>Changes and Updates to this Privacy Policy</h3>
          <p>
            From time to time, we may revise the Policy. To help you stay
            current of any changes, we note the date the Privacy Policy was last
            updated above.
          </p>
          <h3>Contact Information</h3>
          <p>
            Please contact TransArabia with any questions or comments about this
            Policy, your information, our third-party disclosure practices, or
            your consent choices.
            <br />
            TransArabia LLC
            <br />
            jbhvcjvj noida
            <br />
            Spokane, WA 99201
            <br />
            Telephone: 1234456777888
            <br />
            Email: bfdvfdvjj@gmail.com
          </p>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Policies;
