import { useEffect, useState } from "react";
import Routes from "./Routes/Index";
import { Toaster } from "react-hot-toast";
import { connect } from "react-redux";
import { Fetchuserdetails } from "./APIS/UserdetailsAPI";
import { setUserAuthentication } from "./Redux/actions";
import { setCartlength } from "./Redux/actions";
import { FetchCartAPI } from "./APIS/CartAPI";
import { FetchWishAPI } from "./APIS/WhislistAPI";
import { setWishlength } from "./Redux/actions";
import { setUserDetails } from "./Redux/actions";
import { MegaMenuAPI } from "./APIS/MegamenuAPI";
import { setCategroyDropDetails } from "./Redux/actions";

function App({
  getUserAuthenticationRedux,
  setUserAuthenticationRedux,
  setCartlengthRedux,
  setWishlengthRedux,
  setUserInfoRedux,
  setDropmenucategroyRedux,
}) {
  let transAribai_token = localStorage.getItem("transArabiatoken");

  useEffect(() => {
    if (transAribai_token) {
      setUserAuthenticationRedux(transAribai_token);

      Fetchuserdetails(transAribai_token, (callback) => {
        setUserInfoRedux(callback);
      });
    } else {
      localStorage.removeItem("transArabiatoken");
    }
    FetchDRopdown();
    CartData();
    WishdData();
  }, [transAribai_token]);

  const CartData = () => {
    if (!transAribai_token) {
    } else {
      FetchCartAPI(transAribai_token, (callback) => {
        setCartlengthRedux(callback?.cart_items.length);
      });
    }
  };
  const WishdData = () => {
    if (!transAribai_token) {
    } else {
      FetchWishAPI(transAribai_token, (callback) => {
        setWishlengthRedux(callback.length);
      });
    }
  };
  const FetchDRopdown = () => {
    MegaMenuAPI((callback) => {
      setDropmenucategroyRedux(callback?.categories);
    });
  };

  return (
    <>
      <Toaster />
      <Routes />
    </>
  );
}
const mapStateToProps = (state) => {
  return {
    getUserAuthenticationRedux: state.userAuthentication.data,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setUserAuthenticationRedux: (parameter) => {
      dispatch(setUserAuthentication(parameter));
    },
    setCartlengthRedux: (parameter) => {
      dispatch(setCartlength(parameter));
    },
    setWishlengthRedux: (parameter) => {
      dispatch(setWishlength(parameter));
    },
    setUserInfoRedux: (parameter) => {
      dispatch(setUserDetails(parameter));
    },
    setDropmenucategroyRedux: (parameter) => {
      dispatch(setCategroyDropDetails(parameter));
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(App);
