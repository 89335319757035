import React from "react";
import "Style/Home/Brands.css";
import BrandsImgOne from "PublicImages/Brands/Brands_img_one.png";
import BrandsImgtwo from "PublicImages/Brands/Brands_img_two.png";
import BrandsImgthree from "PublicImages/Brands/Brands_img_three.png";
import BrandsImgfour from "PublicImages/Brands/Brands_img_four.png";
import BrandsImgfive from "PublicImages/Brands/Brands_img_five.png";

const Brands = () => {
  return (
    <>
      <div className="Brands">
        <div className="text-center">
          <h3>TOP BRANDS</h3>
          <p className="pb-3">
            Lorem Ipsum is simply dummy text of the printing and type there
            setting industry.
          </p>
        </div>
        <div className="Brands_images d-flex justify-content-between align-items-center">
          <div className="Image_sec">
            <img src={BrandsImgOne} alt="img" />
          </div>
          <div className="Image_sec">
            <img src={BrandsImgtwo} alt="img" />
          </div>
          <div className="Image_sec">
            <img src={BrandsImgthree} alt="img" />
          </div>
          <div className="Image_sec">
            <img src={BrandsImgfour} alt="img" />
          </div>
          <div className="Image_sec">
            <img src={BrandsImgfive} alt="img" />
          </div>
        </div>
      </div>
    </>
  );
};

export default Brands;
