import React from "react";
import { FaAngleRight } from "react-icons/fa6";
import "Style/Home/AboutSection.css";
import aboutSection_img from "PublicImages/AboutSection/aboutSection_img.png";
import { useNavigate } from "react-router-dom";

const AboutSection = () => {
  const navigation = useNavigate();
  return (
    <>
      <div className="AboutSection">
        <div className="row">
          <div className="col-md-6 d-flex justify-content-center align-items-center">
            <div className="Aboutsec_left">
              <h6>ABOUT US</h6>
              <h3>TransArabia</h3>
              <p className="opacity-75">
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text ever since the 1500s, when an unknown printer took a galley
                of type and scrambled it to make a type specimen book. It has
                survived not only five centuries, but also the leap into
                electronic typesetting, remaining essentially unchanged.
              </p>
              <button
                className="d-flex align-items-center gap-2  Read_more "
                onClick={() => {
                  navigation("/about-us");
                  window.scrollTo(0, 0);
                }}
              >
                Read More <FaAngleRight />
              </button>
            </div>
          </div>
          <div className="col-md-6">
            <div className="AboutSec_right d-md-flex d-none justify-content-end">
              <img src={aboutSection_img} alt="img" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AboutSection;
