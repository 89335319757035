import React from "react";
import NavBar from "Sections/HomeSection/NavBar";
import Footer from "Sections/HomeSection/Footer";
import { Button } from "react-bootstrap";
import "Style/Home/AboutUs.css";
import { Link } from "react-router-dom";
import MissionImgOne from "../../PublicImages/BlogImages/Blog_slider_img_1.png";
import MissionImgtwo from "../../PublicImages/BlogImages/Blog_slider_img_2.png";
import MissionImgthree from "../../PublicImages/BlogImages/Blog_slider_img_3.png";
import MissionImgfour from "../../PublicImages/BlogImages/Blog_slider_img_4.png";
import { GiHealthDecrease } from "react-icons/gi";
import { AiFillCar } from "react-icons/ai";
import { PiCookingPotBold } from "react-icons/pi";
import { FaHome } from "react-icons/fa";
import { GiSolarPower } from "react-icons/gi";
import { MdOutlinePersonalInjury } from "react-icons/md";
import CategoriesDropdeon from "Sections/HomeSection/CategoriesDropdeon";

const AboutUs = () => {
  return (
    <>
      <NavBar />
      <CategoriesDropdeon/>
      <div className="mainAboutUs">
        <div className="aboutUS_header d-flex align-items-center justify-content-center text-white">
          <div className="aboutUs_text d-flex align-items-center justify-content-center flex-column">
            <h1>About us</h1>
            <p className="text-center">
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the industry's standard dummy text
              ever since the 1500s,
            </p>
           
          </div>
        </div>

        {/* Second Section --------------------------------------------- */}

        <section className="Second_sec">
          <div className="tp-team-details-text-box mb-50">
            <h2 className="tp-team-details-title">Personal Information</h2>
            <p className="pb-15">
              Since joining <b>Biddut</b> Lorem Ipsum is simply dummy text of
              the printing and typesetting industry. Lorem Ipsum has been the
              industry's standard dummy text ever since the 1500s, when an
              unknown printer took a galley of type and scrambled it to make a
              type specimen book. It has survived not only five centuries, but
              also the leap into electronic typesetting, remaining essentially
              unchanged. It was popularised in the 1960s with the release of
              Letraset sheets containing Lorem Ipsum passages, and more recently
              with desktop publishing software like Aldus PageMaker including
              versions of Lorem Ipsum.
            </p>
            <p>
              Matilda is a frequent speaker on the topics of global innovation
              and digital disruption. She is also an avid cook and history buff.
              You can find him dining late at night with the chefs of the hotels
              where he stays during his travels, or reading in his home library.
            </p>

            <p>
              Since establishment in 2007, TransArabia Pharmaceuticals has
              become a leading wholesale distributor serving all seven emirates
              from our headquarters in Dubai.
            </p>

            <h2 className="tp-team-details-title">OUR STORY</h2>
            <p>
              Duis aute irure dolor in reprehenderit in voluptate velit esse
              cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat
              cupidatat non proident, sunt in culpa qui officia deserunt mollit
              anim id est laborum. Saw wherein fruitful good days image them,
              midst, waters upon, saw. Seas lights seasons. Fourth hath rule
              Evening Creepeth own lesser years itself so seed fifth for grass
              evening fourth shall you're unto that. Had. Female replenish for
              yielding so saw all one to yielding grass you'll air sea it, open
              waters subdue, hath. Brought second Made. Be. Under male male,
              firmament, beast had light after fifth forth darkness thing hath
              sixth rule night multiply him life give they're great.
            </p>
            <p>
              Today, a considerable gap persists in the financing industry
              wherein, Startups/ MSMEs are unaware of the various funding
              options available for them to meet their financial needs. Even
              after hundreds of new age finance options being available,
              startups majorly depend on the banking lending process which
              typically takes more than 2 months. Our aim is to bring down the
              time to raise debt funding from 2 months to less than 15 days.
              Vinest is an AI driven matchmaking platform wherein the most
              suitable funding option for your business at the best pricing will
              be a click away.
            </p>
          </div>
        </section>

        {/* Our Mission ----------------------------------- */}

        <section className="OurMission">
          <div className="row d-flex align-items-center about_mission_content">
            <div className="col-md-3">
              <h2>Our Misson</h2>
            </div>
            <div className="col-md-9">
              <p>
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text ever since the 1500s, when an unknown printer took a galley
                of type and scrambled it to make a type specimen book.
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-md-3 mt-4">
              <div className="card profile-card-5">
                <div className="card-img-block">
                  <img className="card-img-top" src={MissionImgOne} alt="img" />
                </div>
                <div className="card-body pt-0">
                  <h5 className="card-title">MISSION</h5>
                  <p className="card-text">
                    Some quick example text to build on the card title and make
                    up the bulk of the card's content.
                  </p>
                  
                </div>
              </div>
            </div>

            <div className="col-md-3 mt-4">
              <div className="card profile-card-5">
                <div className="card-img-block">
                  <img className="card-img-top" src={MissionImgtwo} alt="img" />
                </div>
                <div className="card-body pt-0">
                  <h5 className="card-title">VISSION</h5>
                  <p className="card-text">
                    Some quick example text to build on the card title and make
                    up the bulk of the card's content.
                  </p>
                  
                </div>
              </div>
            </div>
            <div className="col-md-3 mt-4">
              <div className="card profile-card-5">
                <div className="card-img-block">
                  <img
                    className="card-img-top"
                    src={MissionImgthree}
                    alt="img"
                  />
                </div>
                <div className="card-body pt-0">
                  <h5 className="card-title">VALUE</h5>
                  <p className="card-text">
                    Some quick example text to build on the card title and make
                    up the bulk of the card's content.
                  </p>
                  
                </div>
              </div>
            </div>
            <div className="col-md-3 mt-4">
              <div className="card profile-card-5">
                <div className="card-img-block">
                  <img
                    className="card-img-top"
                    src={MissionImgfour}
                    alt="img"
                  />
                </div>
                <div className="card-body pt-0">
                  <h5 className="card-title">GOALS</h5>
                  <p className="card-text">
                    Some quick example text to build on the card title and make
                    up the bulk of the card's content.
                  </p>
                  
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* Why  Choose Use --------------------------------------------------------*/}
        <section className="Second_sec">
          <div className="tp-team-details-text-box mb-50">
            <h2 className="tp-team-details-title">Why Choose us</h2>
            <p className="pb-15">
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the industry's standard dummy text
              ever since the 1500s, when an unknown printer took a galley of
              type and scrambled it to make a type specimen book. It has
              survived not only five centuries, but also the leap into
              electronic typesetting, remaining essentially unchanged. It was
              popularised in the 1960s with the release of Letraset sheets
              containing Lorem Ipsum passages, and more recently with desktop
              publishing software like Aldus PageMaker including versions of
              Lorem Ipsum.
            </p>
            <p>
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the industry's standard dummy text
              ever since the 1500s, when an unknown printer took a galley of
              type and scrambled it to make a type specimen book. It has
              survived not only five centuries, but also the leap into
              electronic typesetting, remaining essentially unchanged. It was
              popularised in the 1960s with the release of Letraset sheets
              containing Lorem Ipsum passages, and more recently with desktop
              publishing software like Aldus PageMaker including versions of
              Lorem Ipsum.
            </p>
          </div>
        </section>

        {/* START SECTION SERVICES -------------------------------------------------- */}
        <section className="section-services">
          <div className="row justify-content-center text-center">
            <div className="col-md-10 col-lg-8">
              <div className="header-section">
                <h2 className="title">Exclusive Services</h2>
                <p className="description">
                  It is a long established fact that a reader will be distracted
                  by the readable content of a page when looking at its layout.
                </p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6 col-lg-4">
              <div className="single-service">
                <div className="content">
                  <span className="icon">
                    <AiFillCar />
                  </span>
                  <h3 className="title">Electrical Vehicles</h3>
                  <p className="description">
                    It is a long established fact that a reader will be
                    distracted by the readable content of a page when looking at
                    its layout.
                  </p>
                 
                </div>
                <span className="circle-before"></span>
              </div>
            </div>
            <div className="col-md-6 col-lg-4">
              <div className="single-service">
                <div className="content">
                  <span className="icon">
                    <GiHealthDecrease />
                  </span>
                  <h3 className="title">Health Products</h3>
                  <p className="description">
                    It is a long established fact that a reader will be
                    distracted by the readable content of a page when looking at
                    its layout.
                  </p>
                  
                </div>
                <span className="circle-before"></span>
              </div>
            </div>
            <div className="col-md-6 col-lg-4">
              <div className="single-service">
                <div className="content">
                  <span className="icon">
                    <PiCookingPotBold />
                  </span>
                  <h3 className="title">Kitchen & Cooking</h3>
                  <p className="description">
                    It is a long established fact that a reader will be
                    distracted by the readable content of a page when looking at
                    its layout.
                  </p>
                 
                </div>
                <span className="circle-before"></span>
              </div>
            </div>
            <div className="col-md-6 col-lg-4">
              <div className="single-service">
                <div className="content">
                  <span className="icon">
                    <FaHome />
                  </span>
                  <h3 className="title">Home Appliances</h3>
                  <p className="description">
                    It is a long established fact that a reader will be
                    distracted by the readable content of a page when looking at
                    its layout.
                  </p>
                  
                </div>
                <span className="circle-before"></span>
              </div>
            </div>
            <div className="col-md-6 col-lg-4">
              <div className="single-service">
                <div className="content">
                  <span className="icon">
                    <GiSolarPower />
                  </span>
                  <h3 className="title">Solar Power Equipment</h3>
                  <p className="description">
                    It is a long established fact that a reader will be
                    distracted by the readable content of a page when looking at
                    its layout.
                  </p>
                 
                </div>
                <span className="circle-before"></span>
              </div>
            </div>
            <div className="col-md-6 col-lg-4">
              <div className="single-service">
                <div className="content">
                  <span className="icon">
                    <MdOutlinePersonalInjury />
                  </span>
                  <h3 className="title">Personal Care</h3>
                  <p className="description">
                    It is a long established fact that a reader will be
                    distracted by the readable content of a page when looking at
                    its layout.
                  </p>
                  
                </div>
                <span className="circle-before"></span>
              </div>
            </div>
          </div>
        </section>
      </div>

      <Footer />
    </>
  );
};

export default AboutUs;
