import React, { useEffect, useState } from "react";
import "Style/Home/ImageSlider.css";

import { ErrorToaster } from "../../Toaster";
import ReactStars from "react-stars";

import { Button } from "react-bootstrap";
import { IoCartOutline, IoBagOutline } from "react-icons/io5";

import { setCartlength } from "../../Redux/actions";
import { connect } from "react-redux";
import { ADDCartApi, FetchCartAPI } from "../../APIS/CartAPI";
import LoginModel from "Model/LoginModel";
import { SinglevariationChild } from "../../APIS/CartAPI";
import { secondvariationAPI } from "../../APIS/CartAPI";
import { LuMinus } from "react-icons/lu";
import { FiPlus } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
const ImageSlider = ({
  producetdata,
  mainImage,
  sliderImage,
  setMainImage,
  setSliderImage,
  getUserAuthenticationRedux,
  setCartlengthRedux,
}) => {
  const [selectedAttributes, setSelectedAttributes] = useState(true);
  const [showLoginModal, setShowLoginModal] = useState(false);
  const [variationData, setVariationData] = useState([]);
  const [variationId, setVariationId] = useState("");
  const [totalquanitity, setTotalquanitity] = useState("");
  const [quanitity, setQuanitity] = useState(1);
  const [subAttributes, setSubAttributes] = useState("");
  const [subThirdAttributes, setSubThirdAttributes] = useState("");
  const [attributes, setAttributes] = useState(false);

  let [expenend, setExpenend] = useState(true);

  const navigation = useNavigate();

  const handleAddcart = (pid) => {
    let formdata = {
      productId: pid,
      variationId: variationId,
      quantity: quanitity,
    };

    if (!getUserAuthenticationRedux) {
      setShowLoginModal(true);
    } else if (!selectedAttributes) {
      ErrorToaster("Please Select Variant ");
    } else if (!attributes) {
      ErrorToaster("Please Select Variant");
    } else {
      ADDCartApi(
        getUserAuthenticationRedux,
        formdata,
        FetchCartAPI,
        setCartlengthRedux
      );
    }
  };
  const handlefirstChild = (pId, childId, variationId, quantity) => {
    setVariationId(variationId);
    setTotalquanitity(quantity);
    setSubAttributes(childId);
    setAttributes(true);

    let formdata = {
      productId: pId,
      childId: childId,
      variationId: variationId,
      language: "en",
    };

    SinglevariationChild(getUserAuthenticationRedux, formdata, (callback) => {
      setSliderImage(callback?.data?.variationData?.images);

      setVariationData(callback?.data?.variationData?.variationInfo);
    });
  };
  const handleSecoundvariation = (pId, childId, variationId) => {
    setVariationId(variationId);
    setSubThirdAttributes(childId);

    let formdata = {
      productId: pId,
      childId: childId,

      language: "en",
    };
    secondvariationAPI(getUserAuthenticationRedux, formdata);
  };
  const handlebuycart = (pid) => {
    let formdata = {
      productId: pid,
      variationId: variationId,
      quantity: quanitity,
    };
    if (!getUserAuthenticationRedux) {
      setShowLoginModal(true);
    } else if (!selectedAttributes) {
      ErrorToaster("Please Select Variant  ");
    } else if (!attributes) {
      ErrorToaster("Please Select Variant ");
    } else {
      ADDCartApi(
        getUserAuthenticationRedux,
        formdata,
        FetchCartAPI,
        setCartlengthRedux
      );
      navigation("/cart");
    }
  };

  let maxChars = 500;
  const text = producetdata ? producetdata[0]?.description : "";
  if (text.length <= maxChars) {
    <p>{text}</p>;
  }
  const datadiscraption = expenend ? text.substring(0, maxChars) : text;

  return (
    <>
      <LoginModel
        setShowLoginModal={setShowLoginModal}
        showLoginModal={showLoginModal}
      />
      <div className="ImageSlider">
        <div className="row">
          <div className="col-lg-9 col-md-12">
            <div className="row">
              <div className="col-md-6">
                <div className="row">
                  <div className="col-md-3 d-md-flex d-none">
                    <div className="Image-slider">
                      {sliderImage?.map((value, i) => {
                        return (
                          <div className="smallSlider_img" key={i}>
                            <img
                              src={value}
                              alt=""
                              className="w-100"
                              onClick={() => setMainImage(value)}
                            />
                          </div>
                        );
                      })}
                    </div>
                  </div>
                  <div className="col-md-9 col-12">
                    <div className="bigImg_container">
                      <img src={mainImage} alt="img" />
                    </div>
                    {/* {small-size} */}
                    <div className="row">
                      <div className="Image-slider-small">
                        {sliderImage?.map((value, i) => {
                          return (
                            <div className="smallSlider_img-small" key={i}>
                              <img
                                src={value}
                                alt=""
                                onClick={() => setMainImage(value)}
                              />
                            </div>
                          );
                        })}
                      </div>
                    </div>
                    {/* {small-size} */}
                    {/* <div className="d-flex align-items-center justify-content-end gap-2 mt-3">
                      <Button
                        className="d-flex align-items-center  gap-2 AddCartBtn"
                        onClick={() => handleAddcart(producetdata[0]?.id)}
                      >
                        <IoCartOutline /> Add to Cart
                      </Button>
                      <Button
                        className="d-flex align-items-center gap-2 BuyNowBtn"
                        onClick={() => handlebuycart(producetdata[0]?.id)}
                      >
                        <IoBagOutline /> Buy Now
                      </Button>
                    </div> */}
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="text-start slider_imageDetails">
                  <h3>{producetdata ? producetdata[0]?.name : ""}</h3>
                  <p className="m-0 d-flex  justify-content-start align-items-start gap-2">
                    {producetdata ? producetdata[0]?.averageRating : ""}
                    <span>
                      <ReactStars
                        count={5}
                        value={
                          producetdata ? producetdata[0]?.averageRating : ""
                        }
                        size={16}
                        color1={"gray"}
                        half={true}
                      />
                    </span>
                    {producetdata ? producetdata[0]?.totalReviews : ""}(Reviews)
                  </p>
                  <hr />
                  <div className="discount">
                    <h5 className="d-flex align-items-center gap-2">
                      ${producetdata ? producetdata[0]?.sale_price : ""}
                      <span className="opacity-50">
                        {" "}
                        ${producetdata ? producetdata[0]?.base_price : ""}
                      </span>{" "}
                      <p className="m-0">
                        {(
                          ((producetdata[0]?.base_price -
                            producetdata[0]?.sale_price) *
                            100) /
                          producetdata[0]?.base_price
                        ).toFixed(2)}
                        %
                      </p>
                    </h5>
                  </div>
                  <hr />
                  <p className="pt-2">
                    {datadiscraption}
                    {datadiscraption.length < maxChars ? (
                      ""
                    ) : (
                      <span
                        onClick={() => setExpenend(!expenend)}
                        style={{
                          color: "#0aa8d8c6",
                          cursor: "pointer",
                          marginLeft: "5px",
                        }}
                      >
                        {expenend ? " Read more..." : "Read less"}
                      </span>
                    )}
                  </p>

                  <hr />

                  <div className="selected-size-main py-1">
                    <div
                      className="select-size "
                      onClick={() => {
                        if (!getUserAuthenticationRedux) {
                          setShowLoginModal(true);
                        } else {
                          setSelectedAttributes(true);
                        }
                      }}
                    >
                      <p className="fw-bold mb-0">
                        {producetdata
                          ? producetdata[0]?.rootAttributes?.attributeName
                          : ""}
                      </p>
                    </div>
                    {selectedAttributes ? (
                      <div className="products-color">
                        <ul className="p-0 m-0 d-flex gap-3">
                          {producetdata
                            ? producetdata[0]?.rootAttributes?.childData?.map(
                                (iteam, i) => {
                                  const {
                                    childId,
                                    childName,
                                    quantity,
                                    variationId,
                                  } = iteam;
                                  return (
                                    <li
                                      onClick={() => {
                                        handlefirstChild(
                                          producetdata[0]?.id,
                                          childId,
                                          variationId,
                                          quantity
                                        );
                                      }}
                                      key={i}
                                      style={{
                                        backgroundColor:
                                          subAttributes === childId
                                            ? "#0d8aaf"
                                            : "",
                                        color:
                                          subAttributes === childId
                                            ? "white"
                                            : "",
                                      }}
                                    >
                                      {childName}
                                    </li>
                                  );
                                }
                              )
                            : ""}
                        </ul>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                  {variationData?.length > 0 ? (
                    <>
                      <div className="selected-size-main py-1">
                        <div className="select-size">
                          <p className="fw-bold mb-0">
                            {variationData
                              ? variationData[0]?.attributeName
                              : ""}
                          </p>
                        </div>
                        <div className="products-color">
                          <ul className="p-0 m-0 d-flex gap-3">
                            {variationData
                              ? variationData[0]?.childData.map((iteam, i) => {
                                  const {
                                    childId,
                                    childName,
                                    variationId,
                                  } = iteam;

                                  return (
                                    <li
                                      onClick={() =>
                                        handleSecoundvariation(
                                          producetdata[0]?.id,
                                          childId,
                                          variationId
                                        )
                                      }
                                      style={{
                                        backgroundColor:
                                          subThirdAttributes === childId
                                            ? "#0d8aaf"
                                            : "",
                                        color:
                                          subThirdAttributes === childId
                                            ? "white"
                                            : "",
                                      }}
                                      key={i}
                                    >
                                      {childName}
                                    </li>
                                  );
                                })
                              : ""}
                          </ul>
                        </div>
                      </div>
                    </>
                  ) : (
                    ""
                  )}
                  {selectedAttributes ? (
                    <div className="">
                      <div className="single-proucat-quantity">
                        <LuMinus
                          onClick={() => {
                            if (quanitity > 1) {
                              setQuanitity(quanitity - 1);
                            }
                          }}
                        />
                        {quanitity}{" "}
                        <FiPlus
                          onClick={() => {
                            if (totalquanitity !== quanitity) {
                              setQuanitity(quanitity + 1);
                            }
                          }}
                        />
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                  <div className="mt-3">
                    <span style={{ color: "#0d8aaf" }}>
                      Total Quanitity Product :
                    </span>
                    <span>{totalquanitity}</span>
                  </div>

                  <div className="d-flex align-items-center justify-content-start gap-2 mt-3">
                    <Button
                      className="d-flex align-items-center  gap-2 AddCartBtn"
                      onClick={() => handleAddcart(producetdata[0]?.id)}
                    >
                      <IoCartOutline /> Add to Cart
                    </Button>
                    <Button
                      className="d-flex align-items-center gap-2 BuyNowBtn"
                      onClick={() => handlebuycart(producetdata[0]?.id)}
                    >
                      <IoBagOutline /> Buy Now
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
const mapStateToProps = (state) => {
  return {
    getUserAuthenticationRedux: state.userAuthentication.data,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setCartlengthRedux: (parameter) => {
      dispatch(setCartlength(parameter));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ImageSlider);
