import axios from "axios";
import { baseUrl } from "../baseUrl";
// import { SuccessToaster, ErrorToaster } from "../Toaster";

export const FetchBloglistAPI = (formdata, setLoading, callback) => {
  setLoading(true);
  let data = JSON.stringify(formdata);
  let config = {
    method: "post",
    maxBodyLength: Infinity,
    url: `${baseUrl}fetch-blogs`,
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };
  axios
    .request(config)
    .then((response) => {
      callback(response?.data);
      setLoading(false);
    })
    .catch((error) => {
      console.log(error);
      let err = error?.response?.data?.message;
      if (err.length > 0) {
        console.log(error?.response?.data?.message);
      } else {
      }
      console.log(error, "error");
      setLoading(false);
    });
};

export const SingleblogAPI = (formdata, setLoading, callback) => {
  setLoading(true);
  let data = JSON.stringify(formdata);
  let config = {
    method: "post",
    maxBodyLength: Infinity,
    url: `${baseUrl}fetch-blog-detail`,
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };
  axios
    .request(config)
    .then((response) => {
      callback(response?.data);
      setLoading(false);
    })
    .catch((error) => {
      console.log(error);
      let err = error?.response?.data?.message;
      if (err.length > 0) {
        console.log(error?.response?.data?.message);
      } else {
      }
      console.log(error, "error");
      setLoading(false);
    });
};
