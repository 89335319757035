import React from "react";
import { connect } from "react-redux";

import AboutSection from "Sections/HomeSection/AboutSection";
import BussinessCardSection from "Sections/HomeSection/BussinessCardSection";
import CategoriesDropdeon from "Sections/HomeSection/CategoriesDropdeon";
import Header from "Sections/HomeSection/Header";
import NavBar from "Sections/HomeSection/NavBar";
import WarrainteeCardsSection from "Sections/HomeSection/WarrainteeCardsSection";
import QuickSection from "../Sections/HomeSection/QuickSection";
import Blog from "Sections/HomeSection/Blog";
import RatingSection from "Sections/HomeSection/RatingSection";
import Brands from "Sections/HomeSection/Brands";
import Footer from "Sections/HomeSection/Footer";

const HomePage = ({ getUserAuthenticationRedux }) => {
  let trans = localStorage.getItem("transArabiatoken");

  return (
    <>
      <NavBar />
      <CategoriesDropdeon />
      <Header />
      <WarrainteeCardsSection />
      <BussinessCardSection />
      <AboutSection />
      <QuickSection />
      <Blog />
      <RatingSection />
      <Brands />
      <Footer />
    </>
  );
};
const mapStateToProps = (state) => {
  return {
    getUserAuthenticationRedux: state.userAuthentication.data,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(HomePage);
