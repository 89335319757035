import CategoriesDropdeon from "Sections/HomeSection/CategoriesDropdeon";
import Footer from "Sections/HomeSection/Footer";
import NavBar from "Sections/HomeSection/NavBar";
import React from "react";
import "../../Style/Home/B2Cpage.css";

const B2CPage = () => {
  return (
    <>
      <NavBar />
      <CategoriesDropdeon />
      <div className="container" style={{ minHeight: "550px" }}>
        <div className="my-3">
          <div className="">
            <h5>Business To Customer</h5>
          </div>
          <div className="B2C">
            <h6>Go Omnichannel for Consistency</h6>
            <p>
              The key to omnichannel marketing is that it aligns content
              delivery across many channels to work together. Enabling customers
              to move flawlessly between channels on their journey to a
              purchase.
            </p>
            <p className="my-2">
              BTo kickstart omnichannel marketing, you must first figure out how
              and where your audience engages with your brand.
            </p>
          </div>
          <div className="B2C">
            <h6>Content Marketing</h6>
            <p>
              B2C content marketing is often more product-centric and
              emotionally driven. Aiming to engage consumers on a more personal
              level. It focuses on customer benefits. Often through storytelling
              and entertainment. And seeks to stimulate an immediate response or
              purchase.
            </p>
            <p className="my-2">
              51% mentioned tracking organic traffic. This shows that many
              people recognize the importance of this task. Emphasizing the need
              for businesses to prioritize and optimize their organic search
              strategies.
            </p>
          </div>
          <div className="B2C">
            <h6>Provide Useful Tips</h6>
            <p>
              This helps cultivate a relationship between your brand and your
              customers. Which can increase their loyalty and ultimately
              motivate them to make a purchase.
            </p>
            <p className="my-2">
              They provide useful tips, information, and inspiration for outdoor
              enthusiasts to plan their adventures. These days, consumers are
              looking for more than just a product or service. They seek an
              experience, a story, and value. And B2C content marketing provides
              that by offering useful information or entertainment.
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default B2CPage;
