import * as React from "react";
import { createRoot } from "react-dom/client";
import {
  createBrowserRouter,
  RouterProvider,
  Route,
  Link,
} from "react-router-dom";
import HomePage from "Pages/HomePage";
import Categories from "Pages/Categories";
import SolarPowered from "Pages/SolarPowered";
import CeilingFans from "Pages/CeilingFans";
import Login from "Auth/Login";
import Signup from "Auth/Signup";
import Forget from "Auth/Forget";
import Resetpassword from "Auth/Resetpassword";
import ContactUs from "Sections/OtherPages/ContactUs";
import AboutUs from "Sections/OtherPages/AboutUs";
import Policies from "Sections/OtherPages/Policies";
import Faq from "Sections/OtherPages/Faq";
import Cart from "Sections/OtherPages/Cart";
import Partners from "Sections/OtherPages/Partners";
import Carres from "Sections/OtherPages/Carres";
import Whislist from "Sections/OtherPages/Whislist";
import Profile from "Pages/Profile";
import Verfication from "Auth/Verfication";
import CheckOutpage from "Sections/OtherPages/CheckOutpage";
import PrivacyPolicy from "Sections/OtherPages/PrivacyPolicy";
import TermsContions from "Sections/OtherPages/Terms&Contions";
import Singlebolgpage from "Sections/OtherPages/Singlebolgpage";
import Blogsection from "Sections/OtherPages/Blogsection";
import B2BPage from "Sections/OtherPages/B2BPage";
import B2CPage from "Sections/OtherPages/B2CPage";

const router = createBrowserRouter([
  {
    path: "/",
    element: <HomePage />,
  },
  {
    path: "/categories",
    element: <Categories />,
  },
  {
    path: "/solar/:id/:slug",
    element: <SolarPowered />,
  },
  {
    path: "/ceiling/:id/:slug",
    element: <CeilingFans />,
  },
  {
    path: "/contact-us",
    element: <ContactUs />,
  },
  {
    path: "/about-us",
    element: <AboutUs />,
  },
  {
    path: "/policies",
    element: <Policies />,
  },
  {
    path: "/faq",
    element: <Faq />,
  },
  {
    path: "/login",
    element: <Login />,
  },
  {
    path: "/signup",
    element: <Signup />,
  },
  {
    path: "/forget",
    element: <Forget />,
  },
  {
    path: "/resetpassword",
    element: <Resetpassword />,
  },

  {
    path: "/cart",
    element: <Cart />,
  },
  {
    path: "/partners",
    element: <Partners />,
  },
  {
    path: "/carres",
    element: <Carres />,
  },
  {
    path: "/whishlist",
    element: <Whislist />,
  },
  {
    path: "/profile",
    element: <Profile />,
  },
  {
    path: "/email-confirmation/:slug",
    element: <Verfication />,
  },
  {
    path: "/checkoutpage",
    element: <CheckOutpage />,
  },
  {
    path: "/privacy-and-policy",
    element: <PrivacyPolicy />,
  },
  {
    path: "/team-and -conditions",
    element: <TermsContions />,
  },
  {
    path: "/blog/:slug",
    element: <Singlebolgpage />,
  },
  {
    path: "/blog",
    element: <Blogsection />,
  },
  {
    path: "/b-2-b",
    element: <B2BPage />,
  },
  {
    path: "/b-2-c",
    element: <B2CPage />,
  },
]);

function Routes() {
  return (
    <>
      <RouterProvider router={router} />
    </>
  );
}

export default Routes;
