import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import "../../Style/Home/Cart.css";
import NavBar from "Sections/HomeSection/NavBar";
import Footer from "Sections/HomeSection/Footer";
import { MdDelete } from "react-icons/md";
import { connect } from "react-redux";
import { FetchCartAPI } from "../../APIS/CartAPI";
import { setCartlength } from "../../Redux/actions";
import { UpdateCartApi } from "../../APIS/CartAPI";
import { RemoveCartApi } from "../../APIS/CartAPI";
import CategoriesDropdeon from "Sections/HomeSection/CategoriesDropdeon";
import { LuMinus } from "react-icons/lu";
import { FiPlus } from "react-icons/fi";
import { FaCheck } from "react-icons/fa6";
import ReactStars from "react-stars";
import { OfferDetailsAPI } from "../../APIS/api";
import { ErrorToaster } from "../../Toaster";
import { CoupanApply } from "../../APIS/api";
import { RemoveCoupanApply } from "../../APIS/api";

const Cart = ({ getUserAuthenticationRedux, setCartlengthRedux }) => {
  let token = localStorage.getItem("transArabiatoken");
  const navigation = useNavigate();
  const [cartData, setCartData] = useState([]);
  const [cartcouponInfo, setCartCoupan] = useState([]);
  const [Removecode, setRemovecode] = useState("");
  const [Removeshow, setRemoveshow] = useState("");
  const [input, setInput] = useState();
  const [offers, setOffers] = useState([]);
  useEffect(() => {
    FetchCartdata();
    Offerdata();
  }, [getUserAuthenticationRedux]);
  const Offerdata = () => {
    let fromdata = {
      page: 1,
      language: "en",
    };
    OfferDetailsAPI(fromdata, (callback) => {
      setOffers(callback?.data?.offers);
    });
  };
  const FetchCartdata = () => {
    FetchCartAPI(getUserAuthenticationRedux, (callback) => {
      setCartData(callback.cart_items);
      setCartlengthRedux(callback.cart_items.length);
      setCartCoupan(callback.couponInfo);
    });
  };

  const updateCartHandle = (cartId, productId, variationId, quantity) => {
    let formdata = {
      cartId: cartId,
      productId: productId,
      variationId: variationId,
      quantity: quantity,
      language: "en",
    };
    UpdateCartApi(getUserAuthenticationRedux, formdata, FetchCartdata);
  };

  const handelRemove = (cartId) => {
    let fromData = {
      cartId: cartId,
      language: "en",
    };
    RemoveCartApi(
      token,
      fromData,
      FetchCartdata,
      cartData,
      setCartData,
      setCartlengthRedux
    );
  };
  const handleApplycoupan = () => {
    let Result = "";

    offers?.forEach((element, i) => {
      if (element?.name?.toLowerCase() === input?.toLowerCase()) {
        Result = element.id;
        let fromdata = {
          couponId: Result,
        };
        CoupanApply(
          getUserAuthenticationRedux,
          fromdata,
          FetchCartdata,
          setRemoveshow
        );
        setRemovecode(element.name);

        setInput("");
      }
    });
    if (Result === "") {
      ErrorToaster("Apply coupon failed");
    }
  };
  const handleRemoveCoupan = () => {
    let fromdata = {
      removeId: cartcouponInfo ? cartcouponInfo[0]?.discountId : "",
    };

    RemoveCoupanApply(getUserAuthenticationRedux, fromdata, FetchCartdata);
    setRemoveshow(false);
  };

  return (
    <>
      <NavBar />
      <CategoriesDropdeon />
      <div className="cart">
        <div className="transarabia_cart_body" style={{ minHeight: "500px" }}>
          {cartData.length !== 0 ? (
            <div className="row">
              <div className="col-xl-8">
                {cartData.length !== 0
                  ? cartData.map((iteam, i) => {
                      return (
                        <div className="card border shadow-none" key={i}>
                          <div className="card-body">
                            <div className="d-flex align-items-start card-body-small border-bottom pb-3">
                              <div className="me-4">
                                <img
                                  src={iteam.productDetails[0].featured_image}
                                  alt="img"
                                  className="avatar-lg rounded"
                                  style={{ cursor: "pointer" }}
                                />
                              </div>
                              <div className="flex-grow-1 align-self-center overflow-hidden">
                                <div>
                                  <h5 className="text-truncate font-size-18">
                                    <Link to="" className="text-dark">
                                      {iteam.productDetails[0].name}
                                    </Link>
                                  </h5>
                                  <p className="text-muted mb-0">
                                    <ReactStars
                                      count={5}
                                      value={
                                        iteam?.productDetails[0]?.averageRating
                                      }
                                      size={16}
                                      color1={"gray"}
                                      half={true}
                                    />
                                    <i className="bx bxs-star-half text-warning"></i>
                                  </p>

                                  {iteam
                                    ? iteam?.variationData?.variations?.map(
                                        (cur, i) => {
                                          return (
                                            <p
                                              className="mb-0 mt-1"
                                              key={i}
                                              style={{ fontWeight: "500" }}
                                            >
                                              {cur?.attributeName}:{" "}
                                              <span className="">
                                                {cur?.variationName}
                                              </span>
                                            </p>
                                          );
                                        }
                                      )
                                    : ""}
                                </div>
                              </div>
                              <div className="flex-shrink-0 ms-2">
                                <ul className="list-inline mb-0 font-size-16">
                                  <li className="list-inline-item">
                                    <Link to="" className="text-muted px-1">
                                      <MdDelete
                                        onClick={() =>
                                          handelRemove(iteam.cartId)
                                        }
                                        className="card_delete_icon"
                                      />
                                    </Link>
                                  </li>
                                </ul>
                              </div>
                            </div>

                            <div>
                              <div className="row">
                                <div className="col-4">
                                  <div className="mt-3">
                                    <p className="text-muted mb-2">Price</p>
                                    <h5 className="mb-0 mt-2 cart-small">
                                      <span className="text-muted me-2">
                                        <del className="font-size-16 fw-normal">
                                          ${iteam?.variationData?.base_price}
                                        </del>
                                      </span>
                                      ${iteam?.variationData?.sale_price}
                                    </h5>
                                  </div>
                                </div>
                                <div className="col-5">
                                  <div className="mt-3">
                                    <p className="text-muted mb-2">Quantity</p>
                                    <div className="">
                                      <div className="Quantity-details">
                                        <span>
                                          <LuMinus
                                            onClick={() =>
                                              updateCartHandle(
                                                iteam.cartId,
                                                iteam?.productDetails[0]?.id,
                                                iteam?.variationId,
                                                parseInt(iteam?.quantity) - 1
                                              )
                                            }
                                            style={{ cursor: "pointer" }}
                                          />{" "}
                                        </span>{" "}
                                        <span>{iteam.quantity}</span>{" "}
                                        <span>
                                          {" "}
                                          <FiPlus
                                            onClick={() =>
                                              updateCartHandle(
                                                iteam.cartId,
                                                iteam?.productDetails[0]?.id,
                                                iteam?.variationId,
                                                parseInt(iteam?.quantity) + 1
                                              )
                                            }
                                            style={{ cursor: "pointer" }}
                                          />
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-3">
                                  <div className="mt-3">
                                    <p className="text-muted mb-2">Total</p>
                                    <h5 className="cart-small">
                                      $
                                      {iteam?.variationData?.sale_price *
                                        iteam?.quantity}
                                    </h5>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })
                  : ""}
                <div>
                  <div className="d-flex align-items-center ">
                    <div className="Apply-input">
                      <input
                        type="text"
                        name="coupan"
                        value={input}
                        onChange={(e) => setInput(e.target.value)}
                      />
                    </div>
                    <div
                      className="ApplyCoupan ms-3"
                      onClick={handleApplycoupan}
                    >
                      Apply Coupon
                    </div>
                  </div>
                  {Removeshow ? (
                    <div className="d-flex align-items-center my-3">
                      <div className="Remove-input">
                        <FaCheck />
                        <span className="ms-3">{Removecode} </span>
                      </div>
                      <div
                        className="RemoveCoupan ms-5"
                        onClick={handleRemoveCoupan}
                      >
                        Remove Coupon
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              <div className="col-xl-4">
                <div className="mt-5 mt-lg-0">
                  {cartcouponInfo?.map((item, i) => (
                    <div className="card border shadow-none" key={i}>
                      <div className="card-header bg-transparent border-bottom py-3 px-4">
                        <h5 className="font-size-16 mb-0">
                          Order Summary{" "}
                          <span className="float-end">#MN0124</span>
                        </h5>
                      </div>
                      <div className="card-body p-4 pt-2">
                        <div className="table-responsive">
                          <table className="table mb-0">
                            <tbody>
                              <tr>
                                <td>Sub Total :</td>
                                <td className="text-end">
                                  ${item.subtotalAmount}
                                </td>
                              </tr>
                              <tr>
                                <td>Discount : </td>
                                <td className="text-end">
                                  ${item.discountAmount}
                                </td>
                              </tr>
                              <tr>
                                <td>Shipping Charge :</td>
                                <td className="text-end">Free</td>
                              </tr>
                              <tr>
                                <td>Estimated Tax : </td>
                                <td className="text-end">Free</td>
                              </tr>
                              <tr className="bg-light">
                                <th>Total :</th>
                                <td className="text-end">
                                  <span className="fw-bold">
                                    ${item.grandTotal}
                                  </span>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
                <div
                  className="processtocheckout"
                  onClick={() => navigation("/checkoutpage")}
                >
                  Proceed to Checkout
                </div>
              </div>
            </div>
          ) : (
            <div
              className="d-flex align-items-center justify-content-center"
              style={{
                minHeight: "500px",
                border: "1px solid #b9ccca",
                fontSize: "30px",
              }}
            >
              {" "}
              Cart Empty
            </div>
          )}
        </div>
      </div>
      <Footer />
    </>
  );
};
const mapStateToProps = (state) => {
  return {
    getUserAuthenticationRedux: state.userAuthentication.data,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setCartlengthRedux: (parameter) => {
      dispatch(setCartlength(parameter));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Cart);
