import React, { useState } from "react";
import "Style/Home/CeilingFansProduct.css";
import { Button } from "react-bootstrap";
import ReactStars from "react-stars";
import Slider from "react-slick";
import { useNavigate } from "react-router-dom";

const CeilingFansProduct = ({ suggestedProduct, CategoriesNamelocation }) => {
  const navigation = useNavigate();
  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 4,
    slidesToScroll: 1,

    autoplaySpeed: 1,
    responsive: [
      {
        breakpoint: 1025,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
    ],
  };

  return (
    <>
    {suggestedProduct?.length !==0?
      <div className="CeilingFansProduct">
        <div className="d-flex justify-content-between align-items-center">
          <h4 className="m-0">Suggested Viewed</h4>
        </div>

        <div className="suggested-producat">
          <Slider {...settings}>
            {suggestedProduct?.map((item, i) => {
              return (
                <div className="col-md-3 col-12 my-4 px-2" key={i}>
                  <div
                    className="card"
                    onClick={() => {
                      navigation(`/ceiling/${item?.productId}/${item?.slug}`, {
                        state: CategoriesNamelocation.state,
                      });
                      window.scroll(0, 0);
                    }}
                    style={{ cursor: "pointer" }}
                  >
                    <img
                      src={item?.featured_image}
                      alt="img"
                      style={{ height: "250px" }}
                    />
                    <div className="content ">
                      <div className="d-flex justify-content-between align-items-center">
                        <h6>{item?.name.slice(0, 30)}...</h6>
                      </div>
                      <p className="m-0 text-start">
                        {item?.description?.slice(0, 80)}...
                      </p>
                      <div className="review mt-2 d-flex align-items-center justify-content-between">
                        <div className="d-flex align-items-center gap-1 icons">
                          <ReactStars
                            count={5}
                            value={item?.averageRating}
                            size={16}
                            color1={"gray"}
                            half={true}
                          />

                          <span className="text-dark opacity-50">(Review)</span>
                        </div>
                        <Button className="AddToCart_btn d-flex align-items-center gap-1">
                          View
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </Slider>
        </div>
      </div>
      :""}
    </>
  );
};

export default CeilingFansProduct;
