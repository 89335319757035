import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import "../Style/Home/EditAddress.css";
import { IoCloseSharp } from "react-icons/io5";
import { ErrorToaster } from "Toaster";
import Code from "../Code";
import { UpdateAddressApi } from "../APIS/api";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",
  border: "2px solid #0aa8d8c6",
  boxShadow: 24,
  p: 4,
};

const Editadressmodel = ({
  openEdit,
  setOpenEdit,
  EditAddress,
  getUserAuthenticationRedux,
  FetchAddress,
}) => {
  // console.log(EditAddress,"vvvvv")
  useEffect(() => {
    setInput({ addressId: EditAddress?.id, ...EditAddress });
  }, [EditAddress]);
  const [selectCountryCode, setSelctCountryCode] = useState("+91");

  const [input, setInput] = useState({
    addressId: EditAddress?.id,
    name: "",
    street: "",
    city: "",
    state: "",
    code: "",
    address_type: "Home",
    mobile: "",

    default: 1,
  });
  const handleClose = () => setOpenEdit(false);

  const handleEditsubmit = (e) => {
    e.preventDefault();

    if (
      input.name === "" ||
      input.address_type === "" ||
      input.city === "" ||
      input.landmark === "" ||
      input.mobile === ""
    ) {
      ErrorToaster("All field is requried");
    } else if (input?.code?.length >= "7" || input?.code === "") {
      ErrorToaster("Code is Less than 7 digit");
    } else if (input?.mobile?.length <= "7" || input?.mobile?.length >= "13") {
      ErrorToaster("Mobile Number 8 to 12");
    } else {
      let fromdata = {
        addressId: input.addressId,
        name: input.name,
        mobile: input.mobile,
        phone_code: selectCountryCode,
        phone_country: selectCountryCode,
        street: input.street,
        landmark: input.landmark,
        state: input.state,
        city: input.city,
        code: input.code,
        address_type: input.address_type,
        default: input.default,
      };

      UpdateAddressApi(getUserAuthenticationRedux, fromdata, FetchAddress);
      handleClose();
    }
  };
  return (
    <Modal
      open={openEdit}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style} className="responsive-model-box">
        <div className=" d-flex justify-content-between">
          <div className="Edit-Address-heading">Edit Address</div>
          <div className="Edit-Address-heading">
            <IoCloseSharp onClick={handleClose} />
          </div>
        </div>

        <div className="mt-3">
          <form onSubmit={handleEditsubmit}>
            <div className="mb-3">
              <label className="form-label">Name</label>
              <input
                type="text"
                className="form-control"
                name="name"
                value={input.name}
                onChange={(e) => setInput({ ...input, name: e.target.value })}
              />
            </div>
            <div className="mb-3">
              <label className="form-label">Mobile</label>
              <div className="d-flex ">
                <div className="col-sm-3 col-5">
                  <select
                    className="form-select"
                    aria-label="Default select example"
                    value={selectCountryCode}
                    onChange={(e) => setSelctCountryCode(e.target.value)}
                  >
                    <option selected>{selectCountryCode}</option>
                    {Code?.map((ele, i) => {
                      return (
                        <option key={i} value={ele.dial_code}>
                          {ele.name}
                        </option>
                      );
                    })}
                  </select>
                </div>
                <div className="col-sm-9 col-7 ms-2">
                  <input
                    type="number"
                    className="form-control"
                    name="mobile"
                    value={input.mobile}
                    onChange={(e) =>
                      setInput({ ...input, mobile: e.target.value })
                    }
                  />
                </div>
              </div>
            </div>
            <div className="mb-3">
              <label className="form-label">Street</label>
              <input
                type="text"
                className="form-control"
                name="street"
                value={input.street}
                onChange={(e) => setInput({ ...input, street: e.target.value })}
              />
            </div>
            <div className="mb-3">
              <label className="form-label">Landmark</label>
              <input
                type="text"
                className="form-control"
                name="landmark"
                value={input.landmark}
                onChange={(e) =>
                  setInput({ ...input, landmark: e.target.value })
                }
              />
            </div>
            <div className="mb-3">
              <label className="form-label">City</label>
              <input
                type="text"
                className="form-control"
                name="city"
                value={input.city}
                onChange={(e) => setInput({ ...input, city: e.target.value })}
              />
            </div>
            <div className="mb-3">
              <label className="form-label">State</label>
              <input
                type="text"
                className="form-control"
                name="state"
                value={input.state}
                onChange={(e) => setInput({ ...input, state: e.target.value })}
              />
            </div>
            <div className="mb-3">
              <label className="form-label">Pin Code</label>
              <input
                type="text"
                className="form-control"
                name="code"
                value={input.code}
                onChange={(e) => setInput({ ...input, code: e.target.value })}
              />
            </div>
            <div className="mb-3 d-flex align-iteams-center">
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="radio"
                  name="address_type"
                  id="flexRadioDefault1"
                  onChange={(e) =>
                    setInput({ ...input, address_type: e.target.value })
                  }
                  checked={input.address_type === "Office" ? true : false}
                  value={"Office"}
                />
                <label className="form-check-label" for="flexRadioDefault1">
                  Office
                </label>
              </div>
              <div className="form-check ms-3">
                <input
                  className="form-check-input"
                  type="radio"
                  name="address_type"
                  id="flexRadioDefault2"
                  onChange={(e) =>
                    setInput({ ...input, address_type: e.target.value })
                  }
                  checked={input.address_type === "Home" ? true : false}
                  value={"Home"}
                />
                <label className="form-check-label" for="flexRadioDefault2">
                  Home
                </label>
              </div>
            </div>
            <div className="mb-3">
              <div className="Addaress-button">
                <button type="submit">Update Address</button>
              </div>
            </div>
          </form>
        </div>
      </Box>
    </Modal>
  );
};

export default Editadressmodel;
