import React, { useState } from "react";

import { CiLock } from "react-icons/ci";
import { IoEye } from "react-icons/io5";
import { FaEyeSlash } from "react-icons/fa";

import logo3 from "../PublicImages/AboutSection/logo3.png";
import { useNavigate } from "react-router-dom";
import { ErrorToaster } from "../Toaster";
import { useLocation } from "react-router-dom";
import { resetpassword } from "../APIS/AuthApi";
const Resetpassword = () => {
  const [showpassword, setShowpassword] = useState(false);
  const [showconfirmpassword, setShowconfirmpassword] = useState(false);
  let passReg = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
  const navigation = useNavigate();
  const location = useLocation();
  const reset_token = location?.state?.token;

  const [input, setInput] = useState({
    confirmpassword: "",
    password: "",
  });

  const handleInput = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setInput({ ...input, [name]: value });
  };
  const handlesumbmit = (e) => {
    e.preventDefault();

    if (passReg.test(input.password) === false) {
      ErrorToaster(
        "Password with min 8 Characters Include atleast 1 Uppercase, 1 Special Character, and Alphanumeric Characters"
      );
    } else if (input.password !== input.confirmpassword) {
      ErrorToaster("passwor and confirm password not equal");
    } else {
      let fromData = {
        newPassword: input.password,
        confirmPassword: input.confirmpassword,
        password_reset_token: reset_token,
      };

      resetpassword(fromData, navigation);
    }
  };

  return (
    <div className="container-fluied">
      <div className="Row d-md-flex">
        <div className="col-md-6 col-12 loginbox">
          <div className="login-left-side">
            <div className="login-left-side-1">
              <h1>
                Re<span style={{ color: "#0D8AAF" }}>set</span> Password
              </h1>
              <form onSubmit={handlesumbmit}>
                <div className="mb-3">
                  <label className="d-flex justify-content-start fw-6">
                    New Password
                  </label>
                  <div className="left-side-password mt-2">
                    <CiLock />
                    <div
                      className="leftpassword d-flex"
                      style={{ position: "relative" }}
                    >
                      <input
                        className="form-control"
                        type={showpassword ? "text" : "password"}
                        placeholder="Enter Your password"
                        name="password"
                        value={input.password}
                        onChange={handleInput}
                      />
                      {showpassword ? (
                        <IoEye onClick={() => setShowpassword(!showpassword)} />
                      ) : (
                        <FaEyeSlash
                          onClick={() => setShowpassword(!showpassword)}
                        />
                      )}
                    </div>
                  </div>
                </div>
                <div className="mb-3">
                  <label className="d-flex justify-content-start fw-6">
                    Confirm Password
                  </label>
                  <div className="left-side-password mt-2">
                    <CiLock />
                    <div
                      className="leftpassword d-flex"
                      style={{ position: "relative" }}
                    >
                      <input
                        className="form-control"
                        type={showconfirmpassword ? "text" : "password"}
                        placeholder="Enter Your password"
                        name="confirmpassword"
                        value={input.confirmpassword}
                        onChange={handleInput}
                      />
                      {showconfirmpassword ? (
                        <IoEye
                          onClick={() =>
                            setShowconfirmpassword(!showconfirmpassword)
                          }
                        />
                      ) : (
                        <FaEyeSlash
                          onClick={() =>
                            setShowconfirmpassword(!showconfirmpassword)
                          }
                        />
                      )}
                    </div>
                  </div>
                </div>

                <div className="mt-5">
                  <button
                    className="btn btn-success w-100"
                    type="submit"
                    style={{
                      borderRadius: "20px",
                      backgroundColor: "#0D8AAF",
                      borderColor: "#0D8AAF",
                    }}
                  >
                    Update password
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div className="col-md-6 col-12  d-md-flex d-none ">
          <div className="login-rigth-side">
            <div className="login-right-logo">
              <img src={logo3} alt="logo" onClick={() => navigation("/")} />
            </div>
            <div className="login-right-box">
              <h1> Welcome back !</h1>
              <p>
                We are glad to see you again! Get access to your Orders,
                Wishlist and Recommendations.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Resetpassword;
