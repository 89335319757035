import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import "Style/Home/Blog.css";
import { FaAngleRight } from "react-icons/fa6";
import { FetchBloglistAPI } from "../../APIS/BlogAPi";
import { useNavigate } from "react-router-dom";

const Blog = () => {
  const [bloglist, setBloglist] = useState([]);
  const [loading, setLoading] = useState(false);

  const navigation = useNavigate();
  useEffect(() => {
    bloglistItem();
  }, []);

  const bloglistItem = () => {
    let fromdata = {
      page: 1,
      language: "en",
    };
    FetchBloglistAPI(fromdata, setLoading, (callback) => {
      setBloglist(callback?.data?.blog_list);
    });
  };
  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 4,
    slidesToScroll: 1,

    autoplaySpeed: 1000,
    responsive: [
      {
        breakpoint: 1025,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
    ],
  };

  return (
    <>
      <div className="Blog">
        <div className=" text-center">
          <h3>OUR BLOGS</h3>
          <p className="pb-3">
            Lorem Ipsum is simply dummy text of the printing and type there
            setting industry.
          </p>
        </div>

        <div className="Blog_card_Slider">
          <Slider {...settings}>
            {bloglist?.length > 0
              ? bloglist?.map((data, i) => {
                  const { title, id, description, image, slug } = data;
                  return (
                    <div className="col-md-3 mx-0 p-2" key={i}>
                      <div className="card border-0">
                        <img
                          src={image ? image : ""}
                          alt="img"
                          className="blog-img"
                        />
                        <div className="text-start p-2">
                          <h6 className="card-title">
                            {title.slice(0, 35)}...
                          </h6>
                          <p className="card-text m-0">
                            {description.slice(0, 70)}...
                          </p>
                          <a
                            href=""
                            className="text-decoration-none"
                            onClick={() => {
                              navigation(`/blog/${slug}`, { state: id });
                            }}
                          >
                            Read More <FaAngleRight />
                          </a>
                        </div>
                      </div>
                    </div>
                  );
                })
              : ""}
          </Slider>
        </div>
      </div>
    </>
  );
};

export default Blog;
