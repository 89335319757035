import React, { useState } from "react";
import "../../Style/profile/ChangePassword.css";
import { passwordpasswordAPI } from "../../APIS/AuthApi";
import { ErrorToaster } from "../../Toaster";

const ChangePassword = ({ getUserAuthenticationRedux }) => {
  const [input, setInput] = useState({
    oldpassword: "",
    newpassword: "",
    confirmpassword: "",
  });
  const handleInput = (e) => {
    const { name, value } = e.target;

    setInput({ ...input, [name]: value });
  };
  let passReg = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
  let emailreg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w\w+)+$/;

  const handleSubmit = (e) => {
    e.preventDefault();

    if (
      passReg.test(input.oldpassword) === false ||
      input?.oldpassword?.length <= "7"
    ) {
      ErrorToaster(
        "Old Password with min 8 Characters Include atleast 1 Uppercase, 1 Special Character, and Alphanumeric Characters"
      );
    } else if (
      passReg.test(input.newpassword) === false ||
      input?.newpassword?.length <= "7"
    ) {
      ErrorToaster(
        "NEW Password with min 8 Characters Include atleast 1 Uppercase, 1 Special Character, and Alphanumeric Characters"
      );
    } else if (input.newpassword !== input.confirmpassword) {
      ErrorToaster("NEW Password OR Confirm Password Not Equel");
    }
    // console.log(formdata)
    else {
      let formdata = {
        oldPassword: input.oldpassword,
        newPassword: input.newpassword,
        confirmnewPassword: input.confirmpassword,
      };
      passwordpasswordAPI(getUserAuthenticationRedux, formdata);
      setInput({
        oldpassword: "",
        newpassword: "",
        confirmpassword: "",
      });
    }
  };

  return (
    <>
      <div className="changePassword">
        <div className="row">
          <div className="col-md-10">
            <form onSubmit={handleSubmit}>
              <div className="container">
                <h3 className="">Change password</h3>
                <hr />
                <label>
                  <b>Old password</b>
                </label>
                <input
                  type="password"
                  placeholder="Enter Password"
                  name="oldpassword"
                  value={input.oldpassword}
                  onChange={handleInput}
                />
                <label>
                  <b>New Password</b>
                </label>
                <input
                  type="password"
                  placeholder="Enter Password"
                  name="newpassword"
                  value={input.newpassword}
                  onChange={handleInput}
                />
                <label>
                  <b>Confirm Password</b>
                </label>
                <input
                  type="password"
                  placeholder="Confirm Password"
                  name="confirmpassword"
                  value={input.confirmpassword}
                  onChange={handleInput}
                />
                <button type="submit" className="registerbtn">
                  Confirm
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default ChangePassword;
