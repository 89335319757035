import React from "react";
import NavBar from "Sections/HomeSection/NavBar";
import Footer from "Sections/HomeSection/Footer";
import carrersBanner_img from "../../PublicImages/careers_banner_img.png";
import "Style/Home/careers.css";
import { Link } from "react-router-dom";

const Carres = () => {
  return (
    <>
      <NavBar />

      <div className="careers">
        <div className="careers_header_banner d-flex  align-items-center">
          <div className="careers_header_banner_content d-flex justify-content-center align-items-center">
            <div>
              <p className="m-0 ">Career Builder Landing Page</p>
              <h1 className="fw-bold">Work with us</h1>
              <p className=" fw-bold fs-6">
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text ever since the 1500s,
              </p>
            </div>
          </div>
        </div>
        {/*second section -------------------------------------- */}
        <section className="careers_second_sec_content">
          <h2>What is a career page?</h2>
          <p>
            A career page is an extremely important and valuable asset for the
            business organisation, looking for the best candidates to join their
            team.
          </p>
          <p>
            It is a perfect catch for the employees and employers to acknowledge
            the parameters they want to have in their job roles or positions and
            in the company they want to join.
          </p>
          <h2>Why is a good career page important for a website?</h2>
          <p>
            The very first step of employee interaction with your company is
            through a great career page. Speaking a lot about employee brands of
            tech companies, the entire page sets a great example to inspire many
            recruiters in the same league.
          </p>
        </section>

        {/* carrer who we are section ------------------------------------------------- */}
        <div className="who_Are_section">
          <section className="about-section">
            <div className="row d-flex align-items-center">
              <div className="content-column col-lg-7 col-md-12 col-sm-12 order-2">
                <div className="inner-column">
                  <div className="sec-title">
                    <span className="title">Work and be Yourself</span>
                    <h2>Where does it come from?</h2>
                  </div>
                  <div className="text">
                    Contrary to popular belief, Lorem Ipsum is not simply random
                    text. It has roots in a piece of classical Latin literature
                    from 45 BC, making it over 2000 years old. Richard
                    McClintock, a Latin professor at Hampden-Sydney College in
                    Virginia, looked up one of the more obscure Latin words,
                    consectetur, from a Lorem Ipsum passage, and going through
                    the cites of the word in classical literature, discovered
                    the undoubtable source.
                  </div>
                  <div className="text">
                    It is a long established fact that a reader will be
                    distracted by the readable content of a page when looking at
                    its layout. The point of using Lorem Ipsum is that it has a
                    more-or-less normal distribution of letters, as opposed to
                    using 'Content here, content here', making it look like
                    readable English.
                  </div>
                  <div className="btn-box">
                    <Link
                      to="/contactUs"
                      className="theme-btn btn-style-one text-decoration-none"
                    >
                      Contact Us
                    </Link>
                  </div>
                </div>
              </div>
              <div className="image-column col-lg-5 col-md-12 col-sm-12">
                <div className="inner-column wow fadeInLeft">
                  <div className="author-desc">
                    <h2>Rahul Kumar Yadav</h2>
                    <span>Web Developer</span>
                  </div>
                  <figure className="image-1">
                    <Link
                      to=""
                      className="lightbox-image"
                      data-fancybox="images"
                    >
                      <img
                        title="Rahul Kumar Yadav"
                        src={carrersBanner_img}
                        alt="img"
                      />
                    </Link>
                  </figure>
                </div>
              </div>
            </div>
            <div className="sec-title">
              <span className="title">Our Future Goal</span>
              <h2>We want to lead in innovation & Technology</h2>
            </div>
            <div className="text">
              We works on UI/UX and functionality as well so that a plugins
              comes with proper stucture & stunning looks which suits to your
              web app & website.
            </div>
            <div className="text">
              We take a small toolkit here and ride it well so that it is fit
              for your use. One who performs well and looks even better.
            </div>
            <div className="text">
              Here we are trying to give you all kinds of technical content,
              whether it is related to designing or functionality. We are
              creating content on a lot of languages and will continue to make
              it free of cost even if you use it without any problem. Which is a
              very important thing.
            </div>
            <div className="text">
              Here you can also share the content you create, if our technical
              team likes it, then we will also share it on our blog.
            </div>
            <div className="text">
              In the end, I would say keep visiting our website and enjoy the
              quality content.
            </div>
          </section>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Carres;
