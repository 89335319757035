import React from "react";
import "../../Style/Privacy&poilcy.css";
import CategoriesDropdeon from "Sections/HomeSection/CategoriesDropdeon";
import Footer from "Sections/HomeSection/Footer";
import NavBar from "Sections/HomeSection/NavBar";

const PrivacyPolicy = () => {
  return (
    <>
      <NavBar />
      <CategoriesDropdeon />
      <hr className="d-md-none d-flex mt-0" />

      <div className="container">
        <div className="Privacy-policy">
          <div className="privacy-heading">
            <h4>TransArabia Privacy & Policy</h4>
          </div>
          <div className="privacy-content-data">
            <ol>
              <li>
                When you visit our Services, we may send one or more Cookies –
                small data files – to your computer to uniquely identify your
                browser and let us help you log in faster and enhance your
                navigation through the Sites. “Cookies” are text-only pieces of
                information that a website transfers to an individual’s hard
                drive or other website browsing equipment for record keeping
                purposes. A Cookie may convey anonymous information about how
                you browse the Services to us so we can provide you with a more
                personalized experience, but does not collect personal
                information about you. Cookies allow the Sites to remember
                important information that will make your use of the site more
                convenient. A Cookie will typically contain the name of the
                domain from which the Cookie has come, the “lifetime” of the
                Cookie, and a randomly generated unique number or other value.
                Certain Cookies may be used on the Sites regardless of whether
                you are logged in to your account or not.
              </li>
              <li>
                Session Cookies are temporary Cookies that remain in the Cookie
                file of your browser until you leave the Site.
              </li>
              <li>
                When we use session Cookies to track the total number of
                visitors to our Site, this is done on an anonymous aggregate
                basis (as Cookies do not in themselves carry any personal data).
              </li>
              <li>
                When you use the Services we may collect your precise location
                data. We may also derive your approximate location from your IP
                address.
              </li>
              <li>
                It is necessary for our legitimate interests in the proper
                administration of our website and business; analyzing the use of
                the website and our Services; assuring the security of our
                website and Services; maintaining back-ups of our databases; and
                communicating with you;
              </li>

              <li>
                To send promotional communications that may be of specific
                interest to you.
              </li>
              <li>
                It is necessary for our compliance with certain legal provisions
                which may require us to process your personal data. By way of
                example, and without limitation, we may be required by law to
                disclose your personal data to law enforcement or a regulatory
                agency.
              </li>
              <li>Contact you and provide you with information.</li>
              <li>Analyze, improve and manage our Services and operations.</li>
              <li>
                Resolve problems and disputes, and engage in other legal and
                security matters.
              </li>
              <li>
                Enforce any terms and conditions of any subscription for our
                Services.
              </li>
            </ol>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default PrivacyPolicy;
