import { WISH_LENGTH } from "../type";

const initialState = {
  Wishlength: 0,
};

const wishLengthReducer = (state = initialState, action) => {
  switch (action.type) {
    case WISH_LENGTH:
      return {
        ...state,
        Wishlength: action.payload,
      };

    default:
      return state;
  }
};

export default wishLengthReducer;
