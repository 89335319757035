import React from "react";
import { Link, useNavigate } from "react-router-dom";
import "Style/Home/Footer.css";
import {
  FaAnglesRight,
  FaSquareFacebook,
  FaInstagram,
  FaXTwitter,
} from "react-icons/fa6";

const Footer = () => {
  const navigation=useNavigate()
  return (
    <>
      <div className="Footer">
        <div className="row p-5">
          <div className="col-md-3">
            <div className="listing">
              <h5 className="pb-2">Company Info</h5>
              <ul>
                <li className="d-flex align-items-center gap-2">
                  <FaAnglesRight className="arrow_icon" />
                  <Link to="/about-us"  onClick={()=>window.scrollTo(0,0)}>About TransArabia</Link>
                </li>
                
                <li className="d-flex align-items-center gap-2">
                  <FaAnglesRight className="arrow_icon" />
                  <Link to="/contact-us"  onClick={()=>window.scrollTo(0,0)}>Contact us</Link>
                </li>
               
              </ul>
            </div>
          </div>
          <div className="col-md-3">
            <div className="listing">
              <h5 className="pb-2">Customer Service</h5>
              <ul>
                <li className="d-flex align-items-center gap-2">
                  <FaAnglesRight className="arrow_icon" />
                  <Link to="/policies"  onClick={()=>window.scrollTo(0,0)}>Easy Return policy</Link>
                </li>
                <li className="d-flex align-items-center gap-2">
                  <FaAnglesRight className="arrow_icon" />
                  <Link to="/privacy-and-policy"  onClick={()=>window.scrollTo(0,0)}>Privacy & Policy</Link>
                </li>
                <li className="d-flex align-items-center gap-2">
                  <FaAnglesRight className="arrow_icon" />
                  <Link to="/team-and -conditions"  onClick={()=>window.scrollTo(0,0)}>Terms & Conditions</Link>
                </li>
               
              </ul>
            </div>
          </div>
          <div className="col-md-3">
            <div className="listing">
              <h5 className="pb-2">Help</h5>
              <ul>
                <li className="d-flex align-items-center gap-2">
                  <FaAnglesRight className="arrow_icon" />
                  <Link to="/faq" onClick={()=>window.scrollTo(0,0)}>Support center & FAQ</Link>
                </li>
                
              </ul>
            </div>
          </div>
          <div className="col-md-3 p-0">
            <div className="listing">
              <h5 className="pb-2 connect-footer">Connect with TransArabia</h5>
              <div className="d-flex align-items-center gap-3 footer-small-icons">
               <Link to="https://www.facebook.com" target="_blank" > <FaSquareFacebook className="social_media_icon"  style={{cursor:"pointer"}}/></Link>
              <Link to="https://www.instagram.com" target="_blank"> <FaInstagram className="social_media_icon" /></Link> 
              <Link to="https://twitter.com" target="_blank">   <FaXTwitter className="social_media_icon" /></Link> 
              </div>
            </div>
          </div>
        </div>
        <hr className="footerHr_line"></hr>
        <div className="copy_right d-flex justify-content-center">
          <ul className="p-3 d-flex justify-content-center align-items-center">
            <li>
              <Link to="">Copyright 2024. TransArabia All Rights Reserved</Link>
            </li>
           
          </ul>
        </div>
      </div>
    </>
  );
};

export default Footer;
